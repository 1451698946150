<template>
<v-layout align-start>
    <v-flex>
        <v-card dark>
            <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/FondoNL2.jpg" class="grey lighten-2" height="520" width="100%">
                <v-row class="fill-height pa-3" align="center">
                    <v-col  cols="12" md="8" offset="1" class="d-none d-md-block mt-3">
                        <br>
                        <h1 class="display-2 font-weight-bold"  style="margin-top:300px ">
                            Concursos
                        </h1>
                        
                        <br>
                    </v-col>
                    <v-col cols="10" offset="1" class="d-block d-md-none mt-5" >
                        <br>
                        <h1 class="headline font-weight-bold"  style="margin-top:300px ">
                            Concursos
                        </h1>
                        
                        <br>
                    </v-col>
                </v-row>
            </v-img>
        </v-card>
        <v-spacer></v-spacer>
        <v-card>
            <v-tabs v-model="tab">
                <v-btn  to="/Concursos">
                    Listado
                </v-btn>
                <v-tab key="ConcursosInfo" href="#ConcursosInfo" ripple style="display:none;" v-if="seleccionado">
                    Detalle de Concursos
                </v-tab>
                <v-tab-item key="Concursos" value="Concursos">
                    <!-- <v-card flat>
                        <v-card-text>
                            <v-data-table v-model="selected" :headers="headers" :items="listadoFiltro" item-key="concursoId" dense @click:row="mostrarItem" class="elevation-1">
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <v-toolbar-title>Concursos</v-toolbar-title>
                                        <br>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <v-row class="ma-1">
                                        <v-col class="d-flex" cols="12" sm="12">
                                            <v-text-field label="Buscar" v-model="parmBusqueda" @keyup="filtrar"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="ma-1">
                                        <v-col class="d-flex" cols="12" sm="3">
                                            <v-select :items="inscripcion" v-model="parmInscripcion" label="Inscripcion" clearable @change="filtrar()"></v-select>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" sm="3">
                                            <v-select :items="recursos" v-model="parmRecursos" label="Procedencia General del Recurso" clearable @change="filtrar()"></v-select>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" sm="3">
                                            <v-select :items="procesos" v-model="parmProcesos" label="Tipo de proceso" clearable @change="filtrar()"></v-select>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" sm="3">
                                            <v-select :items="municipios" v-model="parmMunicipios" label="Municipio" clearable @change="filtrar()"> </v-select>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:no-data>
                                    <v-btn color="primary" @click="listar">Actualizar</v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card> -->
                </v-tab-item>

                <v-tab-item key="Aclaraciones" value="Aclaraciones">
                    <v-card flat>
                        <v-card-text>
                            <v-data-table v-model="selected"  :headers="headersAclaraciones" :items="listadoCompontente2" :single-select=true item-key="concursoAclaracionId" class="elevation-1">
                                <template v-slot:item.actions="{ item }">
                                    <v-icon small class="mr-2" @click="editItemAclaraciones(item)">
                                        mdi-eye
                                    </v-icon>

                                </template>

                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <v-toolbar-title>Aclaraciones</v-toolbar-title>
                                        <v-divider class="mx-4" inset vertical></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line hide-details></v-text-field>

                                    </v-toolbar>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item key="ConcursosInfo" value="ConcursosInfo">
                    <v-divider></v-divider>
                    <br>
                    <br>
                    <v-tabs vertical class="d-none d-md-flex">
                        <v-tab>
                            Datos Generales
                        </v-tab>
                        <v-tab>
                            Ubicaciones
                        </v-tab>
                        <v-tab>
                            Eventos
                        </v-tab>
                        <v-tab>
                            Imagenes
                        </v-tab>
                        <v-tab>
                            Localidad
                        </v-tab>
                        <v-tab>
                            Resultados
                        </v-tab>
                        
                            <v-tab-item>
                                <concursosDatosGenerales :concursoid="seleccionado" :key="seleccionado"></concursosDatosGenerales>
                            </v-tab-item>
                            
                            <v-tab-item>
                                <concursosUbicacion :concursoid="seleccionado" :key="seleccionado"></concursosUbicacion>
                            </v-tab-item>
                            <v-tab-item>
                                <concursosEventos :concursoid="seleccionado" :key="seleccionado"></concursosEventos>
                            </v-tab-item>
                            <v-tab-item>
                                <concursosImagenes :concursoid="seleccionado" :key="seleccionado"></concursosImagenes>
                            </v-tab-item>
                            <v-tab-item>
                                <concursosLocalidad :concursoid="seleccionado" :key="seleccionado"></concursosLocalidad>
                            </v-tab-item>
                            <v-tab-item>
                                <concursosContratistasIndex :concursoid="seleccionado" :key="seleccionado"></concursosContratistasIndex>
                            </v-tab-item>

                        
                    </v-tabs>

                    <!-- <v-tabs  class="d-flex d-md-none"> -->

                <v-tabs center-active show-arrows class="d-block d-md-none">
                    <v-tab>Datos Generales</v-tab>
                    <v-tab>Ubicaciones</v-tab>
                    <v-tab>Eventos</v-tab>
                    <v-tab>Imagenes</v-tab>
                    <v-tab>Localidad</v-tab>
                    <v-tab>Resultados</v-tab>
                    <v-tab-item><concursosDatosGenerales :concursoid="seleccionado" :key="seleccionado"></concursosDatosGenerales></v-tab-item>
                    <v-tab-item><concursosUbicacion :concursoid="seleccionado" :key="seleccionado"></concursosUbicacion></v-tab-item>
                    <v-tab-item><concursosEventos :concursoid="seleccionado" :key="seleccionado"></concursosEventos></v-tab-item>
                    <v-tab-item><concursosImagenes :concursoid="seleccionado" :key="seleccionado"></concursosImagenes></v-tab-item>
                    <v-tab-item><concursosLocalidad :concursoid="seleccionado" :key="seleccionado"></concursosLocalidad></v-tab-item>
                    <v-tab-item><concursosContratistasIndex :concursoid="seleccionado" :key="seleccionado"></concursosContratistasIndex></v-tab-item>
                </v-tabs>

                </v-tab-item>
            </v-tabs>

        </v-card>
    </v-flex>
</v-layout>
</template>

<script>
import concursosDatosGenerales from '../Concursos/ConcursosDatosGenerales'
import concursosUbicacion from '../Concursos/ConcursosUbicacion'
import concursosContratistasIndex from '../Concursos/ConcursosContratistas_Index'
import concursosEventos from '../Concursos/ConcursosEventos'
import concursosImagenes from '../Concursos/ConcursosImagenes'
import concursosLocalidad from '../Concursos/ConcursosLocalidad'
import concursosReplicas from '../Concursos/ConcursosReplicas'
import axios from 'axios'
export default {
    data: () => ({

        inset: false,
        seleccionado: '',
        prueba: 0,

        inscripcion: [
            'Cerrada',
            'Vigente',
        ],
        recursos: [
            'Federal',
            'Estatal',
        ],
        procesos: [
            'Licitaciones públicas',
            'Invitaciones restringidas',
            'Adjudicaciones directas',
        ],

        municipios: [],
        parmInscripcion: '',
        parmRecursos: null,
        parmProcesos: null,
        parmBusqueda: null,
        parmMunicipios: '',
        parmBusqueda: '',
        item: [],
        variant: 'default',
        listadoCompontente1: [],
        listadoFiltro: [],
        listadoCompontente2: [],
        listado: [],
        dialog: false,
        dialogAclaraciones: false,
        search: '',
        headers: [{
                text: 'Licitación',
                value: 'numeroConcurso',
                class: "indigo white--text title",
            },
            {
                text: 'Titulo Concurso',
                value: 'tituloConcurso',
                class: "indigo white--text title",
            },
        ],
        headersAclaraciones: [

            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
            {
                text: 'Nombre',
                value: 'nombre'
            },
            {
                text: 'Empresa',
                value: 'empresa'
            },
            {
                text: 'Descripcion',
                value: 'descripcion'
            },
            {
                text: 'Respuesta',
                value: 'respuesta'
            },
        ],
        tab: 'Concursos',
        selected: [],
        nameRules: [
            v => !!v || 'Nombre es requerido',
            v => v.length >= 10 || 'Nombre debe de tener mas de 10 caracteres',
        ],
        emailRules: [
            (v) => !!v || 'Correo Electronico es requerido',
            (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Correo Electronico debe de ser valido'
        ],
        RFCRules: [
            (v) => !!v || 'R.F.C es requerido',
            (v) => /^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/.test(v) || 'R.F.C debe de ser valido'

        ],
        EmpresaRules: [
            v => !!v || 'Empresa es requerido'
        ],
        TelefonoRules: [
            v => !!v || 'Telefono es requerido'
        ],
        DescripcionRules: [
            v => !!v || 'Descripcion es requerido',
            v => v.length >= 10 || 'Descripcion debe de tener mas de 10 caracteres',
        ],
        valida: 0,
        validaMensaje: []
    }),
    components: {
        concursosDatosGenerales,
        concursosUbicacion,
        concursosEventos,
        concursosImagenes,
        concursosLocalidad,
        concursosReplicas,
        concursosContratistasIndex,
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo Registro' : 'Solicitud de Aclaración'
        },
        formTitleAclaraciones() {
            return this.editedIndexAclaraciones === -1 ? 'Nueva aclaración' : 'Solicitud de Aclaración'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogAclaraciones(val) {
            val || this.close()
        },
    },

    created() {
        this.listar();
        this.listarMunicipios();
        this.seleccionado = this.$route.params.seleccionado 
        this.tab = 'ConcursosInfo';
    },

    methods: {

        cargarArhivo() {
            let me = this;
            console.log(me);
            console.log(me.defaultItemAclaraciones.Archivo);
            me.defaultItemAclaraciones.Archivo = this.$refs.Archivo.files[0];
            console.log(me.defaultItemAclaraciones.Archivo);
        },

        listarMunicipios() {
            //Enlistar registos
            //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

            let me = this;
            let header = {
                "Authorization": "Bearer " + this.$store.state.token
            };
            let configuration = {
                headers: header
            };
            axios.get('api/concursos/listarMunicipios', configuration).then(function (response) {
                console.log(response.data);
                me.listado = response.data;
                me.listado.forEach(x => {
                    me.municipios.push(x.nombre)
                });

                console.log('municipios');
                console.log(me.municipios);
            }).catch(function (error) {
                console.log(error);
            });

        },
        listar() {
            //Enlistar registos
            //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

            let me = this;
            let header = {
                "Authorization": "Bearer " + this.$store.state.token
            };
            let configuration = {
                headers: header
            };
            axios.get('api/concursos/listarconcursospublicos', configuration).then(function (response) {
                console.log(response.data);
                me.listadoCompontente1 = response.data;

                me.listadoFiltro = me.listadoCompontente1;
            }).catch(function (error) {
                console.log(error);
            });
        },

        listarAclaraciones(concursoid) {
            //Enlistar registos
            //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller
            debugger;
            let me = this;
            this.prueba = concursoid;

            axios.get('api/aclaraciones/ListarConcursos/' + this.prueba).then(function (response) {
                debugger;
                console.log(response.data);
                me.listadoCompontente2 = response.data;
            }).catch(function (error) {
                debugger;
                //Se agrega elemento a la lista por default
                console.log(error);
            });
        },

        filtrar() {
            let me = this;
            me.listadoFiltro = me.listadoCompontente1;
            if (me.parmRecursos == 'Estatal')
                me.listadoFiltro = me.listadoFiltro.filter(x =>
                    x.tipoConcursoId == 2 || x.tipoConcursoId == 4 || x.tipoConcursoId == 6
                );
            else if (me.parmRecursos == 'Federal')
                me.listadoFiltro = me.listadoFiltro.filter(x =>
                    x.tipoConcursoId == 3 || x.tipoConcursoId == 5 || x.tipoConcursoId == 7
                );

            if (me.parmProcesos == 'Licitaciones públicas')
                me.listadoFiltro = me.listadoFiltro.filter(x =>
                    x.tipoConcursoId == 2 || x.tipoConcursoId == 3
                );
            else if (me.parmProcesos == 'Adjudicaciones directas')
                me.listadoFiltro = me.listadoFiltro.filter(x =>
                    x.tipoConcursoId == 4 || x.tipoConcursoId == 5
                );
            else if (me.parmProcesos == 'Invitaciones restringidas')
                me.listadoFiltro = me.listadoFiltro.filter(x =>
                    x.tipoConcursoId == 6 || x.tipoConcursoId == 7
                );

            if (!(me.parmMunicipios == null || me.parmMunicipios == '')) {
                me.listadoFiltro = me.listadoFiltro.filter(x =>
                    x.municipio == me.parmMunicipios
                );
            }
            if (me.parmInscripcion == 'Vigente')
                me.listadoFiltro = me.listadoFiltro.filter(x =>
                    x.estatusConcursoId == 2 ||
                    x.estatusConcursoId == 3
                );
            else if (me.parmInscripcion == 'Cerrada')
                me.listadoFiltro = me.listadoFiltro.filter(x =>
                    x.estatusConcursoId == 5 || x.estatusConcursoId == 6 ||
                    x.estatusConcursoId == 7 || x.estatusConcursoId == 8 ||
                    x.estatusConcursoId == 9 || x.estatusConcursoId == 4
                );
            if (!(me.parmBusqueda == null || me.parmBusqueda == '')) {
                let palabras = me.parmBusqueda.toLowerCase().split(' ');
                me.listadoFiltro = me.listadoFiltro.filter(x =>
                    palabras.every(y => x.tituloConcurso.toLowerCase().includes(y))
                )
            }

        },

        mostrarItem(item) {
            debugger;
            let me = this;
            console.log(item);
            this.selected = [];
            this.selected.push(item);
            this.tab = 'ConcursosInfo';
            this.seleccionado = item.concursoId;
            // console.log(this.selected);

            //Este es el item que se va a desactivar
            var selectedItemAclaraciones = Object.assign({}, item)

        },

        activarItem(item) {
            //Desactivar registro

            let me = this;

            //Este es el item que se va a desactivar
            var selectedItem = Object.assign({}, item)

            if (confirm('¿Estás seguro de querer activar este registro?' + '\n' + selectedItem.name)) {
                console.log("Si");
                axios.put('api/contratistas/activar/' + selectedItem.id).then(function (response) {
                    me.listar();
                    me.limpiar();
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                console.log("No");
            }

        },

        desactivarItem(item) {
            //Desactivar registro

            let me = this;

            //Este es el item que se va a desactivar
            var selectedItem = Object.assign({}, item)

            if (confirm('¿Estás seguro de querer desactivar este registro?' + '\n' + selectedItem.name)) {
                console.log("Si");
                axios.put('api/contratistas/desactivar/' + selectedItem.id).then(function (response) {
                    me.listar();
                    me.limpiar();
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                console.log("No");
            }

        },

        deleteItem(item) {
            //Eliminar registro
            // const index = this.desserts.indexOf(item)
            // confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
        },

        close() {
            //Cierra el Dialog (Ventana Modal)

            this.limpiar();
            this.dialog = false;
            this.dialogAclaraciones = false;
        },

        descargarArchivo(id) {

            let me = this;

            if (this.editedIndexAclaraciones > -1) {

                //Funciona 
                axios({
                    url: 'api/aclaraciones/DescargarArchivo/' + this.editedItemAclaraciones.ConcursoAclaracionId,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    // fileLink.setAttribute('download', 'file.pdf');
                    fileLink.setAttribute('download', me.editedItemAclaraciones.NombreAnexo);
                    document.body.appendChild(fileLink);

                    fileLink.click();

                }).catch(function (error) {
                    var errormostrar = 'No se puede descargar ningun archivo';

                    console.log(errormostrar);
                    // me.validaMensaje.push(error.response.request.responseText.headers);

                    me.validaMensaje.push(errormostrar);
                });

            }

        },

        descargarArchivoRespuesta(id) {

            let me = this;

            if (this.editedIndexAclaraciones > -1) {

                //Funciona 
                axios({
                    url: 'api/aclaraciones/DescargarArchivoRespuesta/' + this.editedItemAclaraciones.ConcursoAclaracionId,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    // fileLink.setAttribute('download', 'file.pdf');
                    fileLink.setAttribute('download', me.editedItemAclaraciones.RespuestaNombreAnexo);
                    document.body.appendChild(fileLink);

                    fileLink.click();

                }).catch(function (error) {
                    var errormostrar = 'No se puede descargar ningun archivo';

                    console.log(errormostrar);
                    // me.validaMensaje.push(error.response.request.responseText.headers);

                    me.validaMensaje.push(errormostrar);
                });

            }

        },

    },
}
</script>
