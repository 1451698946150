
<script>
import axios from 'axios'
import itemPartida from '../components/CatalogoUniversal/ItemPartida'
  export default {
    data: () => ({
        
        catalogoConceptos: [],
        catalogoConceptosFiltrado: [],
        
        filtroDescripcion: '',
        filtroClave: '',

        search: '',

        filtroTitulo: ''
     
    }),

    components:{
        itemPartida
    },

    computed: {  
      
    },

    watch: {
      
    },

    created () {    
      
       this.listar();
    },

    // created () {
      
    // },

    methods: {

        listar(){
            //Enlistar registos
            //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller
            let me = this;
            axios.get('api/CatalogoUniversalConceptos/listar').then(function (response) {
                console.log(response.data, 'CatalogoUniversal');
                me.catalogoConceptos = response.data;
                me.catalogoConceptosFiltrado = me.catalogoConceptos.partidas.slice();
                return true;
            }).catch(
                function (error) {
                console.log(error);
                return false;
            });
        },
        
              

        filtrar() {
            let listadoResultante = [];
            let listadoOriginal = JSON.parse(JSON.stringify(this.catalogoConceptos));

            if (listadoOriginal != null) {
                if (this.filtroTitulo != null && this.filtroTitulo != "") {
                    let palabras = this.filtroTitulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").split(' ');
                    listadoResultante = this.filtrarPartidasPorConcepto(listadoOriginal.partidas, palabras).slice();
                }
            }

            this.catalogoConceptosFiltrado = listadoResultante.slice();
            return true;
        },

        filtrarPartidasPorConcepto(partidas, palabras){
            let me = this;
            let listaConceptos = [];
            let listadoPartidas = [];
            
            if(partidas !== undefined){
                partidas.forEach(function (partida){
                    if(partida.partidas !== undefined){
                        let partidasFiltradas = me.filtrarPartidasPorConcepto(partida.partidas, palabras).slice();
                        if(partidasFiltradas != null && partidasFiltradas.length > 0){
                            partida.partidas = partidasFiltradas.slice();
                            listadoPartidas.push(partida);
                        }                   
                    } 
                    else if(partida.conceptos !== undefined){
                        partida.conceptos.some(function (concepto) {
                            if (palabras.every(x => palabras.every(x => concepto.descripcionLarga.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(x)))) {
                                listaConceptos.push(concepto);
                            } 
                        });
                        if(listaConceptos.length > 0){
                            partida.conceptos = listaConceptos.slice();
                            listadoPartidas.push(partida);
                        }

                    }   
                    else{

                    }            
                });

            }
            return listadoPartidas.slice();

        },

        desactivarFlitroTitulo() {
            this.filtroTitulo = '';
            this.catalogoConceptosFiltrado = this.catalogoConceptos.partidas.slice();
        },
      

    }

  }
</script>

<template>
<div>
    <section id="titulo" class="d-flex flex-column" style="background-image: linear-gradient(to right, #5232C2, #038bbb);color: white;">
        <v-container class="pb-8 pt-8">
            <h1 style="font-size:3em;">Catalogo de Conceptos</h1>
        </v-container>
    </section>
    <section id="titulo" class="d-flex flex-column" style="background-image: linear-gradient(to right, #5232C2, #038bbb);color: white;">
    </section>
    <section>
        <v-row class="pl-4 pr-4">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="display:flex;align-items: baseline;">
                    <v-text-field v-model="filtroTitulo" label="Palabras del concepto" full-width></v-text-field>
                    <v-btn icon color="pink" @click="desactivarFlitroTitulo()">
                        <v-icon>mdi-cancel</v-icon>
                    </v-btn>
            </v-col>          
        </v-row>
        <v-row class="pl-4 pr-4">
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" style="display:flex;align-items: baseline;">
                <v-btn color="pink" dark @click="filtrar" :disabled="this.filtroTitulo == null || this.filtroTitulo == ''">
                    Filtrar
                </v-btn>
            </v-col>          
        </v-row>

        
    </section>
    <section class="pa-4" id="contenedorListadoInfo">

      <v-layout align-start>
          <v-flex>
              <v-card flat>
                  <v-card-text>                       
                      <itemPartida v-for="partida in catalogoConceptosFiltrado" :key="partida.idPresupuestoPartida" :partidaId="partida.idPresupuestoPartida" :descripcion="partida.descripcionPartidaLarga" :partida="partida" >             
                      </itemPartida>
                      
                </v-card-text>
                </v-card>
          </v-flex>
      </v-layout>

    </section>
</div>
</template>
