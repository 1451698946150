<template>
  <v-form v-model="valid">
    <v-container fill-height fluid class="x-ctn">
      <v-row align="center" justify="center">
        <v-col cols="12"  md="8" lg="6" sm="12" >
          <v-text-field v-model="editedItem.Nombre" :rules="nameRules" label="Nombre" required></v-text-field>
        </v-col>        
      </v-row>
      <v-row align="center" justify="center">
       <v-col cols="12" md="8" lg="6" sm="12" >
          <v-text-field v-model="editedItem.RFC" :rules="RFCRules" :counter="13" label="R.F.C"></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" lg="6" sm="12" >
          <v-text-field v-model="editedItem.Empresa" :rules="EmpresaRules"  label="Empresa" required ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" lg="6" sm="12" >
          <v-text-field filled readonly v-model="editedItem.Email" :rules="emailRules" label="Correo Electronico" required ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" lg="6" sm="12" >
          <v-text-field v-model="editedItem.Telefono" :rules="TelefonoRules" type="number" label="Telefono" required ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" lg="6" sm="12">
          <v-textarea name="input-7-1" label="Descripción" :rules="DescripcionRules" v-model="editedItem.Descripcion" auto-grow
          rows="1" row-height="15" ></v-textarea>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="4" lg="3" sm="6">
         <v-file-input v-model="editedItem.Archivo" label="Archivo a Modificar" ref="Archivo a Modificar" required></v-file-input>
        </v-col>
        <v-col cols="12" md="3" lg="2" sm="5" >
          <v-text-field filled readonly v-model="editedItem.NombreAnexo" label="Archivo" required ></v-text-field>
        </v-col>
        <v-tooltip top>
         <template v-slot:activator="{ on, attrs }">
           <v-btn icon v-bind="attrs" v-on="on" @click="descargarArchivo(editedItem.ConcursoAclaracionId)">
             <v-icon x-large color="blue">mdi-cloud-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar Archivo</span>
        </v-tooltip>         
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="12" md="8" lg="6" sm="12" v-show="valida">
         <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v"> </div>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
      
       <v-col cols="12" md="4" align="center" justify="center">
          <v-btn color="success" class="mr-4" @click="guardar">Guardar</v-btn>
        </v-col>        
      </v-row>
      

    </v-container>
  </v-form>
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
      valid: false,
      editedItem: {
        ConcursoAclaracionId : '',
        ConcursoId : 2,
        Nombre : '',
        RFC : '',
        Empresa : '',
        Email : '',
        Telefono : '',
        Descripcion : '',
        DescripcionAnexos : '',
        Guid : '',
        NombreAnexo : '',
        Archivo : null,
        Respuesta : ''
      },
      valida: 0,
      validaMensaje:[],
      
      nameRules: [
        v => !!v || 'Nombre es requerido',
        v => v.length >= 10 || 'Nombre debe de tener mas de 10 caracteres',
      ],
      emailRules: [
          (v) => !!v || 'Correo Electronico es requerido',
          (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Correo Electronico debe de ser valido'
        ],
      RFCRules: [
          (v) => !!v || 'R.F.C es requerido',
          (v) => /^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/.test(v) || 'R.F.C debe de ser valido'
          
        ],
        EmpresaRules: [
        v => !!v || 'Empresa es requerido'
      ],
      TelefonoRules: [
        v => !!v || 'Telefono es requerido'
      ],
      DescripcionRules: [
        v => !!v || 'Descripcion es requerido',
        v => v.length >= 10 || 'Descripcion debe de tener mas de 10 caracteres',
      ],

    }),

    created () {
      this.Mostrar();
    },

    methods: {

      cargarArhivo(){
        let me = this;
        console.log(me);
        console.log(me.editedItem.Archivo);
        me.editedItem.Archivo = this.$refs.Archivo.files[0];
        console.log(me.editedItem.Archivo);
      },

      descargarArchivo (id) {
        let me = this;  
          //Funciona 
          axios({
          url: 'api/aclaraciones/DescargarArchivo/' + this.editedItem.ConcursoAclaracionId, 
          method: 'GET',
          responseType: 'blob',
          }).then((response) => {                      
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          // fileLink.setAttribute('download', 'file.pdf');
          fileLink.setAttribute('download', me.editedItem.NombreAnexo);
          document.body.appendChild(fileLink);  
          fileLink.click();          
          }).catch(function(error){
              var errormostrar = 'No se puede descargar ningun archivo';
                         
              console.log(errormostrar);
              // me.validaMensaje.push(error.response.request.responseText.headers);

              me.validaMensaje.push(errormostrar);       
              me.valida = true;     
          });
      },


      Mostrar () {
        //Mostrar los datos del registro de Solicitud;
        let me = this;
        me.editedItem.Guid = me.$route.params.guid;               

          // axios.get('api/aclaraciones/Mostrar/' + selectedItem.concursoAclaracionId).then(function(response){
          axios.get('api/aclaraciones/MostrarGuid/' +  me.editedItem.Guid).then(function(response){
            console.log(response.data);
            me.editedItem.ConcursoAclaracionId = response.data.concursoAclaracionId;
            me.editedItem.Nombre = response.data.nombre;
            me.editedItem.RFC = response.data.rfc;
            me.editedItem.Empresa = response.data.empresa;
            me.editedItem.Email = response.data.email;
            me.editedItem.Telefono = response.data.telefono;
            me.editedItem.Descripcion = response.data.descripcion;
            me.editedItem.NombreAnexo = response.data.nombreAnexo;
            me.editedItem.Archivo = response.data.Archivo;
            me.editedItem.Respuesta = response.data.respuesta;
          }).catch(function(error){
            console.log(error);
          });    
                
      },


      guardar () {
        //Crea un nuevo registro o actualiza alguno existente dependiendo de la variable "editedIndex" y de si no existen errores
        //Actualizar registro

        let me = this;

        if (this.editedItem.Respuesta === null) 
        {
          if(this.validarActualizacionRegistro()){
            return;
          }

        
        let formData = new FormData();
        console.log(formData);
        formData.append('ConcursoAclaracionId', me.editedItem.ConcursoAclaracionId);
        formData.append('ConcursoId', me.editedItem.ConcursoId);
        formData.append('Nombre', me.editedItem.Nombre);
        formData.append('RFC', me.editedItem.RFC);
        formData.append('Empresa', me.editedItem.Empresa);
        formData.append('Email', me.editedItem.Email);
        formData.append('Telefono', me.editedItem.Telefono);
        formData.append('Descripcion', me.editedItem.Descripcion);
        formData.append('DescripcionAnexos', me.editedItem.DescripcionAnexos);
        formData.append('NombreAnexo', me.editedItem.NombreAnexo);
        formData.append('Archivo', me.editedItem.Archivo);
        console.log(formData);

        axios.put(
          'api/aclaraciones/actualizar',
          formData,
           {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
        .then(function(response){
          alert("Se guardaron correctamente los datos");
          me.Mostrar();
            // me.close();
            // me.listar();
            // me.limpiar();
          }).catch(function(error){
            me.validaMensaje.push(error.response);
            me.valida = true;
            console.log(error.response);
          });

        }
        else{
          me.validaMensaje.push('La respuesta ya fue dada a su aclaración');
            me.valida = true;
        }
        

      },

      validarActualizacionRegistro(){
        this.valida=0;
        this.validaMensaje=[];

        console.log(this.editedItem.ConcursoAclaracionId);

        if(!this.editedItem.Nombre){
          this.validaMensaje.push("El campo Nombre no puede estar vacío.");
        }
        if(!this.editedItem.RFC){
          this.validaMensaje.push("El campo R.F.C no puede estar vacío.");
        }
        if(!this.editedItem.Empresa){
          this.validaMensaje.push("El campo Empresa no puede estar vacío.");
        }
        if(!this.editedItem.Email){
          this.validaMensaje.push("El campo Email no puede estar vacío.");
        }
        if(!this.editedItem.Telefono){
          this.validaMensaje.push("El campo Telefono no puede estar vacío.");
        }
        if(!this.editedItem.Descripcion){
          this.validaMensaje.push("El campo Descripcion no puede estar vacío.");
        }

        if(this.validaMensaje.length){
          this.valida = 1;
        }

        return this.valida;
      },

      limpiar(){
        this.valida = 0;
        this.validaMensaje = [];        
      }



     
	
    },

    
    
  }
</script>