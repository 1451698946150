<template>
    <div>        
        <h1 >
            Ubicación
        </h1>
        <br>
        <v-divider></v-divider>
        <br>

        <gmap-map
            :center="mapCenter"
            :zoom="12"
            style="width: 100%; height: 440px;"                        
        >                       
         <gmap-marker                                                        
            :position="mapCenter"
            :clickable="true"
            :draggable="false"
                            
         ></gmap-marker>
        </gmap-map>

        <div>
            <h1>Coordenadas:</h1>
            <p><b>{{ myCoordinates.lat }} , {{ myCoordinates.lng }}</b></p>
        </div>

        <!--<div style="max-width: 800px; margin: 0 auto; display: flex; align-items: center; justify-content: space-between"></div>-->

    </div>
</template>
<script>
import axios from 'axios'
    export default {
        data() {
            return {
                map: null,
                myCoordinates: {
                    lat: 0,
                    lng: 0
                },
                zoom: 7,
                lat:0,
                lng:0,
                coordenadas:'',
                listadoCompontente1: [],
                prueba2: []
            }
        },
        props: {
                    concursoid: Number,
                },
        created() {
            // does the user have a saved center? use it instead of the default            
              this.listar();              
        },
        methods: {
            listar(){
                //Enlistar registos
                //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller
                
                let me = this;
                
                let header = {"Authorization":"Bearer " + this.$store.state.token};
                let configuration={headers:header};
                axios.get('api/concursos/MostrarUbicacion/' +  me.concursoid).then(function(response){
                console.log(response.data);
                me.listadoCompontente1 = response.data;
                
                var prueba = response.data.coordenadasFinales.split(",");
                me.coordenadas = response.data.coordenadasFinales;
                me.lat = prueba[0];
                me.lng = prueba[1];
                me.myCoordinates.lat = me.lat;
                me.myCoordinates.lng = me.lng;
             }).catch(function(error){
            console.log(error);
            });
            }

        },
        computed: {
            mapCenter() {
            if (!this.coordenadas.length) {
                return {
                    lat: 10,
                    lng: 10
                }
            }
            return {                                 
                lat: parseFloat(this.lat),
                lng: parseFloat(this.lng)
                }
            }   

        }
    }
</script>
    