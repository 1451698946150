<template>
<div id="divInicio">
    <section>
        <v-card dark>
            <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/FondoNL2.jpg" class="grey lighten-2" height="520" width="100%">
                <v-row class="fill-height pa-3" align="center">
                    <v-col cols="12" md="8" offset="1" class="d-none d-md-block mt-3">
                        <br>
                        <h1 class="display-2 font-weight-bold"  style="margin-top:300px ">
                            Hospitales COVID
                        </h1>
                        <h4 class="display-1 font-weight-light"  >
                            Haciendo frente con infraestructura
                        </h4>
                        <br>
                    </v-col>
                    <v-col cols="10" offset="1" class="d-block d-md-none mt-5" >
                        <br>
                        <h1 class="headline font-weight-bold"  style="margin-top:300px ">
                            Hospitales COVID
                        </h1>
                        <h4 class="headline font-weight-light" >
                            Haciendo frente con infraestructura
                        </h4>
                        <br>
                    </v-col>
                </v-row>
            </v-img>
        </v-card>
        <v-card>
            <v-tabs centered icons-and-text height="200">
                <v-tab>
                    <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/Covid/Metropolitano.jpg">
                    </v-img>
                    Metropolitano
                </v-tab>
                <v-tab>
                    <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/Covid/Juarez.jpg">
                    </v-img>
                    Juarez
                </v-tab>
                <v-tab>
                    <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/Covid/Montemorelos.jpeg">
                    </v-img>
                    Montemorelos
                </v-tab>
                <v-tab>
                    <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/Covid/Sabinas.jpg">
                    </v-img>
                    Sabinas
                </v-tab>
                <v-tab>
                    <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/Covid/HospitalTierrayLibertad.jpeg">
                    </v-img>
                    Tierra y libertad
                </v-tab>
                <v-tab-item>
                    <v-card>
                        <v-card-title class="display-1">
                            Hospital Metropolitano
                        </v-card-title>
                        <div class="container">
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="12" lg="6">
                                    <h3 class="display-1">Descripción</h3>
                                <p>
                                    La Reconversión COVID-19 del Hospital Metropolitano Dr. Bernardo Sepúlveda, Piso 6 Ala Poniente, en el municipio de San Nicolás de los Garza, N.L. deberá garantizar que la organización de los servicios de atención médica se ejecute bajo los principios de oportunidad, calidad y eficiencia de los recursos humanos, materiales y financieros en beneficio de la población y con ello se incida en la prevención y control de la pandemia por la enfermedad del virus SARS-CoV2 en Nuevo León.
                                </p>
                                <p>
                                    Con el objetivo de asegurar el acceso oportuno, así como la calidad de la atención que reciben los pacientes que presentan infección por la enfermedad del virus SARS-CoV2 en las instituciones del Sistema Nacional de Salud, se han considerado los siguientes criterios para la Reconversión COVID-19 del Hospital Metropolitano Dr. Bernardo Sepúlveda, Piso 6 Ala Poniente, en el municipio de San Nicolás de los Garza, N.L.: Coordinación Sectorial para la operación y ejecución Reconversión Hospitalaria COVID-19 a nivel estatal, Operación y Logística para la eficiencia de los recursos para lograr la Reconversión COVID-19 del Hospital Metropolitano Dr. Bernardo Sepúlveda, Piso 6 Ala Poniente, en el municipio de San Nicolás de los Garza, N.L., Valoración de la Capacidad Instalada y el alcance de la Reconversión COVID-19 del Hospital Metropolitano Dr. Bernardo Sepúlveda, Piso 6 Ala Poniente, en el municipio de San Nicolás de los Garza, N.L., Información sistematizada y analizada para la toma de decisiones. Diagnóstico, Tratamiento, Rehabilitación de pacientes con base en la evidencia científica y enfoque de prevención de riesgo de infecciones para reducir la transmisión intrahospitalaria por SARS-CoV-2.
                                </p>
                                </v-col>

                                <v-col cols="12" lg="6">
                                    <h3 class="display-1">Resultados</h3>
                                    <v-row>
                                        <v-col>
                                            <v-carousel hide-delimiters height="300">
                                                <v-carousel-item v-for="(item,i) in MetropolitanoImg" :key="i" :src="item.src" contain reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
                                            </v-carousel>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            
                            <v-divider></v-divider>
                            <h3 class="display-1">Acciones realizadas</h3>
                            <v-row>
                                <v-col>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Descripción
                                                    </th>
                                                    <th class="text-left">
                                                        Inversión
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>RECONVERSION COVID-19 DEL HOSPITAL METROPOLITANO DR, BERNARDO SEPULVEDA EN SAN NICOLAS DE LOS GARZA, NUEVO LEON</td>
                                                    <td>$14,870,000.01</td>
                                                </tr>
                                                <tr>
                                                    <td>RECONVERSION COVID-19 DEL HOSPITAL METROPOLITANO DR, BERNARDO SEPULVEDA PISO 6 LA ORIENTE EN SAN NICOLAS DE LOS GARZA, NUEVO LEON</td>
                                                    <td>$6,999,522.29</td>
                                                </tr>
                                                <tr>
                                                    <td>RECONVERSION COVID-19 DEL HOSPITAL METROPOLITANO DR, BERNARDO SEPULVEDA PISO 6 ALA PONIENTE EN SAN NICOLAS DE LOS GARZA, NUEVO LEON</td>
                                                    <td>$5,979,999.73</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <h3 class="display-1">Ubicación</h3>
                            <v-row>
                                <v-col>
                                    <gmap-map :center="Metropolitano" :zoom="12" style="width: 100%; height: 440px;">
                                        <gmap-marker :position="Metropolitano" ></gmap-marker>

                                    </gmap-map>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-title>
                            <h1 class="display-2">Hospital general de Juárez</h1>
                        </v-card-title>
                        <div class="container">
                            <v-row>
                                <v-col cols="12" lg="6">
                                    <h3 class="display-1">Descripción</h3>
                                    <p>
                                        La Adecuación de Centro de Salud con servicios ampliados para convertirlo en Hospital General de Juárez (COVID-19) deberá garantizar que la organización de los servicios de atención médica se ejecute bajo los principios de oportunidad, calidad y eficiencia de los recursos humanos, materiales y financieros en beneficio de la población y con ello se incida en la prevención y control de la pandemia por la enfermedad del virus SARS-CoV2 en Nuevo León.
                                    </p>
                                    <p>
                                        Con el objetivo de asegurar el acceso oportuno, así como la calidad de la atención que reciben los pacientes que presentan infección por la enfermedad del virus SARS-CoV2 en las instituciones del Sistema Nacional de Salud, se han considerado los siguientes criterios para la Adecuación de Centro de Salud con servicios ampliados para convertirlo en Hospital General de Juárez (COVID-19): Coordinación Sectorial para la operación y ejecución Reconversión Hospitalaria COVID-19 a nivel estatal, Operación y Logística para la eficiencia de los recursos para lograr la Adecuación de Centro de Salud con servicios ampliados para convertirlo en Hospital General de Juárez (COVID-19), Valoración de la Capacidad Instalada y el alcance de la Adecuación de Centro de Salud con servicios ampliados para convertirlo en Hospital General de Juárez (COVID-19), Información sistematizada y analizada para la toma de decisiones. Diagnóstico, Tratamiento, Rehabilitación de pacientes con base en la evidencia científica y enfoque de prevención de riesgo de infecciones para reducir la transmisión intrahospitalaria por SARS-CoV-2.
                                    </p>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <h3 class="display-1">Resultados</h3>
                                    <v-row>
                                        <v-col>
                                            <v-carousel hide-delimiters height="300">
                                                <v-carousel-item v-for="(item,i) in JuarezImg" :key="i" :src="item.src" contain reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
                                            </v-carousel>
                                        </v-col>
                                    </v-row>
                                    </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <h3 class="display-1">Acciones realizadas</h3>
                            <v-row>
                                <v-col>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Descripción
                                                    </th>
                                                    <th class="text-left">
                                                        Inversión
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>ADECUACION DE CENTRO DE SALUD DE SERVICIOS AMPLIADOS PARA CONVERTIRLO EN HOSPITAL GENERAL DE JUAREZ, JUAREZ, N.L.</td>
                                                    <td>$19,940,687.07</td>
                                                </tr>
                                                <tr>
                                                    <td>SUPERVISION DE OBRA, DIRECCION ARQUITECTONICA Y CIERRE ADMINISTRATIVO PARA LA ADECUACION DE CENTRO DE SALUD DE SERVICIOS AMPLIADOS PARA CONVERTIRLO EN HOSPITAL GENERAL, JUAREZ, N. L.</td>
                                                    <td>$600,000.00</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <h3 class="display-1">Ubicación</h3>
                                <v-row>
                                    <v-col>
                                        <gmap-map :center="Juarez" :zoom="12" style="width: 100%; height: 440px;">
                                            <gmap-marker :position="Juarez" ></gmap-marker>
                                        
                                        </gmap-map>
                                    </v-col>
                                </v-row>
                        </div>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-title class="display-2">
                            Nuevo Hospital de Montemorelos
                        </v-card-title>
                        <div class="container">
                            <v-row>
                                
                                <v-col cols="12" lg="6">
                                    <h3 class="display-1">Descripción</h3>
                                    <p>
                                        La Sustitución por Obra Nueva y Equipamiento del Hospital General de Montemorelos, en el municipio de Montemorelos, N.L. deberá garantizar que la organización de los servicios de atención médica se ejecute bajo los principios de oportunidad, calidad y eficiencia de los recursos humanos, materiales y financieros en beneficio de la población y con ello se incida en la prevención y control de la pandemia por la enfermedad del virus SARS-CoV2 en Nuevo León.
                                    </p>
                                    <p>
                                        Con el objetivo de asegurar el acceso oportuno, así como la calidad de la atención que reciben los pacientes que presentan infección por la enfermedad del virus SARS-CoV2 en las instituciones del Sistema Nacional de Salud, se han considerado los siguientes criterios para la Sustitución por Obra Nueva y Equipamiento del Hospital General de Montemorelos, en el municipio de Montemorelos, N.L.: Coordinación Sectorial para la operación y ejecución Reconversión Hospitalaria COVID-19 a nivel estatal, Operación y Logística para la eficiencia de los recursos para lograr la Sustitución por Obra Nueva y Equipamiento del Hospital General de Montemorelos, en el municipio de Montemorelos, N.L., Valoración de la Capacidad Instalada y el alcance de la Sustitución por Obra Nueva y Equipamiento del Hospital General de Montemorelos, en el municipio de Montemorelos, N.L., Información sistematizada y analizada para la toma de decisiones. Diagnóstico, Tratamiento, Rehabilitación de pacientes con base en la evidencia científica y enfoque de prevención de riesgo de infecciones para reducir la transmisión intrahospitalaria por SARS-CoV-2.
                                    </p>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <h3 class="display-1">Resultados</h3>
                                    <v-row>
                                        <v-col>
                                            <v-carousel hide-delimiters height="300">
                                                <v-carousel-item v-for="(item,i) in MontemorelosImg" :key="i" :src="item.src" contain reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
                                            </v-carousel>
                                        </v-col>
                                    </v-row>
                                    
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <h3 class="display-1">Acciones realizadas</h3>
                            <v-row>
                                <v-col>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Descripción
                                                    </th>
                                                    <th class="text-left">
                                                        Inversión
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>SUSTITUCION POR OBRA NUIEVA Y EQUIPAMIENTO DEL HOSPITAL GENERAL DE MONTEMORELOS</td>
                                                    <td>$48,722,980.60</td>
                                                </tr>
                                                <tr>
                                                    <td>REHABILITACION DEL ANTIGUO HOSPITAL GENERAL DE MONTEMORELOS PARA PACIENTES NO COVID-19 EN EL MUNICIPIO DE MONTEMORELOS, NUEVO LEON</td>
                                                    <td>$9,450,230.00</td>
                                                </tr>
                                                <tr>
                                                    <td>SUPERVISION DE OBRA, DIRECCION ARQUITECTONICA Y CIERRE ADMINISTRATIVO PARA EL ANTIGUO HOSPITAL GENERAL DE MONTEMORELOS EN EL PROCESO DE RECONVERSIÓN A CENTRO DE ATENCIÓN COVID-19 EN MONTEMORELOS, N.L.</td>
                                                    <td>$485,000.00</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <h3 class="display-1">Ubicación</h3>
                            <v-row>
                                <v-col>
                                    <gmap-map  :center="Montemorelos" :zoom="12" style="width: 100%; height: 440px;">
                                        <gmap-marker :position="Montemorelos" ></gmap-marker>
                                    </gmap-map>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-title>
                            <h1 class="display-2">Hospital general de Sabinas Hidalgo</h1>
                        </v-card-title>
                        <div class="container">
                            <v-row>
                                <v-col cols="12" lg="6">
                                    <h3 class="display-1">Descripción</h3>
                                    <p>
                                        La Sustitución por obra nueva y equipamiento del proyecto Hospital General de Sabinas Hidalgo, Nuevo León, Obras complementarias en espacios operativos para atención de pacientes COVID-19 en el municipio de Sabinas Hidalgo, N.L. deberá garantizar que la organización de los servicios de atención médica se ejecute bajo los principios de oportunidad, calidad y eficiencia de los recursos humanos, materiales y financieros en beneficio de la población y con ello se incida en la prevención y control de la pandemia por la enfermedad del virus SARS-CoV2 en Nuevo León.
                                    </p>
                                    <p>
                                        Con el objetivo de asegurar el acceso oportuno, así como la calidad de la atención que reciben los pacientes que presentan infección por la enfermedad del virus SARS-CoV2 en las instituciones del Sistema Nacional de Salud, se han considerado los siguientes criterios para la Sustitución por obra nueva y equipamiento del proyecto Hospital General de Sabinas Hidalgo, Nuevo León, Obras complementarias en espacios operativos para atención de pacientes COVID-19 en el municipio de Sabinas Hidalgo, N.L.: Coordinación Sectorial para la operación y ejecución Reconversión Hospitalaria COVID-19 a nivel estatal, Operación y Logística para la eficiencia de los recursos para lograr la Sustitución por obra nueva y equipamiento del proyecto Hospital General de Sabinas Hidalgo, Nuevo León, Obras complementarias en espacios operativos para atención de pacientes COVID-19 en el municipio de Sabinas Hidalgo, N.L., Valoración de la Capacidad Instalada y el alcance de la Sustitución por obra nueva y equipamiento del proyecto Hospital General de Sabinas Hidalgo, Nuevo León, Obras complementarias en espacios operativos para atención de pacientes COVID-19 en el municipio de Sabinas Hidalgo, N.L., Información sistematizada y analizada para la toma de decisiones. Diagnóstico, Tratamiento, Rehabilitación de pacientes con base en la evidencia científica y enfoque de prevención de riesgo de infecciones para reducir la transmisión intrahospitalaria por SARS-CoV-2.
                                    </p>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <h3 class="display-1">Resultados</h3>
                                    <v-row>
                                        <v-col>
                                            <v-carousel hide-delimiters height="300">
                                                <v-carousel-item v-for="(item,i) in SabinasImg" :key="i" :src="item.src" contain reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
                                            </v-carousel>
                                        </v-col>
                                    </v-row>
                                    
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <h3 class="display-1">Acciones realizadas</h3>
                            <v-row>
                                <v-col>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Descripción
                                                    </th>
                                                    <th class="text-left">
                                                        Inversión
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>SUSTITUCION POR OBRA NUEVA Y EQUIPAMIENTO DEL HOSPITAL GENERAL DE SABINAS HIDALGO, NUEVO LEON</td>
                                                    <td>$59,954,370.17</td>
                                                </tr>
                                                <tr>
                                                    <td>SUSTITUCION POR OBRA NUEVA Y EQUIPAMIENTO DEL PROYECTO HOSPITAL GENERAL DE SABINAS HIDALGO, NUEVO LEON, 6a. ETAPA, SEGUNDO NIVEL HOSPITALIZACION, RECONVERSION COVID-19 (ALA NORTE), EN EL MUNICIPIO DE SABINAS HIDALGO NUEVO LEON</td>
                                                    <td>$19,410,670.00</td>
                                                </tr>
                                                <tr>
                                                    <td>SUSTITUCION POR OBRA NUEVA Y EQUIPAMIENTO DEL PROYECTO HOSPITAL GENERAL DE SABINAS HIDALGO, NUEVO LEON, 6a. ETAPA, SEGUNDO NIVEL HOSPITALIZACION, RECONVERSION COVID-19 (ALA SUR), EN EL MUNICIPIO DE SABINAS HIDALGO NUEVO LEON</td>
                                                    <td>$19,335,846.00</td>
                                                </tr>
                                                <tr>
                                                    <td>SUSTITUCION POR OBRA NUEVA Y EQUIPAMIENTO DEL PROYECTO HOSPITAL GENERAL DE SABINAS HIDALGO, NUEVO LEON, OBRA COMPLEMENTARIA EN ESPACOS OPERATIVOS PARA ATENCION DE PACIENTES COVID-19, EN EL MUNICIPIO DE SABINAS HIDALGO, NUEVO LEON</td>
                                                    <td>$16,860,140.00</td>
                                                </tr>
                                                <tr>
                                                    <td>SUSTITUCION POR OBRA NUEVA Y EQUIPAMIENTO DEL PROYECTO HOSPITAL GENERAL DE SABINAS HIDALGO, OBRAS COMPLEMENTARIAS EN ESPACIOS OPERATIVOS Y SUMINISTRO DE OSMOSIS PARA ATENCION DE PACIENTES COVID-19, EN EL MUNICIPIO DE SABINAS HIDALGO, NUEVO LEON</td>
                                                    <td>$18,980,550.00</td>
                                                </tr>
                                                <tr>
                                                    <td>SUPERVISION DE OBRA, DIRECCION ARQUITECTONICA Y CIERRE ADMINISTRATIVO PARA EL HOSPITAL GENERAL DE SABINAS HIDALGO EN PROCESO DE RECONVERSIÓN A CENTRO DE ATENCIÓN COVID-19 EN SABINAS HIDALGO, N.L.</td>
                                                    <td>$2,425,000.00</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <h3 class="display-1">Ubicación</h3>
                            <v-row>
                                <v-col>
                                    <gmap-map :center="Sabinas" :zoom="12" style="width: 100%; height: 440px;">
                                        <gmap-marker :position="Sabinas" ></gmap-marker>
                                    </gmap-map>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-title>
                            <h1 class="display-2">Hospital de Tierra y Libertad</h1>
                        </v-card-title>
                        <div class="container">
                            <v-row>
                                <v-col cols="12" lg="6">
                                    <h3 class="display-1">Descripción</h3>
                                    <p>
                                        La Reconversión hospitalaria del Hospital Tierra y Libertad (COVID-19), Planta Baja, en el municipio de Monterrey, N.L deberá garantizar que la organización de los servicios de atención médica se ejecute bajo los principios de oportunidad, calidad y eficiencia de los recursos humanos, materiales y financieros en beneficio de la población y con ello se incida en la prevención y control de la pandemia por la enfermedad del virus SARS-CoV2 en Nuevo León.
                                    </p>
                                    <p>
                                        Con el objetivo de asegurar el acceso oportuno, así como la calidad de la atención que reciben los pacientes que presentan infección por la enfermedad del virus SARS-CoV2 en las instituciones del Sistema Nacional de Salud, se han considerado los siguientes criterios para la Reconversión hospitalaria del Hospital Tierra y Libertad (COVID-19), Planta Baja: Coordinación Sectorial para la operación y ejecución Reconversión Hospitalaria COVID-19 a nivel estatal, Operación y Logística para la eficiencia de los recursos para lograr la Reconversión hospitalaria del Hospital Tierra y Libertad (COVID-19), Planta Baja, Valoración de la Capacidad Instalada y el alcance de la Reconversión hospitalaria del Hospital Tierra y Libertad (COVID-19), Planta Baja, Información sistematizada y analizada para la toma de decisiones. Diagnóstico, Tratamiento, Rehabilitación de pacientes con base en la evidencia científica y enfoque de prevención de riesgo de infecciones para reducir la transmisión intrahospitalaria por SARS-CoV-2.
                                    </p>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <h3 class="display-1">Resultados</h3>
                                    <v-row>
                                        <v-col>
                                            <v-carousel hide-delimiters height="300">
                                                <v-carousel-item v-for="(item,i) in TierraImg" :key="i" :src="item.src" contain reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
                                            </v-carousel>
                                        </v-col>
                                    </v-row>
                                    
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <h3 class="display-1">Acciones realizadas</h3>
                            <v-row>
                                <v-col>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Descripción
                                                    </th>
                                                    <th class="text-left">
                                                        Inversión
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>RECONVERSION HOSPITALARIA DEL HOSPITAL TIERRA Y LIBERTAD (COVI-19) PLANTA BAJA, EN EL MUNICIPIO DE MONTEREY, NUEVO LEON</td>
                                                    <td>$12,032,433.18</td>
                                                </tr>
                                                <tr>
                                                    <td>RECONVERSION HOSPITALARIA DEL HOSPITAL TIERRA Y LIBERTAD (COVI-19), PISO 1 EN EL MUNICIPIO DE MONTEREY, NUEVO LEON</td>
                                                    <td>$4,482,907.81</td>
                                                </tr>
                                                <tr>
                                                    <td>RECONVERSION HOSPITALARIA DEL HOSPITAL TIERRA Y LIBERTAD (COVI-19), PISO 2 EN EL MUNICIPIO DE MONTEREY, NUEVO LEON</td>
                                                    <td>$4,437,384.44</td>
                                                </tr>
                                                <tr>
                                                    <td>RECONVERSION HOSPITALARIA DEL HOSPITAL TIERRA Y LIBERTAD (COVI-19), PISO 3 EN EL MUNICIPIO DE MONTEREY, NUEVO LEON</td>
                                                    <td>$11,221,420.61</td>
                                                </tr>
                                                <tr>
                                                    <td>RECONVERSION HOSPITALARIA DEL HOSPITAL TIERRA Y LIBERTAD (COVI-19), PISO 4 LADO NORTE EN EL MUNICIPIO DE MONTEREY, NUEVO LEON</td>
                                                    <td>$7,046,605.33</td>
                                                </tr>
                                                <tr>
                                                    <td>RECONVERSION HOSPITALARIA DEL HOSPITAL TIERRA Y LIBERTAD (COVI-19), PISO 4 LADO SUR EN EL MUNICIPIO DE MONTEREY, NUEVO LEON</td>
                                                    <td>$6,504,558.77</td>
                                                </tr>
                                                <tr>
                                                    <td>SUPERVISION DE OBRA, DIRECCION ARQUITECTONICA Y CIERRE ADMINISTRATIVO PARA HOSPITAL TIERRA y LIBERTAD EN PROCESO DE RECONVERSIÓN A CENTRO DE ATENCIÓN COVID-19</td>
                                                    <td>$1,500,000.00</td>
                                                </tr>
                                                <tr>
                                                    <td>RECONVERSION HOSPITALARIA DEL HOSPITAL TIERRA Y LIBERTAD (COVID-19) (AREA DE IMAGENOLOGÍA, TOCOCIRUGÍA, QUIROFANOS, OBRA EXTERIOR) EN EL MUNICIPIO DE MONTEREY, NUEVO LEON</td>
                                                    <td>$14,942,262.10</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <h3 class="display-1">Ubicación</h3>
                            <v-row>
                                <v-col>
                                    <gmap-map :center="Tierra" :zoom="12" style="width: 100%; height: 440px;">
                                        <gmap-marker :position="Tierra" ></gmap-marker>
                                    </gmap-map>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </section>
</div>
</template>

<script>
export default {
    data() {
        return {
            dialog1: false,
            dialog2: false,
            dialog3: false,
            dialog4: false,
            dialog5: false,
            tablaMetropolitano: [],
            tablaJuarez: [],
            tablaMontemorelos: [],
            tablaSabinas: [],
            tablaTierra: [],
            Metropolitano: {
                lat: 25.714075,
                lng: -100.274371
            },
            Juarez: {
                lat: 25.653863,
                lng: -100.081956
            },
            Montemorelos: {
                lat: 25.142697,
                lng: -99.810820
            },
            Sabinas: {
                lat: 26.496597,
                lng: -100.169864
            },
            Tierra: {
                lat: 25.740415,
                lng: -100.342051
            },
            path: [],
            paths: [],
            MetropolitanoImg: [{
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Metropolitano1.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Metropolitano2.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Metropolitano3.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Metropolitano4.png"
                },
            ],
            JuarezImg: [{
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Juarez1.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Juarez2.jpg"
                },

            ],
            MontemorelosImg: [{
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Montemorelos1.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Montemorelos2.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Montemorelos3.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Montemorelos4.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Montemorelos5.jpeg"
                },

            ],
            SabinasImg: [{
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Sabinas1.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Sabinas2.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Sabinas3.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Sabinas4.jpg"
                },
            ],
            TierraImg: [{
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Tierra1.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Tierra2.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Tierra3.jpg"
                },
                {
                    src: "https://smpu.nl.gob.mx/acceso/siasiportal/img/covid/Tierra4.jpg"
                },
            ],
            infoContent: '',
            infoWindowPos: {
                lat: 0,
                lng: 0
            },
            infoWinOpen: false,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
        }
    },
    compnents: {

    },
    methods: {
        Mostrargaleria() {
            let me = this;
            me.dialog = true;
        },
        toggleInfoWindow: function (marker, idx) {
            this.infoWindowPos = marker.marcadores;
            this.infoContent = this.getInfoWindowContent(marker);
            console.log(marker.tituloConcurso);
            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        getInfoWindowContent: function (marker) {
            return (
                `<div class="">
          <div>
            <div>
              <div class="m-2"><span style="font-weight: bold;">Titulo: </span>

              </div>
            </div>
            <div class="m-2"><span style="font-weight: bold;">Numero de concurso:  </span>

              <br>
            </div>
            <div class="m-2"><span style="font-weight: bold;">Estatus:  </span>

              <br>
            </div>
            <div class="m-2"><span style="font-weight: bold;">Numero de concurso:  </span>

              <br>
            </div>
          </div>
        </div>`);
        },
    }
}
</script>

<style>
.v-image__image {
    transition: .3s linear;
}
</style>
