<template>                       
    <div>
        <div @click="expandirColapsar" style="cursor:pointer" :class="'partida color-' + depth" :key="partidaId" :style="{'margin-left': `${depth * 20}px`}">
            <!-- <span class="type">{{isExpanded(node) ? '&#9660;' : '&#9658;'}}</span> -->
            <span>{{this.partida.partida}} {{this.descripcion}}</span>
        </div>         
        <itemPartida 
            v-for="itemPartida in this.partida.partidas" 
            :partidaId="itemPartida.idPresupuestoPartida" 
            :descripcion="itemPartida.descripcionPartidaLarga" 
            :partida="itemPartida" :depth="depth + 1" 
            :key="itemPartida.idPresupuestoPartida"
            :style="{display:mostrarPartidas ? 'block' : 'none'}">                      
        </itemPartida>  
        <table 
            v-if="this.partida.conceptos && mostrarConceptos"  
            :style="{'margin-left': `${(depth + 1) * 20}px`,display: this.partida.conceptos && mostrarConceptos ? 'block' : 'none'}">
            <tbody>
                <tr v-for="concepto in this.partida.conceptos" :key="concepto.idCodigo" class="concepto-tr">
                    <td class="concepto-td"><b></b>{{concepto.codigo}}</td>
                    <td class="concepto-td">{{concepto.descripcionLarga}}</td>
                    <td class="concepto-td">{{concepto.unidad}}</td>
                </tr>
            </tbody>
        </table> 
           
    </div>
</template>

<script>
import axios from 'axios'
// import itemPartida from './ItemConcepto'

export default {
    data: () => ({
        mostrarPartidas: false,
        mostrarConceptos: false,
        //codigoPartida: ""

    }),

    // components: {
    //     itemConcepto
    // },
    name:"ItemPartida",

    props: {
        partidaId: Number,
        // codigoPartida: {
        //     type: String,
        //     default: "",
        // },
        descripcion: String,
        partida: Object,
        depth: {
            type: Number,
            default: 0,
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
    },

    created() {

        // console.log("partidaId",this.partidaId);
        // console.log("descripcion",this.descripcion.length);
        // console.log("partida",this.partida);

    },

    methods: {
        expandirColapsar(){
            if(this.partida.partidas){
                this.mostrarPartidas = !this.mostrarPartidas;
            }
            else if(this.partida.conceptos){
                this.mostrarConceptos = !this.mostrarConceptos;
            }
            else{
                console.log("No hay partidas ni conceptos por desplegar en concepto: " + this.partidaId);
            }
        }
    }

}
</script>

<style scoped>
    .partida{        
        text-align: left;
        font-size: 18px;
    }    
    .color-0{  
        border-bottom: gray solid thin;
        padding: 4px 0px;
        color: white;      
        background-color: #0D47A1;
    }   
    .color-1{   
        border-bottom: gray solid thin;
        padding: 4px 0px;
        color: white;      
        background-color: #1565C0;
    } 
    .color-2{ 
        border-bottom: gray solid thin;
        padding: 4px 0px;
        color: white;        
        background-color: #1976D2;
    } 
    .color-3{ 
        border-bottom: gray solid thin;
        padding: 4px 0px;
        color: white;        
        background-color: #1E88E5;
    } 
    .color-4{ 
        border-bottom: gray solid thin;
        padding: 4px 0px;
        color: white;        
        background-color: #42A5F5;
    } 
    .concepto-tr{ 
        margin: 2px; 
        padding: 2px; 
        width: 100%;      
        border: black solid thin;
    }
    .concepto-td{ 
        margin: 2px; 
        padding: 2px;        
        border: black solid thin;
    }
</style>