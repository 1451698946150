<template>
<v-layout align-start>
    <v-flex>
        <h1>Licitantes</h1>
        <v-spacer></v-spacer>

        <v-card class="mx-auto" color="#26c6da" dark max-width="90%" v-if="defaultItem.TipoConcurso =='Licitación Federal'">
            <v-card-text>
                En el <b>Lineamiento Quinto del Artículo Segundo del Acuerdo</b> por el que se emiten diversos Lineamientos en Materia de Adquicisiones,
                Arrendamientos y Servicio y de Obras Públicas y Servicios Relacionados con las Mismas publicado por la Secretaría de la Función
                Pública el 9 de Septiembre de 2010 en el Diario Oficial de la Federación, qu establece que sólo procederá a realizarse la evaluación
                de las propuestas ecónomicas de aquéllas proposiciones cuya propuesta técnica resulte solvente por <b> haber obtenido la puntuación o unidades
                    porcentuales iguales o superiores al mínimo establecido en la convocatoria a licitación.</b>
            </v-card-text>
        </v-card>
        <br>
        <v-data-table :headers="computedheaders" :items="listadoCompontente1" :search="search" class="elevation-1">

            <template v-slot:item.propuestaEconomica="{ item }">
                {{ Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.propuestaEconomica) }}
            </template>

            <template v-slot:item.total="{ item }">
                {{ item.puntosTecnicos + item.puntosEconomicos }}
            </template>

            <template v-slot:item.puntosTecnicos="{ item }">
                {{ item.puntosTecnicos }}
            </template>

            <template v-slot:item.puntosEconomicos="{ item }">
                {{ item.puntosEconomicos }}
            </template>

            <template v-slot:item.eliminar="{ item }">
                <v-icon color="red" small @click="desactivarItem(item)" class="mr-2">
                    mdi-trash-can-outline
                </v-icon>

            </template>

            <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" v-if="item.ganador">
                            <v-icon x-large color="yellow">mdi-trophy</v-icon>
                        </v-btn>

                        <v-btn icon v-bind="attrs" v-on="on" v-if="item.descalificado">
                            <v-icon x-large>mdi-cancel</v-icon>
                        </v-btn>
                    </template>
                    <span v-if="item.ganador">Licitante Ganador</span>
                    <span v-if="item.descalificado">Licitante descalificado</span>

                </v-tooltip>

            </template>
        </v-data-table>
    </v-flex>
</v-layout>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({

        inset: false,
        items: [
            'default',
            'vertical',
        ],
        variant: 'default',

        listadoCompontente1: [],
        search: '',
        GanadorSelect: [],
        LicitanteSelect: [],
        headers: [{
                text: '',
                value: 'actions',
                sortable: false ,
                class: "indigo white--text title",
            },
            {
                text: 'Licitante',
                value: 'contratistaNombre',
                class: "indigo white--text title",
            },
            {
                text: 'Propuesta Economica',
                value: 'propuestaEconomica',
                class: "indigo white--text title",
            },
            {
                text: 'Total',
                value: 'total',
                class: "indigo white--text title",
            },
            {
                text: 'Revisión Técnica',
                value: 'puntosTecnicos',
                class: "indigo white--text title",
            },
            {
                text: 'Revisión Económica',
                value: 'puntosEconomicos',
                class: "indigo white--text title",
            },

            // { text: 'Revisión Tecnica', value: 'puntosTecnicos' },
            // { text: 'Revisión Economica', value: 'puntosEconomicos' },
            // { text: 'Total', value: 'puntosTecnicosEconomicos' },
        ],
        headers2: [{
                text: 'Licitante',
                value: 'contratistaNombre'
            },
            {
                text: 'Propuesta Economica',
                value: 'propuestaEconomica'
            },
            {
                text: '',
                value: 'actions',
                sortable: false
            },
            {
                text: 'Revisión Tecnica',
                value: 'puntosTecnicos'
            },
            {
                text: 'Revisión Economica',
                value: 'puntosEconomicos'
            },
            {
                text: 'Total',
                value: 'puntosTecnicosEconomicos'
            },
        ],
        editedIndex: -1,
        defaultItem: {
            ConcursoId: '',
            ContratistasNombre: '',
            ConcursoContratistaId: '',
            ConcursoContratistaIdSelect: '',
            PropuestaEconomica: '',
            Descalificado: false,
            Ganador: false,
            TipoConcurso: '',
            PuntosTecnicos: '',
            PuntosEconomicos: '',
            PuntosTecnicosEconomicos: ''
        },
        ContratistaItem: {
            Nombre: '',
            RFC: '',
            Email: '',
            Telefono: '',
            ReprecentanteLegal: '',
            CaracterRepresentante: '',
            Calle: '',
            NumeroExterior: '',
            NumeroInterior: '',
            Colonia: '',
            CodigoPostal: '',
            Municipio: '',
            Localidad: ''
        },

        GanadorItem: {
            ConcursoId: '',
            ConcursoContratistaId: ''
        },
        ConcursoContratistaItem: {
            ConcursoId: '',
            ContratistaId: '',
            PropuestaEconomica: '',
            PuntosEconomicos: 0.00,
            PuntosTecnicos: 0.00
        },

        valida: 0,
        validaMensaje: []
    }),

    props: {
        concursoid: Number,
        tituloproyecto: String,
        claveproyecto: String,
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nueva aclaración' : 'Solicitud'
        },

        computedheaders() {

            if (this.defaultItem.TipoConcurso == 'Licitación Federal') {
                return this.headers2;
            } else {
                return this.headers;
            }

        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialog2(val) {
            val || this.close2()
        },
    },

    created() {
        this.listar();
        this.LLenarGanador();
        this.LLenarContratista();
    },

    methods: {



        onChangeLicitante(event) {
            this.ConcursoContratistaItem.ContratistaId = event.contratistaId;
        },

        LLenarGanador() {
            //Enlistar registos
            //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

            let me = this;

            axios.get('api/concursos/LlenarGanador/' + me.concursoid).then(function (response) {
                me.GanadorSelect = response.data;
            }).catch(function (error) {
                console.log(error);
            });
        },

        LLenarContratista() {
            //Enlistar registos
            //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

            let me = this;

            axios.get('api/concursos/LlenarContratista').then(function (response) {
                me.LicitanteSelect = response.data;
            }).catch(function (error) {
                console.log(error);
            });
        },


        listar() {
            //Enlistar registos
            //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

            let me = this;

            me.defaultItem.ConcursoId = me.concursoid;
            let header = {
                "Authorization": "Bearer " + this.$store.state.token
            };
            let configuration = {
                headers: header
            };
            axios.get('api/concursos/MostrarLicitantes/' + me.defaultItem.ConcursoId).then(function (response) {
                me.listadoCompontente1 = response.data;
                me.defaultItem.TipoConcurso = response.data[0].tipoConcurso;
                me.defaultItem.Ganador = response.data[0].ganador;
            }).catch(function (error) {
                console.log(error);
            });
        },

        desactivarItem(item) {

            let me = this;

            //Este es el item que se va a desactivar
            var selectedItem = Object.assign({}, item)

            if (confirm('¿Estás seguro de querer desactivar este registro?')) {
                console.log("Si");
                axios.put('api/concursos/DesactivarLicitante/' + selectedItem.concursoContratistaId).then(function (response) {
                    me.listar();
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                console.log("No");
            }

        },



        limpiar() {
            this.editedIndex = -1;
            this.valida = 0;
            this.validaMensaje = [];

            this.defaultItem = {
                ConcursoId: '',
                ContratistasNombre: '',
                ConcursoContratistaId: '',
                ConcursoContratistaIdSelect: '',
                PropuestaEconomica: '',
                Descalificado: false,
                Ganador: false,
                TipoConcurso: '',
                PuntosTecnicos: '',
                PuntosEconomicos: '',
                PuntosTecnicosEconomicos: ''
            };
            this.ContratistaItem = {
                Nombre: '',
                RFC: '',
                Email: '',
                Telefono: '',
                ReprecentanteLegal: '',
                CaracterRepresentante: '',
                Calle: '',
                NumeroExterior: '',
                NumeroInterior: '',
                Colonia: '',
                CodigoPostal: '',
                Municipio: '',
                Localidad: ''
            };

            this.GanadorItem = {
                ConcursoId: '',
                ConcursoContratistaId: ''
            };

        }
    },
}
</script>