<template>
<div id="divInicio">
    <!-- inicio -->

    <section height="250" width="80%">
        <v-card style="color:rgb(68, 40, 10)">
            <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/FondoNL2.jpg" height="520" width="100%">
                <v-carousel hide-delimiters height="520" cycle>
                    <v-carousel-item v-for="(item, i) in Titulos" :key="i">
                        <div style="margin-top:300px ">
                            <v-col cols="12" md="8" offset="1" class="d-none d-md-block mt-3">
                                <br>
                                <h1 class="display-2 font-weight-bold white--text">
                                    {{item.titulo}}
                                </h1>
                                <h4 class="headline white--text">
                                    {{item.subtitulo}}
                                </h4>
                                <br>
                                <v-btn v-if="item.btn" primary @click="mostrar(item.modal)" :to="item.vinculo" :href="item.link" target="_blank" color="#00ce7c" dark>{{item.btn}}</v-btn>
                            </v-col>
                            <v-col cols="10" offset="1" class="d-block d-md-none mt-5">
                                <br>
                                <h1 class="headline font-weight-bold white--text">
                                    {{item.titulo}}
                                </h1>
                                <h4 class="headline  white--text">
                                    {{item.subtitulo}}
                                </h4>
                                <br>
                                <v-btn v-if="item.btn" @click="mostrar(item.modal)" small :to="item.vinculo" :href="item.link" target="_blank" color="#00ce7c" dark>{{item.btn}}</v-btn>
                            </v-col>

                        </div>
                    </v-carousel-item>
                </v-carousel>
            </v-img>
        </v-card>
    </section>
    <section class="container">
        <v-row>
            <br>
            
            <v-col cols="12" sm="12" md="8" >
            <v-subheader style="margin-bottom: 30px;" class="display-1">Noticias</v-subheader>
                <v-row>
                    <v-col>
                        <v-card width="100%" >
                            <v-carousel  flat hide-delimiters cycle>
                                <v-carousel-item v-for="item in listadoNoticias" flat :key="item.noticiaId"  >
                                <v-card class="container" flat light :to="'/noticias/' + item.noticiaId " outlined>
                                    <v-img v-if="item.imagen" :src="'https://smpu.nl.gob.mx'+ item.imagen"  max-height="410" ></v-img>
                                    <v-img v-if="!item.imagen" src='https://smpu.nl.gob.mx/acceso/assets/apoyocontratistas/LogoSI.svg' max-height="410"></v-img>
                                    <v-card-title style="word-break:normal" v-text="item.tituloNoticia">
                                    </v-card-title>
                                </v-card>
                                </v-carousel-item>
                            </v-carousel>
                        </v-card>
                    </v-col>
                    <br>
                </v-row>
                <br>
                <v-row>
                    <v-col>
                        <!-- <v-divider></v-divider> -->
                    </v-col>
                </v-row>
                <br>


                

            </v-col>
            
            <v-col cols="12" sm="12" md="4">
                <v-subheader class="display-1">Temas de interés</v-subheader>
                <v-row style="padding:1rem;">
                 
                <v-col cols="12">
                    <v-dialog v-model="dialog3" width="1200">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn x-large width="100%" color="#72cd80" dark v-bind="attrs" v-on="on">Próximos eventos
                                <v-icon x-large >event</v-icon>
                            </v-btn>
                        </template>

                        <v-card >
                            <v-card-title class="headline grey lighten-2">
                                Próximos eventos
                            </v-card-title>
                            <v-card-body>
                                <v-row class="fill-height">
                                    <v-col>
                                        <v-sheet height="64">
                                            <v-toolbar flat>
                                                <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                                                    Hoy
                                                </v-btn>
                                                <v-btn fab text small color="grey darken-2" @click="prev">
                                                    <v-icon small>
                                                        mdi-chevron-left
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn fab text small color="grey darken-2" @click="next">
                                                    <v-icon small>
                                                        mdi-chevron-right
                                                    </v-icon>
                                                </v-btn>
                                                <v-toolbar-title v-if="$refs.calendar">
                                                    {{ $refs.calendar.title }}
                                                </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-btn color="primary" outlined v-if="type != 'month'" @click="type = 'month'">Vista Mensual</v-btn>
                                            </v-toolbar>
                                        </v-sheet>
                                        <v-sheet height="600">
                                            <v-calendar ref="calendar" v-model="focus" color="primary" :events="events" :type="type" interval-height="43" first-interval="7" interval-count="12" @click:event="showEvent" @click:more="viewDay" @click:date="viewDay" locale="es-mx"></v-calendar>
                                            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x locale="es-mx">
                                                <v-card color="grey lighten-4" min-width="340px" flat>
                                                    <v-toolbar :color="selectedEvent.color" dark>
                                                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                                                        <v-spacer></v-spacer>
                                                    </v-toolbar>
                                                    <v-card-text>
                                                        <span v-html="selectedEvent.details"></span>
                                                        <br>
                                                        <br>
                                                        <span v-html="selectedEvent.numero"></span>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-btn text color="secondary" @click="selectedOpen = false">
                                                            Cerrar
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-menu>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-card-body>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialog3 = false">
                                    Salir
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
                <v-col >
                    
                    <v-card href="https://smpu.nl.gob.mx/transparencia/" target="_blank">
                        <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/InfraAbierta.png" contain max-height="230"   alt="en proceso"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="12" xl="6">
                    <v-card to="/EnProceso" min-height="97" class="d-none d-sm-block d-md-none d-xl-block">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Obras en proceso</v-card-text>
                    </v-card>
                    <v-card to="/EnProceso" class="d-block d-sm-none d-md-block d-xl-none">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Obras en proceso</v-card-text>
                    </v-card>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="12" xl="6">
                    <v-card to="/COVID"  min-height="97" class="d-none d-sm-block d-md-none d-xl-block">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Hospitales COVID</v-card-text>
                    </v-card>
                    <v-card to="/COVID" class="d-block d-sm-none d-md-block d-xl-none">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Hospitales COVID</v-card-text>
                    </v-card>
                </v-col> -->

                <v-col cols="12" sm="6" md="12" xl="6">
                    <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/LINEAMIENTOS%20B.P.%20VF.pdf"  min-height="97" class="d-none d-sm-block d-md-none d-xl-block">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Lineamientos del Banco de Programas y Proyectos de Inversión</v-card-text>
                    </v-card>
                    <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/LINEAMIENTOS%20B.P.%20VF.pdf" class="d-block d-sm-none d-md-block d-xl-none">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Lineamientos del Banco de Programas y Proyectos de Inversión</v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="12" xl="6">
                    <v-card to="/AvisosDePrivacidad"  min-height="97" class="d-none d-sm-block d-md-none d-xl-block">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Avisos de Privacidad</v-card-text>
                    </v-card>
                    <v-card to="/AvisosDePrivacidad" class="d-block d-sm-none d-md-block d-xl-none">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Avisos de Privacidad</v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="12" xl="6">
                    <v-card target="_blank" href="https://drive.google.com/drive/folders/1CjEbzV-nET4vkRaBVaxa2m4UHndH9K0U"  min-height="97" class="d-none d-sm-block d-md-none d-xl-block">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Riesgo Hidrometeorológico en el Río Santa Catarina</v-card-text>
                    </v-card>
                    <v-card target="_blank" href="https://drive.google.com/drive/folders/1CjEbzV-nET4vkRaBVaxa2m4UHndH9K0U" class="d-block d-sm-none d-md-block d-xl-none">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Riesgo Hidrometeorológico en el Río Santa Catarina</v-card-text>
                    </v-card>
            </v-col>

            <v-col style="" cols="12" sm="6" md="12" xl="6">
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/PROGRAMA%20ANUAL%20DE%20OBRA%20ANALITIO%202022.pdf"  min-height="97" class="d-none d-sm-block d-md-none d-xl-block">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Programa Anual de Obra Anatilio</v-card-text>
                </v-card>
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/PROGRAMA%20ANUAL%20DE%20OBRA%20ANALITIO%202022.pdf" class="d-block d-sm-none d-md-block d-xl-none">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Programa Anual de Obra Anatilio</v-card-text>
                </v-card>
            </v-col>

                <!-- <v-col cols="12" sm="4" md="12" xl="4">
                    <v-card to="/AlianzaReal" min-height="97" class="d-none d-sm-block d-md-none d-xl-block">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Alianza Real</v-card-text>
                    </v-card>
                    <v-card to="/AlianzaReal" class="d-block d-sm-none d-md-block d-xl-none">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Alianza Real</v-card-text>
                    </v-card>
                </v-col> -->

                </v-row>
            </v-col>
        </v-row>

        <v-row class="container-1">
            <v-col style="margin-top: -283px;" cols="12" sm="6" md="12" xl="6">
                <v-col style="padding-right: 400px; padding-left: 400px;">
                    <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/046_MIR_INFRAESTRUCTURA_2022.pdf" class="d-none d-sm-block d-md-none d-xl-block">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Matriz de Indicadores de Resultados 2022</v-card-text>
                    </v-card>
                    <v-card style="margin-left: -400px;" target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/046_MIR_INFRAESTRUCTURA_2022.pdf" class="d-block d-sm-none d-md-block d-xl-none">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Matriz de Indicadores de Resultados 2022</v-card-text>
                    </v-card>
                </v-col>
            </v-col>

            <v-col style="margin-top: -283px;" cols="12" sm="6" md="12" xl="6">
                <v-col style="padding-right: 400px; padding-left: 400px;">
                    <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/046_MIR_INFRAESTRUCTURA_2023.pdf" class="d-none d-sm-block d-md-none d-xl-block">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Matriz de Indicadores de Resultados 2023</v-card-text>
                    </v-card>
                    <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/046_MIR_INFRAESTRUCTURA_2023.pdf" class="d-block d-sm-none d-md-block d-xl-none">
                        <v-card-text class="headline"  style="word-break:normal; text-align:center">Matriz de Indicadores de Resultados 2023</v-card-text>
                    </v-card>
                </v-col>
            </v-col>
        </v-row>
        
        <!-- ¡¡ Next row !! -->

        <v-row class="container-1">
            <v-col style="margin-top: -148px;" cols="12" sm="6" md="12" xl="6">
                <v-col style="padding: 0px 400px 0px 400px;">
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/TESTIMONIO%20FINAL%20Tren%20Mty.pdf" class="d-none d-sm-block d-md-none d-xl-block">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">TESTIMONIO FINAL Tren Mty</v-card-text>
                </v-card>
                <v-card style="margin-left: -400px;" target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/TESTIMONIO%20FINAL%20Tren%20Mty.pdf" class="d-block d-sm-none d-md-block d-xl-none">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Testimonio Final Tren Monterrey</v-card-text>
                </v-card>
                </v-col>
            </v-col>

            <v-col style="margin-top: -148px" cols="12" sm="6" md="12" xl="6">
                <v-col style="padding: 0px 400px 0px 400px;">
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/PROGRAMA%20ANUAL%20DE%20OBRAS%20ESTATALES%2025-11-2022.pdf" class="d-none d-sm-block d-md-none d-xl-block">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Programa Anual de Obra Estatales</v-card-text>
                </v-card>
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/PROGRAMA%20ANUAL%20DE%20OBRAS%20ESTATALES%2025-11-2022.pdf" class="d-block d-sm-none d-md-block d-xl-none">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Programa Anual de Obra Estatales</v-card-text>
                </v-card>
                </v-col>
            </v-col>

        </v-row>
        
    </section>
    <v-subheader class="display-1 container">Resultados</v-subheader>
            <v-divider></v-divider>
            <br>
            <br>
    <section>
        <v-card outlined dark color="#bc8d52" tile>
            <v-card-text class="container ">
                <v-row  >
                    <v-col cols="12" sm="12" lg="4">
                        <v-card class="mx-auto"   outlined dark color="#bc8d52">
                            <v-row>
                            <v-col cols="12">
                            <v-card-title>
                                <h1 style="font-weight: 500; font-style: italic;" class="display-1">{{"$"+ new Intl.NumberFormat("en-US").format(total)}}</h1>
                            </v-card-title>
                            
                            <v-card-subtitle style="color:white" class="headline">
                                Inversión de Obras y servicios
                            </v-card-subtitle>
                                <v-select v-model="seleccion" chips multiple class="pl-4" label="Años" return-object :items="indicadores" item-text="anio"  clearable @change="sumas()">
                                </v-select>
                            </v-col>
                            </v-row>
                        </v-card>
                        <v-row>
                            <v-col cols="6" sm="4" lg="12" class="pa-0">
                                <v-card class="mx-auto" outlined dark color="#bc8d52">
                                    <v-card-title>
                                        <h1 style="font-weight: 500; font-style: italic;" class="display-1">{{municipios}}</h1>
                                    </v-card-title>
                                    <v-card-subtitle style="color:white" class="headline">
                                        Municipios beneficiados
                                    </v-card-subtitle>
                                </v-card>
                                </v-col>
                                <v-col cols="6" sm="4" lg="12" class="pa-0">
                                <v-card class="mx-auto"  outlined dark color="#bc8d52">
                                    <v-card-title>
                                        <h1 style="font-weight: 500; font-style: italic;" class="display-1">{{contratistasAdjudicados}}</h1>
                                    </v-card-title>
                                    <v-card-subtitle style="color:white" class="headline">
                                        Contratistas adjudicados
                                    </v-card-subtitle>
                                </v-card>
                                </v-col>
                                <v-col cols="12" sm="4" lg="12" class="pa-0">
                                <v-card class="mx-auto"  outlined dark color="#bc8d52">
                                    <v-card-title>
                                        <h1 style="font-weight: 500; font-style: italic;" class="display-1">{{TotalContratos}}</h1>
                                    </v-card-title>
                                    <v-card-subtitle style="color:white" class="headline">
                                        Total de contrataciones
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                        
                    </v-col>
                    <v-col cols="12" sm="12" lg="8">
                        
                        <v-row>
                            <v-tabs background-color="#bc8d52" >
                                <v-tab class="headline">
                                    Contratos
                                </v-tab>
                                <v-tab class="headline">
                                    Inversión
                                </v-tab>
                                <v-tab-item >
                                    <v-card  tile flat class="d-block d-sm-none" dark color="#bc8d52">
                                        <v-carousel>
                                            <v-carousel-item >
                                                <h1 class="headline mx-3">Programa o Proyecto de Inversión </h1>
                                                <div id="chart">
                                                    <apexchart type="pie" :options="OpcionesSectores" :series="PorcentajeSectores"></apexchart>
                                                </div>
                                            </v-carousel-item>
                                            <v-carousel-item >
                                                <h1 class="headline mx-3">Obra o Servicio </h1>
                                                <div id="chart">
                                                    <apexchart type="pie" :options="OpcionesObra" :series="PorcentajeObra"></apexchart>
                                                </div>
                                            </v-carousel-item>
                                            <v-carousel-item >
                                                <h1 class="headline mx-3">Estatus de obras</h1>
                                                <div id="chart">
                                                    <apexchart type="pie" ref="realtimeChart" :options="OpcionesEstatus" :series="PorcentajeEstatus"></apexchart>
                                                </div>
                                            </v-carousel-item>
                                            <v-carousel-item >
                                                <h1 class="headline mx-3">Procesos de contratación</h1>
                                                <div id="chart">
                                                    <apexchart type="pie" ref="realtimeChart" :options="OpcionesProcesos" :series="PorcentajeProcesos"></apexchart>
                                                </div>
                                            </v-carousel-item>
                                        </v-carousel>
                                    </v-card>
                                    <v-card  tile flat class="d-none d-sm-block" dark color="#bc8d52">
                                        <v-col >
                                            <v-row >
                                                <v-col cols="12" md="6">
                                                    <h1 class="headline mx-3">Programa o Proyecto de Inversión </h1>
                                                    <div id="chart">
                                                        <apexchart type="pie" :options="OpcionesSectores" :series="PorcentajeSectores"></apexchart>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <h1 class="headline mx-3">Obra o Servicio </h1>
                                                    <div id="chart">
                                                        <apexchart type="pie" :options="OpcionesObra" :series="PorcentajeObra"></apexchart>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" md="6" >
                                                    <h1 class="headline mx-3">Estatus de obras</h1>
                                                    <div id="chart">
                                                        <apexchart type="pie" ref="realtimeChart" :options="OpcionesEstatus" :series="PorcentajeEstatus"></apexchart>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <h1 class="headline mx-3">Procesos de contratación</h1>
                                                    <div id="chart">
                                                        <apexchart type="pie" ref="realtimeChart" :options="OpcionesProcesos" :series="PorcentajeProcesos"></apexchart>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item >
                                    <v-card  tile flat class="d-block d-sm-none" dark color="#bc8d52">
                                        <v-carousel class="ma-0">
                                            <v-carousel-item >
                                                <h1 class="headline mx-3">Programa o Proyecto de Inversión</h1>
                                                <div id="chart">
                                                    <apexchart type="pie" :options="OpcionesSectoresMonto" :series="PorcentajeSectoresMonto"></apexchart>
                                                </div>
                                            </v-carousel-item>
                                            <v-carousel-item >
                                                <h1 class="headline mx-3">Obra o servicio</h1>
                                                <div id="chart">
                                                    <apexchart type="pie" :options="OpcionesObraMonto" :series="PorcentajeObraMonto"></apexchart>
                                                </div>
                                            </v-carousel-item>
                                        </v-carousel>
                                    </v-card>

                                    <v-card  tile flat class="d-none d-sm-block" dark color="#bc8d52">
                                        <v-col class="ma-0">
                                            <v-row class="ma-0">
                                                <v-col cols="12" md="6">
                                                    <h1 class="headline mx-3">Programa o Proyecto de Inversión</h1>
                                                    <div id="chart">
                                                        <apexchart type="pie" :options="OpcionesSectoresMonto" :series="PorcentajeSectoresMonto"></apexchart>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <h1 class="headline mx-3">Obra o servicio</h1>
                                                    <div id="chart">
                                                        <apexchart type="pie" :options="OpcionesObraMonto" :series="PorcentajeObraMonto"></apexchart>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </section>
    <br>
    <br>
    <section>
        <v-subheader class="display-1">Localiza tu obra</v-subheader>
        <v-divider></v-divider>
        <br>
    
        <gmap-map :center="center" :zoom="6" style="width: 100%; height: 450px;">

            <gmap-marker v-for="Indice in InformacionContratoSerializado" :key="Indice.contratoId" :position="Indice.marcadores" :icon="Indice.icono" @click="toggleInfoWindow(Indice, Indice.contratoId)"></gmap-marker>
            <gmap-polyline v-bind:path.sync="path" v-bind:options="{ strokeColor:'#008000'}">
            </gmap-polyline>
            <gmap-polygon :paths="paths"></gmap-polygon>
            <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                <div v-html="infoContent"></div>
            </gmap-info-window>
        </gmap-map>
    </section>
    <v-dialog v-model="dialogInicio" max-width="900px">
        <v-card class="d-none d-md-block" >
            <v-card-title class="headline grey lighten-2">
                <h4> Avisos | Secretaría de Movilidad y Planeación Urbana</h4>
            </v-card-title>
            <v-card-text>
                <br>
                <p class="display-1">
                    Nuevo León presentó la <strong>iniciativa Infraestructura Abierta</strong>, un ejercicio de manera proactiva mediante el cual estaremos publicando datos y documentos de las obras realizadas.
                </p>
                <p class="display-1">
                    La información puede ser consultada en el:
                </p>
                <v-btn href="https://smpu.nl.gob.mx/transparencia/" target="_blank" class="primary">PORTAL DE INFRAESTRUCTURA ABIERTA</v-btn>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="primary" text @click="dialogInicio = false">
                    Salir
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card class="d-block d-md-none">
            <v-card-title class="subtitle-2 grey lighten-2">
                <h4> Avisos | Secretaría de Infraestructura</h4>
            </v-card-title>
            <v-card-text>
                <br>
                <p class="body-1">
                    Nuevo León presentó la <strong>iniciativa Infraestructura Abierta</strong>, un ejercicio de manera proactiva mediante el cual estaremos publicando datos y documentos de las obras realizadas.
                </p>
                <p class="body-1">
                    La información puede ser consultada en el:
                </p>
                <v-btn href="https://smpu.nl.gob.mx/transparencia/" target="_blank" small class="primary">PORTAL DE INFRAESTRUCTURA ABIERTA</v-btn>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="primary" text @click="dialogInicio = false">
                    salir
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog1" max-width="1100">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    <h4> Nosotros | Secretaría de Movilidad y Planeación Urbana</h4>
                </v-card-title>
                <v-card-text>
                    <div class="modal-body mb-0 text-center">
                        <div class="row">
                            <img src="https://smpu.nl.gob.mx/acceso/assets/websi/SMPULogo.svg" />
                        </div>
                        <div class="row">
                            <!-- <div class="col-lg-6 col-sm-12">
                                <h3 class="titulos">Visión</h3>
                                <br />
                                <p>
                                    Trascender como la primera dependencia de un gobierno independiente reconocida a nivel nacional por su
                                    modelo competitivo y de transparencia total, garantizando el uso óptimo de los recursos públicos.
                                </p>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <h3 class="titulos">Misión</h3>
                                <br />
                                <p>
                                    Desarrollar obra pública de alta calidad, incluyente y de beneficio social para los habitantes de Nuevo León,
                                    con eficiencia, innovación, honestidad y transparencia, atrayendo inversiones y consolidando a nuestro estado como el mejor lugar para vivir.
                                </p>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <h3 class="titulos">Responsabilidades</h3>
                                <br />
                                <p>Dependencia encargada de la proyección y construcción de las obras públicas que conciernen a la Administración Pública del Estado.</p>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <h3 class="titulos">Prioridades</h3>
                                <br />
                                <p>
                                    Satisfacer las necesidades de los habitantes de Nuevo León en la creación, conservación y mejoramiento de la Infraestructura del estado,
                                    con calidad, seguridad y transparencia, en un marco de sustentabilidad y respeto al medio ambiente.
                                </p>
                            </div> -->
                            <div class="col-12">
                                <div><img src="https://www.nl.gob.mx/sites/default/files/styles/epsa_profile/public/hernaun_villarreal_rodriuguez.jpg" style="border-radius:10%;" /></div>
                                <h3 class="headline">Hernán Villarreal Rodríguez</h3>
                                <h4 style="font-family:Gotham; font-size:1.5em; font-weight:600;">Secretario de Movilidad y Planeación Urbana</h4>
                                <br />
                                <h3 class="titulos">Responsabilidades de esta dependencia</h3>
                                <br />
                                <p>
                                    Formular, conducir, planear y evaluar la política de movilidad mediante la responsabilidad de conservar y asegurar que las necesidades de tráfico protejan el bienestar y seguridad social de los ciudadanos; el desarrollo urbano estatal implementará una mejor estrategia de planeación en la distribución de la urbanización; y de la proyección y construcción de las obras públicas teniendo como objetivo el beneficio social de la población y el crecimiento económico del Estado, que le conciernen a las dependencias de la Administración Pública del Estado.
                                </p>
                                <!-- <h3 class="titulos">Organigrama</h3>

                                <v-img v-bind="attrs" v-on="on" max-width="1000" src="https://www.nl.gob.mx/sites/default/files/10.infraestructura.jpg">
                                </v-img>

                                <div style="text-align:left" class="container">
                                    <v-btn color="blue" small height="50" width="280" class="ma-1 " href="https://www.nl.gob.mx/funcionarios/silvia-vargas-villarreal" target="_blank">
                                        Secretaría Particular
                                    </v-btn> <br>
                                    <v-btn color="blue" small height="50" width="280" class="ma-1 " href="https://www.nl.gob.mx/funcionarios/rafael-salgado-garcia" target="_blank">
                                        Secretaría Técnica
                                    </v-btn><br>
                                    <v-btn color="blue" small height="50" width="280"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/lorenzo-javier-zamarron-gutierrez" target="_blank">
                                        Dirección de Transparencia <br> y Normatividad
                                    </v-btn><br>
                                    <v-btn color="blue" small height="50" width="280"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/jorge-alberto-tovar-nicoli" target="_blank">
                                        Unidad de Inversiones 
                                    </v-btn><br>
                                    <v-btn color="blue" small height="50" width="280"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/rosa-amelia-lozano-zambrano" target="_blank">
                                        Subsecretaría de Construcción
                                    </v-btn><br>
                                    <v-row>
                                        <v-col>
                                            <v-btn color="blue" outlined small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/sandra-guadalupe-patino-macias" target="_blank">
                                                Dirección de Proyectos
                                            </v-btn>
                                        
                                            <v-btn color="blue" outlined  small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/leonel-botello-de-leon" target="_blank">
                                                Dirección de Edificación
                                            </v-btn>
                                    
                                            <v-btn color="blue" outlined  small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/jose-eleuterio-garcia-velazquez" target="_blank">
                                                Dirección de Obras Especiales
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-btn color="blue" small height="50" width="280"   class="ma-1 " href="https://www.nl.gob.mx/funcionarios/godofredo-gardner-anaya" target="_blank">
                                        Subsecretaría de Planeación
                                    </v-btn>
                                    <v-row>
                                        <v-col>
                                            <v-btn color="blue" outlined  small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/alejandro-sanchez-gomez" target="_blank">
                                                Dirección de Licitaciones
                                            </v-btn>
                                        
                                            <v-btn color="blue" outlined small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/noemi-gutierrez-moreno" target="_blank">
                                                Unidad de Enlace Administrativo
                                            </v-btn>
                                        
                                            <v-btn color="blue" outlined small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/alfonso-salazar-lopez" target="_blank">
                                                Dirección de Planeación, <br> Programación y Presupuesto
                                            </v-btn>
                                        
                                            <v-btn color="blue" outlined small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/maria-eugenia-gonzalez-monreal" target="_blank">
                                                Dirección de Bienestar Ciudadano
                                            </v-btn>
                                            <v-btn color="blue" outlined small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/jesus-antonio-galindo-gutierrez" target="_blank">
                                                Dirección de Innovación y <br> Mejora Continua de Procesos
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div> -->
                            </div>
                        </div>

                        <br />

                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog1 = false">
                        salir
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" width="900">
        <v-card>
            <v-card-title class="headline grey lighten-2">
                <h4> Recibe Notificaciones | Secretaría de Movilidad y Planeación Urbana</h4>
            </v-card-title>
            <v-card-text>
                <div class="modal-body mb-0 text-center">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12">
                            <v-text-field name="name" label="correo electronico" id="id"></v-text-field>
                            <v-text-field name="name" label="Nombre" id="id"></v-text-field>
                        </div>
                    </div>
                    <br />
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog2 = false">
                    Registrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import axios from 'axios'
export default {

    props: {
    },

    data: () => ({
        total:0,
        contratistasAdjudicados:0,
        municipios:0,
        indicadores: [],
        listadoContratistas:[],
        listadoMunicipios:[],
        seleccion:[],
        listadoNoticias: [],
        dialogInicio: false,
        dialog1: false,
        dialog2: false,
        dialog3: false,
        TotalContratos:0,
        currentMidx: null,
        InformacionContrato: [],
        InformacionContratoSerializado: [],
        type: 'month',
        typeToLabel: {
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
            '4day': '4 Días',
        },

        center: {
            lat: 24,
            lng: -99.3
        },
        path: [],
        paths: [],
        infoContent: '',
        infoWindowPos: {
            lat: 0,
            lng: 0
        },
        infoWinOpen: false,
        infoOptions: {
            pixelOffset: {
                width: 0,
                height: -35
            }
        },
        PorcentajeSectores: [],
        OpcionesSectores: {
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '12px',
                height: '100px',
                
            },
            chart: {
                type: 'pie',
            },
            labels: [
                'Adquisiciones',
                'Mantenimiento',
                'Estudios de pre inversión',
                'Infraestructura Económica',
                'Infraestructura Social',
                'Infraestructura Gubernamental',
                'De inmuebles',
                'Otros',
            ],
            colors:[
                '#546E7A',
                '#8E24AA',
                '#FDD835',
                '#00ACC1',
                '#00897B',
                '#43A047',
                '#C0CA33',
                '#FB8C00',
            ],
            noData: {
                text: 'Loading...'
            },
            responsive: [{
                breakpoint: 600,
                options: {
                    chart: {
                        width: 340
                    },
                    legend: {
                        show:false,
                    }
                }
            }]
        },
        PorcentajeSectoresMonto: [],
        OpcionesSectoresMonto: {
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '12px',
                height: '100px',

            },
            chart: {
                type: 'pie',
            },
            labels: [
                'Adquisiciones',
                'Mantenimiento',
                'Estudios de pre inversión',
                'Infraestructura Económica',
                'Infraestructura Social',
                'Infraestructura Gubernamental',
                'De inmuebles',
                'Otros',
            ],
            colors:[
                '#546E7A',
                '#8E24AA',
                '#FDD835',
                '#00ACC1',
                '#00897B',
                '#43A047',
                '#C0CA33',
                '#FB8C00',
            ],
            noData: {
                text: 'Loading...'
            },
            responsive: [{
                breakpoint: 600,
                options: {
                    chart: {
                        width: 340
                    },
                    legend: {
                        show:false,
                    }
                }
            }]
        },
        PorcentajeObra: [],
        OpcionesObra: {
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '14px',
                height: '100px',
            },
            chart: {
                type: 'pie',
            },
            noData: {
                text: 'Loading...'
            },
            labels: ['Construcción', 
            'Rehabilitación', 
            'Otros'],
            colors:[
                '#546E7A',
                '#8E24AA',
                '#FDD835',
                '#00ACC1',
                '#00897B',
                '#43A047',
                '#C0CA33',
                '#FB8C00',
            ],
            responsive: [{
                breakpoint: 600,
                options: {
                    chart: {
                        width: 340
                    },
                    legend: {
                        show:false,
                    }
                }
            }]
        },PorcentajeObraMonto: [],
        OpcionesObraMonto: {
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '14px',
                height: '100px',
            },
            chart: {
                type: 'pie',
            },
            labels: [
                'Construcción', 
                'Rehabilitación', 
                'Otros'
            ],
            colors:[
                '#546E7A',
                '#8E24AA',
                '#FDD835',
                '#00ACC1',
                '#00897B',
                '#43A047',
                '#C0CA33',
                '#FB8C00',
            ],
            noData: {
                text: 'Loading...'
            },
            responsive: [{
                breakpoint: 600,
                options: {
                    chart: {
                        width: 340
                    },
                    legend: {
                        show:false,
                    }
                }
            }]
        },
        PorcentajeEstatus: [],
        OpcionesEstatus: {
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '16px',
                height: '100px',
            },
            chart: {
                type: 'pie',
            },
            labels: ['En Ejecución', 'Concluido'],
            colors:[
                '#546E7A',
                '#8E24AA',
                '#FDD835',
                '#00ACC1',
                '#00897B',
                '#43A047',
                '#C0CA33',
                '#FB8C00',
            ],
            noData: {
                text: 'Loading...'
            },
            responsive: [{
                breakpoint: 600,
                options: {
                    chart: {
                        width: 340
                    },
                    legend: {
                        show:false,
                    }
                }
            }]
        },
        PorcentajeProcesos: [],
        OpcionesProcesos: {
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                fontSize: '16px',
                height: '100px',
            },
            chart: {
                type: 'pie',
            },
            labels: [ 'Licitación','Adjudicación directa','Invitación restringida'],
            colors:[
                '#546E7A',
                '#8E24AA',
                '#FDD835',
                '#00ACC1',
                '#00897B',
                '#43A047',
                '#C0CA33',
                '#FB8C00',
            ],
            noData: {
                text: 'Loading...'
            },
            responsive: [{
                breakpoint: 600,
                options: {
                    chart: {
                        width: 340
                    },
                    legend: {
                        show:false,
                    }
                }
            }]
        },
        Titulos: [{
                titulo: "Bienvenido",
                subtitulo: "al portal de Infraestructura",
            },
            {
                titulo: "Nosotros",
                subtitulo: "Conoce  más  sobre  quiénes  somos",
                btn: "Ver más",
                modal: "1"
            },
            {
                titulo: "Concursos",
                subtitulo: "Infórmate  sobre  nuevas  obras  y participa",
                btn: "Ver más",
                vinculo: "/concursos",
            },
            {
                titulo: "Histórico  de  Contratos ",
                subtitulo: "Observa  las  obras  que  hemos  realizado",
                btn: "Ver más",
                vinculo: "/historicocontratospublicos",
            },
            {
                titulo: "Portal de proveedores ",
                subtitulo: "Suscríbete  y  entérate  de  las  obras  y  licitaciones  del  Estado",
                btn: "Ver más",
                link: "https://proveedores.nl.gob.mx/",
            },
            {
                titulo: "Regístrate  aquí  y  recibe  notificaciones",
                btn: "Ver más",
                modal: "2"
            },
            {
                titulo: "Denuncia  Corrupción",
                btn: "Denunciar",
                link: "http://corrupnetnl.mx",
            },
        ],
        headers: [{
                text: "Numero de Concurso",
                value: "concursoId"
            },
            {
                text: "Nombre de Concurso",
                value: "tituloConcurso"
            },
            {
                text: "Evento",
                value: "nombre"
            },
            {
                text: "Fecha",
                value: "fecha"
            },
        ],
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        listadoEventos: [],
        overlay: false,
        valid: true,
        name: '',
        select: null,
    }),
    computed(){
        
    },
    created() {
        this.listarNoticias();
        this.listarEventos();
        this.listarUbicaciones();
        this.Indicadores();
        this.IndicadorContratistas();
        this.IndicadorMunicipios();
        this.VentanaInicio();
        
    },
    mounted() {
    },
    methods: {
        
        sumas(){
            let me = this;
            me.CalculaInversion();
            me.CalculaEstatus();
            me.CalculaSectores();
            me.CalculaTipos();
            me.CalculaTipoConcurso();
            me.CalculaContratistas();
            me.CalculaMunicipios();

        },
        CalculaContratistas(){
            let me = this;
            let anios = [];
            let contratistasFiltrados = [];
            let contratistasUnicos = [];
            if(me.seleccion.length > 0 ){
                me.seleccion.forEach(x => {
                    anios.push(x.anio);
                });
            }
            else {
                me.indicadores.forEach(x => {
                    anios.push(x.anio );
                });
            }     
            console.log(anios , 'anios');
            me.listadoContratistas.forEach(contrato => {
               if (anios.includes(contrato.anio)){
                   contratistasFiltrados.push(contrato)
               }
            });
            console.log(contratistasFiltrados , 'listado filtrado de contratistas')
            contratistasUnicos = [...new Set(contratistasFiltrados.map(item => item.contratistaId))]; // [ 'A', 'B']
            console.log(contratistasUnicos , 'listado filtrado de contratistas sin repetir')
            me.contratistasAdjudicados = contratistasUnicos.length;
        },
        CalculaMunicipios(){
            let me = this;
            let anios = [];
            let MunicipiosFiltrados = [];
            let MunicipiosUnicos = [];
            if(me.seleccion.length > 0 ){
                me.seleccion.forEach(x => {
                    anios.push(x.anio);
                });
            }
            else {
                me.indicadores.forEach(x => {
                    anios.push(x.anio );
                });
            }     
            console.log(anios , 'anios');
            me.listadoMunicipios.forEach(contrato => {
               if (anios.includes(contrato.anio)){
                   MunicipiosFiltrados.push(contrato)
               }
            });
            console.log(MunicipiosFiltrados , 'listado filtrado de Municipios')
            MunicipiosUnicos = [...new Set(MunicipiosFiltrados.map(item => item.municipioId))]; // [ 'A', 'B']
            console.log(MunicipiosUnicos , 'listado filtrado de Municipios sin repetir')
            me.municipios= MunicipiosUnicos.length;
        },
        CalculaTipoConcurso(){
            let me = this;
            let Licitaciones = [];
            let AdjudicacionDirecta = [];
            let InvitacionRestringida = [];
            
            while(me.PorcentajeProcesos.length > 0) {
                me.PorcentajeProcesos.pop();
            }
            if(me.seleccion.length > 0 ){
                me.seleccion.forEach(x => {
                    if (!(x.licitaciones == null)){
                        Licitaciones.push(x.licitaciones)
                    }
                    else{
                        Licitaciones.push(0)
                    }
                    
                    if (!(x.adjudicacionDirecta == null)){
                        AdjudicacionDirecta.push(x.adjudicacionDirecta)
                    }
                    else{
                        AdjudicacionDirecta.push(0)
                    }

                    if (!(x.invitacionRestringida == null)){
                        InvitacionRestringida.push(x.invitacionRestringida)
                    }
                    else{
                        InvitacionRestringida.push(0)
                    }
                    });

            }
            else {
                me.indicadores.forEach(x => {
                    if (!(x.licitaciones == null)){
                        Licitaciones.push(x.licitaciones)
                    }
                    else{
                        Licitaciones.push(0)
                    }
                    
                    if (!(x.adjudicacionDirecta == null)){
                        AdjudicacionDirecta.push(x.adjudicacionDirecta)
                    }
                    else{
                        AdjudicacionDirecta.push(0)
                    }

                    if (!(x.invitacionRestringida == null)){
                        InvitacionRestringida.push(x.invitacionRestringida)
                    }
                    else{
                        InvitacionRestringida.push(0)
                    }
                    });

            }                
            
            me.PorcentajeProcesos.push(Licitaciones.reduce((a, b) => a + b, 0));
            me.PorcentajeProcesos.push(AdjudicacionDirecta.reduce((a, b) => a + b, 0));
            me.PorcentajeProcesos.push(InvitacionRestringida.reduce((a, b) => a + b, 0));
        },
        CalculaInversion(){
            let me = this;
            let cantidades = [];
            if(me.seleccion.length > 0 ){
                me.seleccion.forEach(x=> {
                    if (!(x.montoTotal== null))
                    cantidades.push(x.montoTotal)
                    });
                    me.total = cantidades.reduce((a, b) => a + b, 0);
            }
            else if(me.seleccion.length <= 0){
                me.indicadores.forEach(x=> {
                    if (!(x.montoTotal== null))
                    cantidades.push(x.montoTotal)
                    });
                    me.total = cantidades.reduce((a, b) => a + b, 0);
            }
        },
        CalculaTipos(){
            let me = this;
            let Construccion = [];
            let Rehabilitacion = [];
            let TipoOtros = [];
            let MontoConstruccion = [];
            let MontoRehabilitacion = [];
            let TipoMontoOtros = [];
            
            while(me.PorcentajeObra.length > 0) {
                me.PorcentajeObra.pop();
            }
            while(me.PorcentajeObraMonto.length > 0) {
                me.PorcentajeObraMonto.pop();
            }
            if(me.seleccion.length > 0 ){
                me.seleccion.forEach(x => {
                    if (!(x.construccion == null)){
                        Construccion.push(x.construccion)
                    }
                    else{
                        Construccion.push(0)
                    }
                    
                    if (!(x.rehabilitacion == null)){
                        Rehabilitacion.push(x.rehabilitacion)
                    }
                    else{
                        Rehabilitacion.push(0)
                    }
                    if (!(x.tipoOtros == null)){
                        TipoOtros.push(x.tipoOtros)
                    }
                    else{
                        TipoOtros.push(0)
                    }
                    if (!(x.montoConstruccion == null)){
                        MontoConstruccion.push(x.montoConstruccion)
                    }
                    else{
                        MontoConstruccion.push(0)
                    }
                    
                    if (!(x.montoRehabilitacion == null)){
                        MontoRehabilitacion.push(x.montoRehabilitacion)
                    }
                    else{
                        MontoRehabilitacion.push(0)
                    }
                    if (!(x.tipoMontoOtros == null)){
                        TipoMontoOtros.push(x.tipoMontoOtros)
                    }
                    else{
                        TipoMontoOtros.push(0)
                    }

                    });

            }
            else {
                me.indicadores.forEach(x => {
                    if (!(x.construccion == null)){
                        Construccion.push(x.construccion)
                    }
                    else{
                        Construccion.push(0)
                    }
                    
                    if (!(x.rehabilitacion == null)){
                        Rehabilitacion.push(x.rehabilitacion)
                    }
                    else{
                        Rehabilitacion.push(0)
                    }
                    if (!(x.tipoOtros == null)){
                        TipoOtros.push(x.tipoOtros)
                    }
                    else{
                        TipoOtros.push(0)
                    }
                    if (!(x.montoConstruccion == null)){
                        MontoConstruccion.push(x.montoConstruccion)
                    }
                    else{
                        MontoConstruccion.push(0)
                    }
                    
                    if (!(x.montoRehabilitacion == null)){
                        MontoRehabilitacion.push(x.montoRehabilitacion)
                    }
                    else{
                        MontoRehabilitacion.push(0)
                    }
                    if (!(x.tipoMontoOtros == null)){
                        TipoMontoOtros.push(x.tipoMontoOtros)
                    }
                    else{
                        TipoMontoOtros.push(0)
                    }
                    
                    });

            }                
            
            me.PorcentajeObra.push(Construccion.reduce((a, b) => a + b, 0));
            me.PorcentajeObra.push(Rehabilitacion.reduce((a, b) => a + b, 0));
            me.PorcentajeObra.push(TipoOtros.reduce((a, b) => a + b, 0));
            me.PorcentajeObraMonto.push(MontoConstruccion.reduce((a, b) => a + b, 0));
            me.PorcentajeObraMonto.push(MontoRehabilitacion.reduce((a, b) => a + b, 0));
            me.PorcentajeObraMonto.push(TipoMontoOtros.reduce((a, b) => a + b, 0));
        },
        CalculaEstatus(){
            let me = this;
            let Terminado = [];
            let EnProceso = [];
            
            while(me.PorcentajeEstatus.length > 0) {
                me.PorcentajeEstatus.pop();
            }
            if(me.seleccion.length > 0 ){
                me.seleccion.forEach(x => {
                    if (!(x.terminado == null)){
                        Terminado.push(x.terminado)
                    }
                    else{
                        Terminado.push(0)
                    }
                    
                    if (!(x.enProceso == null)){
                        EnProceso.push(x.enProceso)
                    }
                    else{
                        EnProceso.push(0)
                    }
                    });

            }
            else {
                me.indicadores.forEach(x => {
                    if (!(x.terminado == null)){
                        Terminado.push(x.terminado)
                    }
                    else{
                        Terminado.push(0)
                    }
                    
                    if (!(x.enProceso == null)){
                        EnProceso.push(x.enProceso)
                    }
                    else{
                        EnProceso.push(0)
                    }
                    
                    });

            }                
            
            me.PorcentajeEstatus.push(EnProceso.reduce((a, b) => a + b, 0));
            me.PorcentajeEstatus.push(Terminado.reduce((a, b) => a + b, 0));
            me.TotalContratos = (me.PorcentajeEstatus.reduce((a, b) => a + b, 0));
            console.log(me.TotalContratos,'total contratos');
        },
        CalculaSectores(){
            let me = this;
            let Adquisiciones = [];
            let MontoAdquisiciones = [];
            let Mantenimiento = [];
            let MontoMantenimiento = [];
            let EstudiosPreInversion = [];
            let MontoEstudiosPreInversion = [];
            let InfraestructuraEconomica = [];
            let MontoInfraestructuraEconomica = [];
            let InfraestructuraSocial = [];
            let MontoInfraestructuraSocial = [];
            let InfraestructuraGubernamental = [];
            let MontoInfraestructuraGubernamental = [];
            let Deinmuebles = [];
            let MontoDeinmuebles = [];
            let Otros = [];
            let MontoOtros = [];
            while(me.PorcentajeSectores.length > 0) {
                me.PorcentajeSectores.pop();
            }
            while(me.PorcentajeSectoresMonto.length > 0) {
                me.PorcentajeSectoresMonto.pop();
            }
            if(me.seleccion.length > 0 ){
                me.seleccion.forEach(x=> {
                    if (!(x.adquisiciones == null)){
                        Adquisiciones.push(x.adquisiciones)
                    }
                    else{
                        Adquisiciones.push(0)
                    }
                    if (!(x.mantenimiento == null)){
                        Mantenimiento.push(x.mantenimiento)
                    }
                    else{
                        Mantenimiento.push(0)
                    }
                    if (!(x.estudiosPreInversion == null)){
                        EstudiosPreInversion.push(x.estudiosPreInversion)
                    }
                    else{
                        EstudiosPreInversion.push(0)
                    }
                    if (!(x.infraestructuraEconomica == null)){
                        InfraestructuraEconomica.push(x.infraestructuraEconomica)
                    }
                    else{
                        InfraestructuraEconomica.push(0)
                    }
                    if (!(x.infraestructuraSocial == null)){
                        InfraestructuraSocial.push(x.infraestructuraSocial)
                    }
                    else{
                        InfraestructuraSocial.push(0)
                    }
                    if (!(x.infraestructuraGubernamental == null)){
                        InfraestructuraGubernamental.push(x.infraestructuraGubernamental)
                    }
                    else{
                        InfraestructuraGubernamental.push(0)
                    }
                    if (!(x.deinmuebles == null)){
                        Deinmuebles.push(x.deinmuebles)
                    }
                    else{
                        Deinmuebles.push(0)
                    }
                    if (!(x.sectoresOtros == null)){
                        Otros.push(x.sectoresOtros)
                    }
                    else{
                        Otros.push(0)
                    }

                    if (!(x.montoAdquisiciones == null)){
                        MontoAdquisiciones.push(x.montoAdquisiciones)
                    }
                    else{
                        MontoAdquisiciones.push(0)
                    }
                    if (!(x.montoMantenimiento == null)){
                        MontoMantenimiento.push(x.montoMantenimiento)
                    }
                    else{
                        MontoMantenimiento.push(0)
                    }
                    if (!(x.montoEstudiosPreInversion == null)){
                        MontoEstudiosPreInversion.push(x.montoEstudiosPreInversion)
                    }
                    else{
                        MontoEstudiosPreInversion.push(0)
                    }
                    if (!(x.montoInfraestructuraEconomica == null)){
                        MontoInfraestructuraEconomica.push(x.montoInfraestructuraEconomica)
                    }
                    else{
                        MontoInfraestructuraEconomica.push(0)
                    }
                    if (!(x.montoInfraestructuraSocial == null)){
                        MontoInfraestructuraSocial.push(x.montoInfraestructuraSocial)
                    }
                    else{
                        MontoInfraestructuraSocial.push(0)
                    }
                    if (!(x.montoInfraestructuraGubernamental == null)){
                        MontoInfraestructuraGubernamental.push(x.montoInfraestructuraGubernamental)
                    }
                    else{
                        MontoInfraestructuraGubernamental.push(0)
                    }
                    if (!(x.montoDeinmuebles == null)){
                        MontoDeinmuebles.push(x.montoDeinmuebles)
                    }
                    else{
                        MontoDeinmuebles.push(0)
                    }
                    if (!(x.sectoresMontoOtros == null)){
                        MontoOtros.push(x.sectoresMontoOtros)
                    }
                    else{
                        MontoOtros.push(0)
                    }
                    });

            }
            else {
                me.indicadores.forEach(x=> {
                    if (!(x.adquisiciones == null)){
                        Adquisiciones.push(x.adquisiciones)
                    }
                    else{
                        Adquisiciones.push(0)
                    }
                    
                    if (!(x.mantenimiento == null)){
                        Mantenimiento.push(x.mantenimiento)
                    }
                    else{
                        Mantenimiento.push(0)
                    }
                    if (!(x.estudiosPreInversion == null)){
                        EstudiosPreInversion.push(x.estudiosPreInversion)
                    }
                    else{
                        EstudiosPreInversion.push(0)
                    }
                    if (!(x.infraestructuraEconomica == null)){
                        InfraestructuraEconomica.push(x.infraestructuraEconomica)
                    }
                    else{
                        InfraestructuraEconomica.push(0)
                    }
                    if (!(x.infraestructuraSocial == null)){
                        InfraestructuraSocial.push(x.infraestructuraSocial)
                    }
                    else{
                        InfraestructuraSocial.push(0)
                    }
                    if (!(x.infraestructuraGubernamental == null)){
                        InfraestructuraGubernamental.push(x.infraestructuraGubernamental)
                    }
                    else{
                        InfraestructuraGubernamental.push(0)
                    }
                    if (!(x.deinmuebles == null)){
                        Deinmuebles.push(x.deinmuebles)
                    }
                    else{
                        Deinmuebles.push(0)
                    }
                    if (!(x.sectoresOtros == null)){
                        Otros.push(x.sectoresOtros)
                    }
                    else{
                        Otros.push(0)
                    }
                    if (!(x.montoAdquisiciones == null)){
                        MontoAdquisiciones.push(x.montoAdquisiciones)
                    }
                    else{
                        MontoAdquisiciones.push(0)
                    }
                    if (!(x.montoMantenimiento == null)){
                        MontoMantenimiento.push(x.montoMantenimiento)
                    }
                    else{
                        MontoMantenimiento.push(0)
                    }
                    if (!(x.montoEstudiosPreInversion == null)){
                        MontoEstudiosPreInversion.push(x.montoEstudiosPreInversion)
                    }
                    else{
                        MontoEstudiosPreInversion.push(0)
                    }
                    if (!(x.montoInfraestructuraEconomica == null)){
                        MontoInfraestructuraEconomica.push(x.montoInfraestructuraEconomica)
                    }
                    else{
                        MontoInfraestructuraEconomica.push(0)
                    }
                    if (!(x.montoInfraestructuraSocial == null)){
                        MontoInfraestructuraSocial.push(x.montoInfraestructuraSocial)
                    }
                    else{
                        MontoInfraestructuraSocial.push(0)
                    }
                    if (!(x.montoInfraestructuraGubernamental == null)){
                        MontoInfraestructuraGubernamental.push(x.montoInfraestructuraGubernamental)
                    }
                    else{
                        MontoInfraestructuraGubernamental.push(0)
                    }
                    if (!(x.montoDeinmuebles == null)){
                        MontoDeinmuebles.push(x.montoDeinmuebles)
                    }
                    else{
                        MontoDeinmuebles.push(0)
                    }
                    if (!(x.sectoresMontoOtros == null)){
                        MontoOtros.push(x.sectoresMontoOtros)
                    }
                    else{
                        MontoOtros.push(0)
                    }
                    });

            }                
            
            me.PorcentajeSectores.push(Adquisiciones.reduce((a, b) => a + b, 0));
            me.PorcentajeSectores.push(Mantenimiento.reduce((a, b) => a + b, 0));
            me.PorcentajeSectores.push(EstudiosPreInversion.reduce((a, b) => a + b, 0));
            me.PorcentajeSectores.push(InfraestructuraEconomica.reduce((a, b) => a + b, 0));
            me.PorcentajeSectores.push(InfraestructuraSocial.reduce((a, b) => a + b, 0));
            me.PorcentajeSectores.push(InfraestructuraGubernamental.reduce((a, b) => a + b, 0));
            me.PorcentajeSectores.push(Deinmuebles.reduce((a, b) => a + b, 0));
            me.PorcentajeSectores.push(Otros.reduce((a, b) => a + b, 0));
            me.PorcentajeSectoresMonto.push(MontoAdquisiciones.reduce((a, b) => a + b, 0));
            me.PorcentajeSectoresMonto.push(MontoMantenimiento.reduce((a, b) => a + b, 0));
            me.PorcentajeSectoresMonto.push(MontoEstudiosPreInversion.reduce((a, b) => a + b, 0));
            me.PorcentajeSectoresMonto.push(MontoInfraestructuraEconomica.reduce((a, b) => a + b, 0));
            me.PorcentajeSectoresMonto.push(MontoInfraestructuraSocial.reduce((a, b) => a + b, 0));
            me.PorcentajeSectoresMonto.push(MontoInfraestructuraGubernamental.reduce((a, b) => a + b, 0));
            me.PorcentajeSectoresMonto.push(MontoDeinmuebles.reduce((a, b) => a + b, 0));
            me.PorcentajeSectoresMonto.push(MontoOtros.reduce((a, b) => a + b, 0));

        },
        VentanaInicio(){
            if(!localStorage.Inicio){
                this.dialogInicio = true;
                localStorage.Inicio = true;
            }
        },
        mostrarOverlay() {

            console.log(this.$overlay);

            this.$overlay = !this.$app.overlay;
            setTimeout(() => {
                this.$app.overlay = !this.$app.overlay;
            }, 5000);

        },
        toggleInfoWindow: function (marker, idx) {
            this.infoWindowPos = marker.marcadores;
            this.infoContent = this.getInfoWindowContent(marker);
            console.log(marker.tituloConcurso);
            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        getInfoWindowContent: function (marker) {
            console.log("infowindow");
            console.log(marker);
            if (!(marker.urlPia == null)) {
                return (
                    `<div class="">
          <div>
            <div>
              <div class="m-2"><span style="font-weight: bold;">Titulo: </span>
                ${marker.tituloConcurso}
              </div>
            </div>

            <div class="m-2"><span style="font-weight: bold;">Estatus:  </span>
              ${marker.estatusContrato}
              <br>
            </div>
            <div class="m-2"><span style="font-weight: bold;">Numero de concurso:  </span>
              ${marker.numeroConcurso}
              <br>
            </div>
            <div class="m-2"><span style="font-weight: bold;">Numero de contrato:  </span>
              ${marker.numeroContrato}
              <br>
            </div>
            <a

            href="${marker.urlPia}" target="_blank"
            >Mas info</a>
          </div>
        </div>`);

            } else {
                return (
                    `<div class="">
          <div>
            <div>
              <div class="m-2"><span style="font-weight: bold;">Titulo: </span>
                ${marker.tituloConcurso}
              </div>
            </div>
            <div class="m-2"><span style="font-weight: bold;">Estatus:  </span>
              ${marker.estatusContrato}
              <br>
            </div>
            <div class="m-2"><span style="font-weight: bold;">Numero de concurso:  </span>
              ${marker.numeroConcurso}
              <br>
            </div>
            <div class="m-2"><span style="font-weight: bold;">Numero de contrato:  </span>
              ${marker.numeroContrato}
              <br>
            </div>
          </div>
        </div>`);
            }

        },
        mostrar(modal) {
            if (modal == 1) {
                this.dialog1 = true;
            } else if (modal == 2) {
                this.dialog2 = true;
            }
        },
        listarNoticias() {
            console.log("Listar");
            let me = this;
            // let header = {"Authorization":"Bearer " + this.$store.state.token};
            // let configuration={headers:header};
            axios.get('/listarNoticias').then(function (response) {
                console.log("Concursos:");
                console.log(response.data);
                me.listadoNoticias = response.data.reverse();
                return true;
            }).catch(function (error) {
                console.log(error);
                return false;
            });
        },
        Indicadores(){

            let me = this;
            axios.get('api/concursos/MostrarIndicadores').then(function (response) {
                console.log(response.data, "Indicadores");
                //aux.push(response.data);
                me.indicadores = response.data;
                me.sumas();
                console.log(me.indicadores, "me.Indicadores");
                return true;
            }).catch(function (error) {
                console.log(error);
                return false;
            });
        },
        IndicadorContratistas(){

            let me = this;
            axios.get('api/concursos/MostrarIndicadorContratistas').then(function (response) {
                console.log(response.data, "contratistas por año");
                me.listadoContratistas = response.data;
                me.sumas();
                return true;
            }).catch(function (error) {
                console.log(error);
                return false;
            });
        },
        IndicadorMunicipios(){

            let me = this;
            axios.get('api/concursos/MostrarIndicadorMunicipios').then(function (response) {
                console.log(response.data, "Municipios por año");
                me.listadoMunicipios = response.data;
                me.sumas();
                return true;
            }).catch(function (error) {
                console.log(error);
                return false;
            });
        },
        listarUbicaciones() {
            console.log("Listar");
            let me = this;
            axios.get('api/Contratos/ListarUbicaciones').then(function (response) {
                console.log("Ubicaciones:");
                console.log(response.data);
                me.InformacionContrato = response.data.reverse();
                console.log(me.InformacionContrato, "me.InformacionContrato");
                me.crearObjetosUbicacion();
                return true;
            }).catch(function (error) {
                console.log(error);
                return false;
            });
        },
        listarEventos() {
            console.log("Listar");
            let me = this;
            // let header = {"Authorization":"Bearer " + this.$store.state.token};
            // let configuration={headers:header};
            axios.get('/listarEventos').then(function (response) {
                console.log("Eventos:");
                console.log(response.data);
                me.listadoEventos = response.data.reverse();
                me.getEvents();
                return true;
            }).catch(function (error) {
                console.log(error);
                return false;
            });
        },
        viewDay({
            date
        }) {
            this.focus = date
            this.type = 'day'
        },
        getEvents() {
            var events = [];
            var me = this;
            me.listadoEventos.forEach(evento => {
                var fecha = evento.fecha.substr(0, 10);
                var hora = evento.hora.substr(10,3);
                console.log(fecha);
                console.log(hora);
                var min = (`${fecha}${hora}:00:00`);
                var max = (`${fecha}${hora}:59:59`);
                events.push({
                    name: evento.nombre,
                    details: evento.tituloConcurso,
                    numero: evento.numeroConcurso,
                    start: min,
                    end: max,
                    color: 'blue',
                    timed: true,
                })
            })

            this.events = events;
        },
        showEvent({
            nativeEvent,
            event
        }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                setTimeout(() => {
                    this.selectedOpen = true
                }, 10)
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                setTimeout(open, 10)
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        crearObjetosUbicacion() {
            var me = this;

            me.InformacionContrato.forEach(element => {
                if (element.marcadores != undefined) {
                    // console.log(element.marcadores);
                    var marcadoresInternos = JSON.parse(element.marcadores);
                    var InfoUbicacion = new Object();
                    InfoUbicacion.tituloConcurso = element.tituloConcurso;
                    InfoUbicacion.contratoId = element.contratoId;
                    InfoUbicacion.concursoId = element.concursoId;
                    InfoUbicacion.numeroConcurso = element.numeroConcurso;
                    InfoUbicacion.numeroContrato = element.numeroContrato;
                    InfoUbicacion.estatusContrato = element.estatusContrato;
                    InfoUbicacion.urlPia = element.urlPia;
                    if (InfoUbicacion.estatusContrato == 'CONTRATACIÓN' || InfoUbicacion.estatusContrato == 'EN EJECUCIÓN' ||
                        InfoUbicacion.estatusContrato == 'DETENIDO' || InfoUbicacion.estatusContrato == 'INICIO DE RESCISIÓN') {
                        InfoUbicacion.icono = {
                            url: 'https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/enProceso.svg'
                        };
                    } else if (InfoUbicacion.estatusContrato == 'EN CIERRE ADMINISTRATIVO' || InfoUbicacion.estatusContrato == 'CONCLUIDO CON FIANZA DE V.O.' ||
                        InfoUbicacion.estatusContrato == 'TERMINADA FÍSICAMENTE' || InfoUbicacion.estatusContrato == 'TERMINACIÓN ANTICIPADA') {
                        InfoUbicacion.icono = {
                            url: 'https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/terminado.svg'
                        };
                    }

                    marcadoresInternos.forEach(marcador => {
                        InfoUbicacion.marcadores = (marcador);
                    });
                    me.InformacionContratoSerializado.push(InfoUbicacion)
                }
            });

            console.log('InformacionContratoSerializado');
            console.log(me.InformacionContratoSerializado);
        },
    },
}
</script>

