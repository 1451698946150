import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router'
import {
  Scene,
  TrackballControls,
  PerspectiveCamera,
  WebGLRenderer,
  Color,
  FogExp2,
  CylinderBufferGeometry,
  MeshPhongMaterial,
  Mesh,
  DirectionalLight,
  AmbientLight,
  LineBasicMaterial,
  Geometry,
  Vector3,
  Line
} from "three-full";

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    token: null,
    usuario: null,
  },

  mutations: {
    setToken(state, token){
      console.log(token);
      state.token = token;
    },
    deleteToken(state){
      state.token = null;
    },
    setUsuario(state,usuario){
      console.log(usuario);
      state.usuario = usuario;
    },
    deleteUsuario(state){
      state.usuario = null;
    }

  },

  actions: {
    guardarToken({commit},token){
      commit("setToken",token)
      commit("setUsuario",decode(token))
      localStorage.setItem("token",token)
      localStorage.setItem("usuario",decode(token))

    },

    autoLogin({commit}){
      let token = localStorage.getItem("token")
      console.log("Autologin");
      console.log(token);
      if(token){
        commit("setToken",token)
        commit("setUsuario",decode(token))
      }
      else{
        router.push({name:'Inicio'})
      }
    },

    salir({commit}){
      try{
        console.log("Saliendo");
        commit("deleteToken")
        commit("deleteUsuario")
        localStorage.removeItem("token")
        localStorage.removeItem("usuario")
        console.log(token);
        alert("fin")
      
       // router.push({name:'Inicio'})
      }
      catch(ex){
        alert(ex);
        console.log(ex);

      }
      
    }

  },

  modules: {
  }
  
})
