<template>
 <v-layout align-start>
  <v-flex>
   <h1 >
        Información general
    </h1>
    <br>
   <v-divider :inset="inset"></v-divider>
   <v-spacer></v-spacer>
   <br>
   <v-form v-model="valid">
    <v-container fill-height fluid class="x-ctn">
      <v-row align="center" justify="center">
        <v-col >
          <b>Título de la Convocatoria </b>
              <p> {{ defaultItem.TituloConcurso }} </p>
        </v-col>   
        <v-col   >
          <b>Estatus </b>
           <p>{{ defaultItem.EstatusNombre }}</p>
        </v-col> 
      </v-row>
      <v-row align="center" justify="center">
        <v-col   >
          <b> No. Licitación </b>
              <p> {{ defaultItem.NumeroConcurso }} </p>
        </v-col>   
        <v-col   >  
          <b> No. Pre Bases </b>
          <v-col  >
           <p>{{ defaultItem.ExpedientePreBase }}</p>
          </v-col>  
        </v-col>   
        <v-col>  
          <b> Plazo de Ejecución (días) </b>
           <p>{{ defaultItem.PlazoEjecucion }}</p>
        </v-col>  
        <v-col >
          <b>Clave de cartera </b>
              <p> {{ defaultItem.ClaveCartera }} </p>
        </v-col>   
        <v-col >  
          <b>Aprobacion Presupuestal </b>
          <v-col >
           <p>{{ defaultItem.AprobacionPresupuestal }}</p>
          </v-col>  
        </v-col> 

      </v-row>
      <v-row align="center" justify="center">
        
      </v-row>
      <v-row align="center" justify="center">
        <v-col  >
          <b>Promovente</b>
              <p> {{ defaultItem.DependenciaNombre }} </p>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col>
          <b>Descripción </b>
              <p>{{ defaultItem.Descripcion }}</p>
        </v-col>  
      </v-row>
      <v-row align="center" justify="center">
        <v-col>
          <b>Dirección </b>
              <p> {{ defaultItem.Ubicacion }} </p>
        </v-col>  
      </v-row>
    </v-container>
  </v-form>
  </v-flex>
 </v-layout>
  
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
      inset: false,
      valid: false,
      defaultItem: {
        ConcursoId:'',
        TituloConcurso : '',
        EstatusId:'',
        EstatusNombre : '',
        NumeroConcurso: '',
        PlazoEjecucion : '',
        ClaveCartera : '',
        AprobacionPresupuestal : '',
        DependenciaId:'',
        DependenciaNombre: '',
        Descripcion : '',
        Ubicacion : '',
        ExpedientePreBase : ''
      },
      
      valida: 0,
      validaMensaje:[],

    }),
    props: {
        concursoid: Number,
    },
    created () {
      this.Mostrar();
    },

    methods: {

      Mostrar () {
        //Mostrar los datos del registro de Solicitud;
        let me = this;
        // me.defaultItem.concursoid = me.$route.params.concursoid;               
        debugger;
          // axios.get('api/aclaraciones/Mostrar/' + selectedItem.concursoAclaracionId).then(function(response){
          axios.get('api/concursos/MostrarDatosGenerales/' +  me.concursoid).then(function(response){
            console.log(response.data);
            me.defaultItem.ConcursoId = response.data.concursoid;
            me.defaultItem.TituloConcurso = response.data.tituloConcurso;
            me.defaultItem.EstatusId = response.data.estatusId;
            me.defaultItem.EstatusNombre = response.data.estatusNombre;
            me.defaultItem.NumeroConcurso = response.data.numeroConcurso;
            me.defaultItem.PlazoEjecucion = response.data.plazoEjecucion;
            me.defaultItem.ClaveCartera = response.data.claveCartera;
            me.defaultItem.AprobacionPresupuestal = response.data.aprobacionPresupuestal;
            me.defaultItem.DependenciaId = response.data.dependenciaId;
            me.defaultItem.DependenciaNombre = response.data.dependenciaNombre;
            me.defaultItem.Descripcion = response.data.descripcion;
            me.defaultItem.Ubicacion = response.data.ubicacion;
            me.defaultItem.ExpedientePreBase = response.data.expedientePreBase;
            
          }).catch(function(error){
            console.log(error);
          });    
                
      },


     
	
    },

    
    
  }
</script>