const configMapa = {
    clickableIcons: true,
    streetViewControl: true,
    panControlOptions: true,
    gestureHandling: "cooperative",
    mapTypeControl: true,
    zoom: 7,
    minZoom: 1,
    maxZoom: 15
  };
   
  export { configMapa};