<template>
<div>
    <section id="titulo" class="d-flex flex-column" style="background-image: linear-gradient(to right, #5232C2, #038bbb);color: white;">
        <v-container class="pb-8 pt-8">
            <h1 style="font-size:3em;">Contratistas</h1>
        </v-container>
    </section>
    <section id="titulo" class="d-flex flex-column" style="background-image: linear-gradient(to right, #5232C2, #038bbb);color: white;">
    </section>
    <section class="pa-4" id="contenedorListadoInfo">
      <v-layout align-start>
          <v-flex>
              <v-card flat outlined>
               
                  <v-card-text> 
                    <v-data-table :headers="headers" :items="listadoCompontente1" :single-select=true item-key="rfc" flat  dense @click:row="datosContratista">
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                    </v-toolbar>
                                </template>
                                <template v-slot:no-data>
                                    <v-btn color="primary" @click="listar">Actualizar</v-btn>
                                </template>
                            </v-data-table>
                   </v-card-text>
                </v-card>
          </v-flex>
      </v-layout>
     
        <!--* Empieza Modal *-->
          <v-dialog v-model="dialog" max-width="1000">
              <v-card>
                  <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                      <v-container 
                      v-for="item in listaDatosContratista" 
                      :key="item.rfc"
                      >
                      <v-row>
                        <v-col cols="12" >
                              <span class="title"> <strong>Razón social:</strong>  {{item.razon_social}}</span> 
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>RFC:</strong>  {{ item.rfc}}</span> 
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>Nombre de la empresa:</strong> {{item.nombre_comercial}}</span> 
                            </v-col>
                            <v-col cols="12">
                              <span class="headline">Dirección</span>
                              <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>Calle:</strong>  {{item.calle_fiscal}}</span> 
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>Num. Ext:</strong>  {{item.num_ext_fiscal}}</span> 
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>Num. Int:</strong>  {{item.num_int_fiscal}}</span> 
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>Colonia:</strong>  {{item.colonia_fiscal}}</span> 
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>Localidad:</strong> {{item.localidad}}</span> 
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>Ciudad:</strong>  {{item.ciudad_fiscal}}</span> 
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>CP:</strong>  {{item.codigo_postal}}</span> 
                            </v-col>
                            <v-col cols="12">
                              <span class="headline">Contacto</span>
                              <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>Telefono:</strong>  {{item.telfono}}</span> 
                            </v-col>
                            <v-col cols="12" md="6">
                              <span class="title"> <strong>Correo:</strong>  {{item.correo}}</span> 
                            </v-col>
                            
                        </v-row>
                      </v-container>
                  </v-card-text>

                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
                  </v-card-actions>   
                  </v-card>
          </v-dialog>
      <!--* Termina Modal *-->
    </section>
</div>
</template>
<script>
import axios from 'axios'
  export default {
    data: () => ({
      
      //Modelos

      dialog: false,
      listaDatosContratista: [],
      datosCotra: {
        rfc: '',
        razon_social: '',
        nombre_comercial: '',
        calle_fiscal: '',
        num_ext_fiscal: '',
        num_int_fiscal: '',
        colonia_fiscal: '',
        ciudad_fiscal: '',
        codigo_postal: 0,
        telfono: '',
        correo: '',
        localidad: '',
      },
      rfc: '',

      defaultItem: {
          EspecialidadId: '0',
          SubPartidaId: '0',
          PartidaId: '0',
          filtro: '0',
      },
      seleccionado:'',


      listadoCompontente1: [],
      headers: [
        { text: 'RFC', value: 'rfc' },
        { text: 'Nombre o Razon social', value: 'nombre_razon_social' },
      ],
      editedIndex: -1,
      
      singleSelect: false,
      selected: [],
     
    }),

    

    computed: {
      formTitle() {
            return 'Datos Generales del Contratista';
        },
      
    },

    watch: {
      dialog(val) {
            val || this.close()
        },
      
    },

    created () {
      
      
       this.listar();
    },

    // created () {
      
    // },

    methods: {

      

      listar(){
        //Enlistar registos
        //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller
        let me = this;
        axios.get('api/ContratistasREC/ListContratistas' ).then(function(response){
            console.log(response.data);
          me.listadoCompontente1 = response.data.data.filter(x => x.rfc != undefined && x.rfc !="");
          
        }).catch(function(error){
          console.log(error);
        });
      },



      datosContratista(item){
        //trae la informacion de un contratista selecionado 
        let me = this;
        console.log(item);
        me.seleccionado = item;
        console.log(me.seleccionado.rfc );
        debugger;
        axios.get('api/ContratistasREC/datosContratista/'+ me.seleccionado.rfc).then(function(response){
        debugger;
          console.log(response.data);
          me.listaDatosContratista = response.data.data;



          me.dialog = true;
        }).catch(function(error){
          console.log(error);
        });

      },
      open(item) {
            
            //Estas variables sirven para mostrar la ventana modal (Dialog) e indicar que se va a editar un registro
            this.dialog = true;
        },

      close() {
            //Cierra el Dialog (Ventana Modal)
            this.dialog = false;
        },
      

    }

  }
</script>