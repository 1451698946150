<template>
<v-layout align-start>
    <v-flex>
        <v-card dark>
            <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/FondoNL2.jpg" class="grey lighten-2" height="520" width="100%">
                <v-row class="fill-height pa-3" align="center">
                    <v-col cols="12" md="8" offset="1" class="d-none d-md-block mt-3">
                        <br>
                        <h1 class="display-2 font-weight-bold"  style="margin-top:300px ">
                            Histórico de contratos
                        </h1>
                        <h4 class="display-1 font-weight-light"  >
                            Obras transparentes
                        </h4>
                        <br>
                    </v-col>
                    <v-col cols="10" offset="1" class="d-block d-md-none mt-5" >
                        <br>
                        <h1 class="headline font-weight-bold"  style="margin-top:300px ">
                            Histórico de contratos
                        </h1>
                        <h4 class="headline font-weight-light" >
                            Obras transparentes
                        </h4>
                        <br>
                    </v-col>
                </v-row>
            </v-img>
        </v-card>
        <v-spacer></v-spacer>

        <v-card>
            <v-tabs v-model="tab">
                <v-tab key="Listado" href="#Listado" ripple>
                    Listado
                </v-tab>
                <v-tab key="Conceptos" href="#Conceptos" style="display:none;" ripple>
                    Conceptos
                </v-tab>
                <v-tab-item key="Listado" value="Listado">
                    <v-card flat>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="6" lg="6">
                                    <br>
                                    <v-range-slider v-model="rangoCosto" label="Costo" :min="0" :max="costoMax" @change="filtrar()"></v-range-slider>
                                    <span>{{"$"+ new Intl.NumberFormat("en-US").format(rangoCosto[0])+".00"}} -- </span>

                                    <span>{{"$"+ new Intl.NumberFormat("en-US").format(rangoCosto[1])+".00"}}</span>
                                </v-col>
                                <v-col cols="12" md="6" >
                                    <v-select v-model="parmAnio" :items="años" label="Año" clearable @change="filtrar()">

                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="6" >
                                    <v-select v-model="parmTipo" label="Tipo de obra" :items="tipoObras" clearable @change="filtrar()">

                                    </v-select>
                                </v-col>

                                <v-col cols="12" md="6" >
                                    <v-select v-model="parmDependencia" label="Dependencia" :items="dependencias" clearable @change="filtrar()">

                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4" >
                                    <v-text-field v-model="contrato" label="Contrato" @keyup="filtrar()" single-line hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" >
                                    <v-text-field v-model="empresa" @keyup="filtrar()" label="Empresa" single-line hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" >
                                    <v-text-field v-model="obra" label="Obra" @keyup="filtrar()" single-line hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-text>
                            <!--Listado de Contratos-->
                            <v-data-table v-model="selected" :sort-by.sync="sort" :sort-desc.sync="desc" :headers="headers" :items="listadoFiltro" :single-select=true item-key="contratoId" class="elevation-1" dense @click:row="mostrarItem">
                                <template v-slot:top>

                                    <v-toolbar flat color="white">

                                    </v-toolbar>
                                </template>
                                <template v-slot:item.montoTotal="{ item }">
                                    {{"$" + new Intl.NumberFormat("en-US").format(item.montoTotal)}}
                                </template>

                                <template v-slot:no-data>
                                    <v-btn color="primary" @click="listar">Actualizar</v-btn>
                                </template>
                            </v-data-table>

                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item key="Conceptos" value="Conceptos">
                    <v-card flat>
                        <v-card-title class="headline"> <b>{{seleccionado +":"}}</b>  </v-card-title >
                        <v-card-text class="headline"> {{descripcion}}</v-card-text>
                        <v-card-text>
                            <!--Listado de Contratos-->
                            <v-data-table v-model="selected" :headers="headersConceptos" :items="listadoConceptos" :single-select=true item-key="idConcepto" dense class="elevation-1">
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                    </v-toolbar>
                                </template>
                                <template v-slot:no-data>
                                    <v-btn color="primary" @click="listar">Actualizar</v-btn>
                                </template>
                                <template v-slot:item.importeTotal="{ item }">
                                    {{"$" + new Intl.NumberFormat("en-US").format(item.importeTotal)}}
                                </template>

                            </v-data-table>

                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </v-flex>
</v-layout>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({
        tab: 'Listado',
        seleccionado: '',
        sort:'contratoId',
        desc:true,
        listadoCompontente1: [],
        listadoFiltro: [],
        listadoConceptos: [],
        dialog: false,
        costoMax: 10,
        search: '',
        rangoCosto: [],
        selected: [],
        parmAnio: '',
        parmTipo: '',
        parmDependencia: null,
        años: [],
        tipoObras: [],
        dependencias: [],
        descripcion: '',
        headers: [{
                text: 'Contrato',
                value: 'numeroContrato',
                class: "indigo white--text title",
            },
            {
                text: 'Empresa',
                value: 'empresa',
                class: "indigo white--text title",
            },
            {
                text: 'Tipos de obra',
                value: 'tiposObra',
                class: "indigo white--text title",
            },
            {
                text: 'Año',
                value: 'periodo',
                class: "indigo white--text title",
            },
            {
                text: 'Obra',
                value: 'obra',
                class: "indigo white--text title",
            },
            {
                text: 'Importe inc. IVA',
                value: 'montoTotal',
                class: "indigo white--text title",
            },
            {
                text: 'Estatus',
                value: 'estatus',
                class: "indigo white--text title",
            },
        ],
        headersConceptos: [{
                text: 'Id',
                value: 'idConcepto',
                class: "indigo white--text title",
            },
            {
                text: 'Concepto',
                value: 'concepto',
                class: "indigo white--text title",
            },
            {
                text: 'Unidad',
                value: 'unidad',
                class: "indigo white--text title",
            },
            {
                text: 'Total',
                value: 'importeTotal',
                class: "indigo white--text title",
            },
        ],
        editedIndex: -1,
        defaultItem: {
            ContratoId: '',
            Empresa: '',
            Periodo: '',
            Obra: '',
            MontoTotal: '',
            Estatus: ''
        },
        valida: 0,
        validaMensaje: []
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo registro' : 'Editar registro'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
    },

    created() {
        this.listar();
        this.obra = "";
        this.empresa = "";
        this.contrato = "";
    },

    methods: {

        listar() {
            //Enlistar registos
            //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

            let me = this;
            // let header = {"Authorization":"Bearer " + this.$store.state.token};
            // let configuration={headers:header};
            axios.get('api/historicoContratos/listarPublico').then(function (response) {
                console.log(response.data);
                me.listadoCompontente1 = response.data;
                me.listadoFiltro = me.listadoCompontente1;
                // me.formatoMonto();
                me.obtenerRangoCosto();
                me.listarAños();
                me.listarTiposObra();
                me.listarDependencias();

            }).catch(function (error) {
                //Se agrega elemento a la lista por default
                me.listadoCompontente1.push({
                    contratoId: '1',
                    empresa: 'A',
                    periodo: '2018',
                    obra: 'A',
                    montoTotal: '15000000',
                    estatus: 'A'
                });
                me.listadoCompontente1.push({
                    contratoId: '2',
                    empresa: 'B',
                    periodo: '2019',
                    obra: 'B',
                    montoTotal: '20000000',
                    estatus: 'B'
                });
                console.log(error);
            });
        },
        // formatoMonto() {
        //     let me = this;
        //     me.listadoFiltro.forEach(x => x.montoTotal = "$" + new Intl.NumberFormat("en-US").format(x.montoTotal))
        // },
        listarAños() {
            let me = this;
            var listadoAños = [];
            me.listadoCompontente1.forEach(x => {
                listadoAños.push(x.periodo)
            });

            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            me.años = listadoAños.filter(onlyUnique);
            console.log(me.años)
        },
        listarTiposObra() {
            let me = this;
            var listadoTipoObras = [];
            me.listadoCompontente1.forEach(x => {
                listadoTipoObras.push(x.tiposObra)
            });

            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            me.tipoObras = listadoTipoObras.filter(onlyUnique);
            console.log(me.tipoObras)
        },
        listarDependencias() {
            let me = this;
            var listadoDependencias = [];
            me.listadoCompontente1.forEach(x => {
                listadoDependencias.push(x.dependencia)
            });

            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            me.dependencias = listadoDependencias.filter(onlyUnique);
            console.log(me.dependencias)
        },
        obtenerRangoCosto() {
            let me = this;
            var listadoCostos = [];
            me.listadoCompontente1.forEach(x => {
                listadoCostos.push(x.montoTotal)
            });

            me.costoMax = Math.max(...listadoCostos);
            me.costoMax = Math.trunc(me.costoMax) + 1;
            me.rangoCosto.push(0);
            me.rangoCosto.push(me.costoMax);
            console.log(me.rangoCosto);
            console.log(me.costoMax)

        },
        filtrar() {
            let parmObra = this.obra.toUpperCase();
            let parmEmpresa = this.empresa.toUpperCase();
            let parmContrato = this.contrato.toUpperCase();
            let parmCosto = this.rangoCosto;
            this.listadoFiltro = this.listadoCompontente1;
            console.log(this.listadoFiltro)
            if (parmEmpresa != "" || null) {
                this.listadoFiltro = this.listadoFiltro.filter(function (x) {
                    return (x.empresa.includes(parmEmpresa))
                });
            }
            if (parmObra != "" || null) {
                this.listadoFiltro = this.listadoFiltro.filter(function (x) {
                    return (x.obra.includes(parmObra))
                });
            }
            if (parmContrato != "" || null) {
                this.listadoFiltro = this.listadoFiltro.filter(function (x) {
                    return (x.numeroContrato.includes(parmContrato))
                });
            }
            if (!(this.parmAnio == null || this.parmAnio == '')) {
                this.listadoFiltro = this.listadoFiltro.filter(x =>
                    x.periodo == this.parmAnio
                );
            }
            if (!(this.parmTipo == null || this.parmTipo == '')) {
                this.listadoFiltro = this.listadoFiltro.filter(x =>
                    x.tiposObra == this.parmTipo
                );
            }
            if (!(this.parmDependencia == null || this.parmDependencia == '')) {
                this.listadoFiltro = this.listadoFiltro.filter(x =>
                    x.dependencia == this.parmDependencia
                );
            }
            this.listadoFiltro = this.listadoFiltro.filter(reg =>
                Math.trunc(reg.montoTotal) <= parmCosto[1] && Math.trunc(reg.montoTotal) >= parmCosto[0]
            )
            console.log(parmCosto[1]);
            console.log(this.listadoFiltro)

        },

        mostrarItem(item) {
            let me = this;
            this.selected = [];
            this.selected.push(item);
            this.tab = 'Conceptos';
            this.seleccionado = item.numeroContrato;
            this.descripcion = item.obra;
            console.log(this.selected);

            axios.get('api/historicoContratos/listarConceptos/' + this.seleccionado).then(function (response) {
                console.log(response.data);
                me.listadoConceptos = response.data;
            }).catch(function (error) {
                //Se agrega elemento a la lista por default
                console.log(error);
            });
        },
    },
}
</script>
