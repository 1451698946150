import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


import Mapa from '../components/Mapa.vue'
import Noticias from '../components/Noticias.vue'


import Inicio from '../components/Home/Inicio.vue'
import Login from '../components/Home/Login.vue'
import NoDisponible from '../components/Home/NoDisponible.vue'

import Concursos from '../components/Concursos/Concursos.vue'
import Concurso from '../components/Concursos/Concurso.vue'

import HistoricoContratosPublicos from '../components/HistoricoContratos/HistoricoContratosPublicos.vue'
import store from '../store/index.js'

import Aclaraciones from '../components/Aclaraciones/Aclaraciones.vue'
import AclaracionesRespuesta from '../components/Aclaraciones/AclaracionesRespuesta.vue'
import AclaracionesEditar from '../components/Aclaraciones/AclaracionesEditar.vue'

import COVID from '../views/COVID.vue'
import About from '../views/About.vue'
import BIM from '../views/BIM.vue'
import AlianzaReal from '../views/AlianzaReal.vue'
import EnProceso from '../views/EnProceso.vue'

import CatalogoUniversal from '../views/CatalogoUniversal.vue'

import Contratistas from '../components/Contratistas.vue'
import AvisosDePrivacidad from '../views/AvisosDePrivacidad.vue'


Vue.use(VueRouter)


  const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio,
    meta:{
      libre: true
    }
  },
  {
    path: '/Noticias/:id',
    name: 'Noticias',
    component: Noticias,
    meta:{
      libre: true
    }
  },
  {
    path: '/About',
    name: 'About',
    component: About,
    meta:{
      libre: true
    }
  },
  {
    path: '/Mapa',
    name: 'Mapa',
    component: Mapa,
    meta:{
      libre: true
    }
  },
  {
    path: '/NoDisponible',
    name: 'NoDisponible',
    component: NoDisponible,
    meta:{
      libre: true
    }
  },
  {
    path: '/CatalogoUniversal',
    name: 'CatalogoUniversal',
    component: CatalogoUniversal,
    meta:{
      libre: true
    }
  },
  
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta:{
      libre: true
    }
  },
  {
    path: '/Contratistas',
    name: 'Contratistas',
    component: Contratistas,
    meta:{
      libre: true
    }
  },
  {
    path: '/HistoricoContratosPublicos',
    name: 'HistoricoContratosPublicos',
    component: HistoricoContratosPublicos,
    meta:{
      libre: true
    }
  },

  {
    path: '/Aclaraciones',
    name: 'Aclaraciones',
    component: Aclaraciones,
    meta:{
      libre: true
    }
  },
  {
    path: '/AclaracionesRespuesta',
    name: 'AclaracionesRespuesta',
    component: AclaracionesRespuesta,
    meta:{
      libre: true
    }
  },
  {
    // path: '/AclaracionesEditar',
     path: '/AclaracionesEditar/:guid',
    name: 'AclaracionesEditar',
    component: AclaracionesEditar,
    meta:{
      libre: true
    }
  },
  {
     path: '/Concursos',
    name: 'Concursos',
    component: Concursos,
    meta:{
      libre: true
    }
  },
  {
     path: '/Concursos/:seleccionado',
    name: 'Concurso',
    component: Concurso,
    meta:{
      libre: true
    }
  },


{
  path: '/AlianzaReal',
  name: 'AlianzaReal',
  component: AlianzaReal,
  meta:{
   libre: true
 }
},
 {
  path: '/EnProceso',
  name: 'EnProceso',
  component: EnProceso,
  meta:{
   libre: true
 }
},
{
  path: '/BIM',
  name: 'BIM',
  component: BIM,
  meta:{
   libre: true
 }
},
{
  path: '/COVID',
  name: 'COVID',
  component: COVID,
  meta:{
   libre: true
 }
},
{
  path: '/AvisosDePrivacidad',
  name: 'Avisos De Privacidad',
  component: AvisosDePrivacidad,
  meta:{
   libre: true
 }
},
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

var router = new VueRouter({
  mode: 'history',
  base : '/web/',
  //base: process.env.BASE_URL, 
  //base : '/', 
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return {x: 0, y: 0}
    }
  },
  routes
})

router.beforeEach((to,from,next) => {
  if(to.matched.some(record => record.meta.libre)){
    next()
  }else if(store.state.usuario && store.state.usuario.role=='Administrador'){
    if(to.matched.some(record => record.meta.administrador)){
      next()
    }
  }else{
    next({
      name: 'Inicio'
    });
  }
})

export default router
