<template>
    <v-layout align-start>
        <v-flex>
            <h1 >Localidad</h1>
            <br>
            <v-divider></v-divider>
            <br>
            <v-data-table
                :headers="headers"
                :items="listadoCompontente1"
                :search="search"
                class="elevation-1"
            >
                
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios'
  export default {
    data: () => ({

      inset: false,
      items: [
        'default',
        'vertical',
      ],
      variant: 'default',

      listadoCompontente1: [],
      dialog: false,
      search: '',
      headers: [
        
        { text: 'Nombre', value: 'municipioNombre', class: "indigo white--text title", },
        { text: 'Localidad', value: 'localidadNombre', class: "indigo white--text title", },
      ],
      editedIndex: -1,
      defaultItem: {
        ConcursoId:'',
        MunicipioNombre:'',
        LocalidadNombre:''
      },
      valida: 0,
      validaMensaje:[]
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva aclaración' : 'Solicitud'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.listar();
    },

    methods: {
      listar(){
        //Enlistar registos
        //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

        let me = this;
        me.defaultItem.ConcursoId = me.$route.params.concursoid;
        let header = {"Authorization":"Bearer " + this.$store.state.token};
        let configuration={headers:header};
        axios.get('api/concursos/MostrarLocalidades/' +  me.defaultItem.ConcursoId).then(function(response){
            console.log(response.data);
          me.listadoCompontente1 = response.data;
        }).catch(function(error){
          console.log(error);
        });
      },
      close () {
        //Cierra el Dialog (Ventana Modal)

        this.limpiar();
        this.dialog = false;
      },

      limpiar(){
        this.editedIndex = -1;
        this.valida = 0;
        this.validaMensaje = [];

        this.defaultItem = {
         MunicipioNombre:'',
         LocalidadNombre:''
        };

      }
    },
  }
</script>