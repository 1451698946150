var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"align-start":""}},[_c('v-flex',[_c('h1',[_vm._v(" Eventos ")]),_c('v-spacer'),_c('br'),_c('v-divider'),_c('br'),_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listadoCompontente1,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return _vm._l((item.url),function(itemfor,index){return _c('v-btn',{key:index,attrs:{"icon":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.titulo[index].toLowerCase() === 'bases')?_c('a',{attrs:{"href":'https://smpu.nl.gob.mx/' + itemfor}},[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"pink","small":""}},on),[_vm._v("mdi-file-document-outline")])],1):(item.titulo[index].toLowerCase().includes('anexos'))?_c('a',{attrs:{"href":'https://smpu.nl.gob.mx/' + itemfor}},[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"teal","small":""}},on),[_vm._v("mdi-file-document-outline")])],1):_c('a',{attrs:{"href":'https://smpu.nl.gob.mx/' + itemfor}},[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-file-document-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.titulo[index]))])])],1)})}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fecha.substring(0,10) == "0001-01-01" ? "" : item.fecha.substring(0,10))+" ")]}},{key:"item.hora",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hora.substring(11) == "00:00:00" ? "" : item.hora.substring(11))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }