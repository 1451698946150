<template>
<v-app id="inspire">

    <v-toolbar width="100%" height="90" max-height="90" color="#ffffff"  dense>
        <!-- Adjust the height to your needs, mine is 40 -->
        <v-btn to="/"  color="#ffffff" x-large height="100%" tile width="120px" ><img class="mr-3" src="https://smpu.nl.gob.mx/acceso/assets/websi/LogoNL2.svg" height="80" width="auto" /></v-btn>
        <v-spacer></v-spacer>
        <div class="mr-3 d-none d-lg-block" >
            <v-toolbar-items >
                <v-btn elevation="0" style=" height: 35px !important;" text to="/">
                    <v-icon dark>mdi-home</v-icon><br />
                </v-btn>
                <v-btn elevation="0" small  style=" height: 35px !important;" text @click="dialog1 = true"><br />Nosotros <br /> </v-btn>
                <v-btn elevation="0" small  style=" height: 35px !important;" text @click="dialog5 = true"><br />SEMSV<br /> </v-btn>
                <v-btn elevation="0" small  style="height: 35px !important;" text href="/web/Concursos"><br />Concursos  </v-btn>
                <v-btn elevation="0" small  style="height: 35px !important;" text href="/web/historicocontratospublicos">Histórico de <br /> contratos</v-btn>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="0" small  v-bind="attrs" v-on="on" style="height: 35px !important;" text >Registro de<br> Proveedores</v-btn>
                    </template>
                    <v-list>
                        <v-list-item target="_blank" href="https://proveedores.nl.gob.mx/" style="border-left: 2px !important;">
                            Registro de<br> Proveedores
                        </v-list-item>
                        <!-- <v-divider></v-divider>
                            <v-list-item href="/contratistas" style="border-left: 2px !important;">
                            Listado de <br> contratistas
                        </v-list-item> -->
                    </v-list>
                </v-menu>
                <v-btn elevation="0" small  style="height: 35px !important;" text @click="dialog = true">Recibe<br> Notificaciones</v-btn>
                <v-btn elevation="0" small  style="height: 35px !important;" target="_blank" text href="http://corrupnetnl.mx">Denuncia <br /> Ciudadana</v-btn>
                <v-btn elevation="0" small  style="height: 35px !important;" target="_blank" text href="https://smpu.nl.gob.mx/transparencia">Infraestructura <br /> Abierta</v-btn>
            </v-toolbar-items>

            <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
                <v-list>
                    <v-list-item to="/"  style="border-left: 2px !important;">
                        Inicio
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="dialog1 = true" style="border-left: 2px !important;">
                        Nosotros
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="dialog5 = true" style="border-left: 2px !important;">
                        SEMSV
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item href="/web/Concursos" style="border-left: 2px !important;">
                        Concursos
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item href="/web/historicocontratospublicos" style="border-left: 2px !important;">
                        Histórico de <br /> contratos
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item href="https://proveedores.nl.gob.mx/" style="border-left: 2px !important;">
                        Registro de<br> Proveedores
                    </v-list-item>
                    <!-- <v-divider></v-divider>
                        <v-list-item href="/contratistas" style="border-left: 2px !important;">
                        Listado de <br> contratistas
                    </v-list-item> -->
                    <v-divider></v-divider>
                    <v-list-item @click="dialog = true" style="border-left: 2px !important;">
                        Recibe<br> Notificaciones
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item href="http://corrupnetnl.mx" style="border-left: 2px !important;">
                        Denuncia <br /> Ciudadana
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <div class="mr-3 d-block d-lg-none">
            <v-app-bar-nav-icon @click.stop="show"></v-app-bar-nav-icon>
        </div>
        <v-dialog v-model="dialog1" max-width="1100">
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                        <h4> Nosotros | Secretaría de Movilidad y Planeación Urbana</h4>
                    </v-card-title>
                    <v-card-text>
                        <div class="modal-body mb-0 text-center">
                            <div class="row">
                                <img src="https://smpu.nl.gob.mx/acceso/assets/websi/SMPULogo.svg" />
                            </div>
                            <div class="row">
                                <!-- <div class="col-lg-6 col-sm-12">
                                    <h3 class="titulos">Visión</h3>
                                    <br />
                                    <p>
                                        Trascender como la primera dependencia de un gobierno independiente reconocida a nivel nacional por su
                                        modelo competitivo y de transparencia total, garantizando el uso óptimo de los recursos públicos.
                                    </p>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <h3 class="titulos">Misión</h3>
                                    <br />
                                    <p>
                                        Desarrollar obra pública de alta calidad, incluyente y de beneficio social para los habitantes de Nuevo León,
                                        con eficiencia, innovación, honestidad y transparencia, atrayendo inversiones y consolidando a nuestro estado como el mejor lugar para vivir.
                                    </p>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <h3 class="titulos">Responsabilidades</h3>
                                    <br />
                                    <p>Dependencia encargada de la proyección y construcción de las obras públicas que conciernen a la Administración Pública del Estado.</p>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <h3 class="titulos">Prioridades</h3>
                                    <br />
                                    <p>
                                        Satisfacer las necesidades de los habitantes de Nuevo León en la creación, conservación y mejoramiento de la Infraestructura del estado,
                                        con calidad, seguridad y transparencia, en un marco de sustentabilidad y respeto al medio ambiente.
                                    </p>
                                </div> -->
                                <div class="col-12">
                                    <div><img src="https://www.nl.gob.mx/sites/default/files/styles/epsa_profile/public/hernaun_villarreal_rodriuguez.jpg" style="border-radius:10%;" /></div>
                                    <h3 class="headline">Hernán Villarreal Rodríguez</h3>
                                    <h4 style="font-family:Gotham; font-size:1.5em; font-weight:600;">Secretario de Movilidad y Planeación Urbana</h4>
                                    <br />
                                    <h3 class="titulos">Responsabilidades de esta dependencia</h3>
                                    <br />
                                    <p>
                                        Formular, conducir, planear y evaluar la política de movilidad mediante la responsabilidad de conservar y asegurar que las necesidades de tráfico protejan el bienestar y seguridad social de los ciudadanos; el desarrollo urbano estatal implementará una mejor estrategia de planeación en la distribución de la urbanización; y de la proyección y construcción de las obras públicas teniendo como objetivo el beneficio social de la población y el crecimiento económico del Estado, que le conciernen a las dependencias de la Administración Pública del Estado.
                                    </p>
                                    <!-- <h3 class="titulos">Organigrama</h3>

                                    <v-img v-bind="attrs" v-on="on" max-width="1000" src="https://www.nl.gob.mx/sites/default/files/10.infraestructura.jpg">
                                    </v-img>

                                    <div style="text-align:left" class="container">
                                        <v-btn color="blue" small height="50" width="280" class="ma-1 " href="https://www.nl.gob.mx/funcionarios/silvia-vargas-villarreal" target="_blank">
                                            Secretaría Particular
                                        </v-btn> <br>
                                        <v-btn color="blue" small height="50" width="280" class="ma-1 " href="https://www.nl.gob.mx/funcionarios/rafael-salgado-garcia" target="_blank">
                                            Secretaría Técnica
                                        </v-btn><br>
                                        <v-btn color="blue" small height="50" width="280"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/lorenzo-javier-zamarron-gutierrez" target="_blank">
                                            Dirección de Transparencia <br> y Normatividad
                                        </v-btn><br>
                                        <v-btn color="blue" small height="50" width="280"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/jorge-alberto-tovar-nicoli" target="_blank">
                                            Unidad de Inversiones 
                                        </v-btn><br>
                                        <v-btn color="blue" small height="50" width="280"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/rosa-amelia-lozano-zambrano" target="_blank">
                                            Subsecretaría de Construcción
                                        </v-btn><br>
                                        <v-row>
                                            <v-col>
                                                <v-btn color="blue" outlined small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/sandra-guadalupe-patino-macias" target="_blank">
                                                    Dirección de Proyectos
                                                </v-btn>
                                            
                                                <v-btn color="blue" outlined  small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/leonel-botello-de-leon" target="_blank">
                                                    Dirección de Edificación
                                                </v-btn>
                                        
                                                <v-btn color="blue" outlined  small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/jose-eleuterio-garcia-velazquez" target="_blank">
                                                    Dirección de Obras Especiales
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                        <v-btn color="blue" small height="50" width="280"   class="ma-1 " href="https://www.nl.gob.mx/funcionarios/godofredo-gardner-anaya" target="_blank">
                                            Subsecretaría de Planeación
                                        </v-btn>
                                        <v-row>
                                            <v-col>
                                                <v-btn color="blue" outlined  small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/alejandro-sanchez-gomez" target="_blank">
                                                    Dirección de Licitaciones
                                                </v-btn>
                                            
                                                <v-btn color="blue" outlined small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/noemi-gutierrez-moreno" target="_blank">
                                                    Unidad de Enlace Administrativo
                                                </v-btn>
                                            
                                                <v-btn color="blue" outlined small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/alfonso-salazar-lopez" target="_blank">
                                                    Dirección de Planeación, <br> Programación y Presupuesto
                                                </v-btn>
                                            
                                                <v-btn color="blue" outlined small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/maria-eugenia-gonzalez-monreal" target="_blank">
                                                    Dirección de Bienestar Ciudadano
                                                </v-btn>
                                                <v-btn color="blue" outlined small height="40" width="270"  class="ma-1 " href="https://www.nl.gob.mx/funcionarios/jesus-antonio-galindo-gutierrez" target="_blank">
                                                    Dirección de Innovación y <br> Mejora Continua de Procesos
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div> -->
                                </div>
                            </div>

                            <br />

                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="dialog1 = false">
                            salir
                        </v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>

        <!-- New model -->

        <div class="mr-3 d-block d-lg-none">
            <v-app-bar-nav-icon @click.stop="show"></v-app-bar-nav-icon>
        </div>
        <v-dialog v-model="dialog5" max-width="1100">
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                        <h4>Sistema Estatal de Movilidad y Seguridad Vial</h4>
                    </v-card-title>
                
        <v-row >
            <v-col style="padding: 0px 350px 0px 350px;">
            <v-col cols="12" sm="6" md="12" xl="6">
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/SEMSV/LINEAMIENTOS%20DE%20ORGANIZACI%C3%93N%20Y%20OPERACI%C3%93N%20DEL%20SEMSV.pdf" class="d-none d-sm-block d-md-none d-xl-block">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Lineamientos de organización <br> y operación del SEMSV</v-card-text>
                </v-card>
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/SEMSV/LINEAMIENTOS%20DE%20ORGANIZACI%C3%93N%20Y%20OPERACI%C3%93N%20DEL%20SEMSV.pdf" class="d-block d-sm-none d-md-block d-xl-none">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Lineamientos de organización <br> y operación del SEMSV</v-card-text>
                </v-card>
            </v-col>    
            </v-col>

            <v-col style="padding: 0px 350px 0px 350px;">
            <v-col cols="12" sm="6" md="12" xl="6">
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/SEMSV/Minuta%20SEMSV.pdf" class="d-none d-sm-block d-md-none d-xl-block">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Minuta SEMSV</v-card-text>
                </v-card>
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/SEMSV/Minuta%20SEMSV.pdf" class="d-block d-sm-none d-md-block d-xl-none">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Minuta SEMSV</v-card-text>
                </v-card>
            </v-col>
            </v-col>

            <v-col style="padding: 0px 350px 0px 350px;">
            <v-col cols="12" sm="6" md="12" xl="6">
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/SEMSV/Presentaci%C3%B3n%20SEMSV%20final.pdf" class="d-none d-sm-block d-md-none d-xl-block">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Presentación SEMSV final</v-card-text>
                </v-card>
                <v-card target="_blank" href="https://smpu.nl.gob.mx/acceso/MarcoNormativo/SEMSV/Presentaci%C3%B3n%20SEMSV%20final.pdf" class="d-block d-sm-none d-md-block d-xl-none">
                    <v-card-text class="headline"  style="word-break:normal; text-align:center">Presentación SEMSV final</v-card-text>
                </v-card>
            </v-col>
            </v-col>

        </v-row>
              
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="dialog5 = false">
                            salir
                        </v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>

        <!-- Termina -->


        <v-dialog v-model="dialog" width="900">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    <h4> Recibe Notificaciones</h4>
                </v-card-title>
                <v-card-text>
                    <div class="modal-body mb-0 text-center">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <v-text-field v-model="Ciudadanos.Email" :rules="[rules.required, rules.email]" name="Email" label="Correo electronico" id="id"></v-text-field>
                                <v-text-field v-model="Ciudadanos.NombreCiudadano" :rules="[rules.required , rules.texto]" name="Nombre" label="Nombre" id="id"></v-text-field>
                                <v-text-field v-model="Ciudadanos.PrimerApellido" :rules="[rules.required, rules.texto]" name="Apellido" label="Apellido" id="id"></v-text-field>
                            </div>
                        </div>
                        <br />
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click="cerrar()">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="GuardarCiudadano()">
                        Registrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-toolbar>
    <!-- <v-toolbar width="100%" height="35" max-height="35" class="pt-1 pb-1" style="background-color:#f2f2f2">

      <div class="mr-3" style="text-align:center">
      </div>

    </v-toolbar> -->



    <v-content>

        <!-- <div>
              <Mapa class="mapa"/>
            </div> -->
        <v-container class="ma-0 pa-0" fluid>
            <v-slide-y-transition mode="out-in">
                <router-view />

            </v-slide-y-transition>

        </v-container>
        <br>
    </v-content>
    <v-card height="auto">
        <v-footer padless dark color="#3d3f42">
            <v-container >
                <v-row>
                    <v-col>
                        <v-card flat color="#3d3f42" href="tel:911">
                        <div class="headline"><strong>911</strong></div>
                        <div class="headline">Emergencias</div>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat color="#3d3f42" href="tel:089">
                        <div class="headline"><strong>089</strong></div>
                        <div class="headline">Denuncia anónima</div>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat color="#3d3f42" href="tel:070">
                        <div class="headline"><strong>070</strong></div>
                        <div class="headline">Informatel y Locatel</div>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat color="#3d3f42" href="tel:073">
                        <div class="headline"><strong>073</strong> </div>
                        <div class="headline">Agua y drenaje</div>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card flat color="#3d3f42" href="http://aire.nl.gob.mx/" title="Ir a portal de Monitoreo ambiental" target="_blank">
                        <v-icon>mdi-sprout</v-icon>
                        <div class="headline">Monitoreo ambiental</div>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col cols="12" md="5" >
                        <v-row class="justify-center">
                            <img style="max-height:220px" src="https://smpu.nl.gob.mx/acceso/assets/websi/escudo-nl2.svg">
                        </v-row>
                        
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-row>
                        <v-col cols="12" md="5">
                            <v-row class="justify-md-end justify-center">
                                <v-card flat color="#3d3f42" href="http://nl.gob.mx/gobierno">
                                    <div class="headline">Gobierno</div>
                                </v-card>
                            </v-row>
                            <v-row class="justify-md-end justify-center">
                                <v-card flat color="#3d3f42" href="http://nl.gob.mx/tramites-y-servicios">
                                    <div class="headline" style="text-align:right">Trámites y servicios</div>
                                </v-card>
                            </v-row>
                            <v-row class="justify-md-end justify-center">
                                <v-card flat color="#3d3f42" href="http://nl.gob.mx/actualidad">
                                    <div class="headline">Actualidad</div>
                                </v-card>
                            </v-row>
                            <v-row class="justify-md-end justify-center">
                                <v-card flat color="#3d3f42" href="http://nl.gob.mx/transparencia">
                                    <div class="headline">Transparencia</div>
                                </v-card>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="1" class="px-5">
                            <div style="background-color:#e1e8e7; width:2px; height:100%"></div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-row class="justify-center my-5">
                                <v-btn x-small class="elevation-0 transparent" target="_blank" href="https://www.facebook.com/gobiernonuevoleon/"><v-icon>mdi-facebook</v-icon></v-btn>
                                <v-btn x-small class="elevation-0 transparent" target="_blank" href="https://twitter.com/nuevoleon"><v-icon>mdi-twitter</v-icon></v-btn>
                                <v-btn x-small class="elevation-0 transparent" target="_blank" href="https://www.instagram.com/nuevoleonmx/"><v-icon>mdi-instagram</v-icon></v-btn>
                                <v-btn x-small class="elevation-0 transparent" target="_blank" href="https://www.youtube.com/user/GobiernoNuevoLeon"><v-icon>mdi-youtube</v-icon></v-btn>
                                <v-btn x-small class="elevation-0 transparent" target="_blank" href="https://t.me/gobnl"><v-icon>mdi-telegram</v-icon></v-btn>
                            </v-row>
                            <v-row class="justify-center" >
                                <v-card flat color="#3d3f42" href="http://nl.gob.mx/campanas/codigo-de-etica-del-gobierno-del-estado-de-nuevo-leon">
                                    <div class="headline">Código de Ética</div>
                                </v-card>
                            </v-row>
                            
                        </v-col>
                        </v-row>

                    </v-col>

                </v-row>
                <v-row >
                    <v-col cols="12" md="6" >
                        <v-card flat color="#3d3f42" href="https://nl.gob.mx/sobre-tratamiento-datos-personales" >
                        <div class="headline">Sobre el tratamiento de los datos personales</div>
                        </v-card>
                    </v-col>
                    <v-col  cols="12" md="3">
                        <v-card flat color="#3d3f42" href="https://nl.gob.mx/dinos-tu-opinion-sobre-este-sitio">
                        <div class="headline">Dinos tu opinión</div>
                        </v-card>
                    </v-col>
                    <v-col  cols="12" md="3">
                        <v-card flat color="#3d3f42" href="https://nl.gob.mx/contactanos">
                        <div class="headline">Contáctanos</div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </v-card>
</v-app>
</template>

<!-- Global site tag (gtag.js) - Google Analytics -->

<script async src="https://www.googletagmanager.com/gtag/js?id=UA-120666640-2"></script><script>
window.dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', 'UA-120666640-2');
</script><script>
import Inicio from './components/Inicio';
import axios from 'axios'
export default {
    name: 'App',

    props: {
        //source: String,
    },
    menuProveedores: false,

    components: {
        Inicio
    },

    data: () => ({
        rules: {
            required: value => !!value || 'Campo requerido.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Formato de correo no valido.'
            },
            texto: value => {
                const pattern = /\W/g
                return !pattern.exec(value) || 'Caracter no valido'
            },
        },
        showMenu: false,
        x: 0,
        y: 0,
        dialog: false,
        dialog1: false,
        dialog5: false,
        overlay: false,
        items: [{
                title: 'Suscribete',
                link: 'proveedores.nl.gob.mx/'
            },
            {
                title: 'Recibe Notificaciones',
                link: 'proveedores.nl.gob.mx/'
            },
        ],

        Ciudadanos: {
            NombreCiudadano: '',
            PrimerApellido: '',
            Email: ''
        },

    }),
    computed: {
        accesoRegistrado() {
            return this.$store.state.usuario;
        }

    },
    created() {
        //this.$store.dispatch("autoLogin");
    },

    methods: {
        show(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },

        salir() {

            this.$store.dispatch("salir");
            location.reload();
        },
        GuardarCiudadano() {

            let me = this;
            console.log('Envia Parametros', me.Ciudadanos);
            axios.post('api/Ciudadanos/Crear', me.Ciudadanos).then(function (response) {
                me.cerrar();
                alert("Se guardó la informacion correctamente");

            }).catch(function (error) {
                console.log(error);
            });
        },
        cerrar() {
            let me = this;
            me.dialog = false;
            me.limpiar();
        },
        limpiar() {
            let me = this;
            me.Ciudadanos.NombreCiudadano = '';
            me.Ciudadanos.PrimerApellido = '';
            me.Ciudadanos.Email = '';
        }
    }
};
</script>

<style>
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../public/tipografia/Montserrat-Regular.otf') format('opentype');
}
.application {
  font-family:  'Montserrat-Regular' !important;
}
</style>
