<template>
<v-layout align-start>
 <v-flex>
   <h1 >
      Imagenes
    </h1>
    <br>
  <v-divider></v-divider>
  <br>
  <v-carousel v-if="listadoCompontente1.length > 0">
    <v-carousel-item
      v-for="(item,i) in listadoCompontente1"
      :key="i"
      :src="item.url"
      reverse-transition="fade-transition"
      transition="fade-transition"
    ></v-carousel-item>
  </v-carousel>
  <v-else>
    "No hay imagenes para mostrar."
  </v-else>
  </v-flex>
 </v-layout>
</template>

<script>
import axios from 'axios'
  export default {
    data () {
      return {        
        model: 0,
        listadoCompontente1: [],
        ConcursoId:'',
        URL:'',
      }
    },
    props: {
        concursoid: Number,
    },
    created () {
      this.listar();
    },

    methods: {
      listar(){
        //Enlistar registos
        //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

        let me = this;
        let header = {"Authorization":"Bearer " + this.$store.state.token};
        let configuration={headers:header};
        axios.get('api/concursos/MostrarImagenes/' +  me.concursoid).then(function(response){
            console.log(response.data);
          me.listadoCompontente1 = response.data;
        }).catch(function(error){
          console.log(error);
        });
      },

      close () {
        //Cierra el Dialog (Ventana Modal)

        this.limpiar();
        this.dialog = false;
      },


      limpiar(){
        this.editedIndex = -1;
        this.valida = 0;
        this.validaMensaje = [];

        this.defaultItem = {
        ConcursoId : '',
        Nombre:'',
        Empresa:'',
        Email:'',
        Telefono:''
        };

      }
    },

  }
</script>