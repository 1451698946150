<template>
<div>
    <v-parallax
      dark 
      src="https://smpu.nl.gob.mx/acceso/assets/apoyocontratistas/fondo3.png"
      width="100%"
      
      height="670">
      <v-row align="center">
        <v-col class="text-center" cols="12">
          <h2 class="display-1  mb-4">Estamos actualizando el sistema</h2>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="text-center" cols="12">
          <span class="subheading" style="font-size:1.3em;" >Intenta acceder más tarde. Enviaremos una notificación a los usuarios registrados cuando el sistema esté disponible.
            <br>
          </span>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="text-center" cols="12" sm-12 md-6 lg-6 xl-6>
          <v-btn x-large color="#662D8F" dark @click="salir" v-if="accesoRegistrado">Inicio</v-btn>
          <v-btn x-large color="#662D8F" dark to="/" v-else>Inicio</v-btn>
        </v-col>
      </v-row>
      <br>
      <br>

    </v-parallax>
</div>
</template>
<script>

import axios from 'axios'
  export default {
    data: () => ({
        
        
    }),

    computed:{      
      accesoRegistrado(){
        return this.$store.state.usuario;
      }
    },

    methods: {
      salir(){
          
        this.$store.dispatch("salir");
        location.reload();
      }
    }

  }
</script>