<template>
    <v-layout align-start>
        <v-flex>
            <h1>Administración de Respuestas de Aclaraciones</h1>
            <v-spacer></v-spacer>
            <v-data-table
                :headers="headers"
                :items="listadoCompontente1"
                :search="search"
                class="elevation-1"
            >
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                  </v-icon>
                  
                  <!--<v-icon small @click="desactivarItem(item)" v-if="item.Rfc == 'True'" >
                      check
                  </v-icon>
                  <v-icon small @click="activarItem(item)" v-else-if="item.Rfc == 'False'" >
                      block
                  </v-icon>
                  <v-icon small @click="activarItem(item)" v-else >
                      block
                  </v-icon>-->

                </template>

                <template v-slot:top>
                  <v-toolbar flat color="white">
                      <v-toolbar-title>Respuestas de Aclaraciones</v-toolbar-title>
                      <v-divider
                      class="mx-4"
                      inset
                      vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field  v-model="search" append-icon="mdi-magnify"  label="Busqueda" single-line  hide-details></v-text-field>
                          
                      <v-spacer></v-spacer>
                      <v-dialog v-model="dialog" max-width="90%">
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container v-if="editedIndex<=0">                                  
                                  <v-row>                                                                      
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea name="input-7-1" label="Descripción" v-model="defaultItem.Descripcion" auto-grow
                                             rows="1" row-height="15" ></v-textarea>
                                    </v-col>
                                  </v-row>

                                  <v-row>                                    
                                    <v-col cols="12" sm="6" md="6">
                                     <v-file-input v-model="defaultItem.Archivo" label="Archivo" ref="Archivo" required></v-file-input>
                                    </v-col>
                                  </v-row> 
                                                              
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12" v-show="valida">
                                        <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                                        </div>
                                    </v-col>
                                  </v-row>

                              </v-container>
                              <v-container v-else>
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field filled readonly v-model="editedItem.Nombre" label="Nombre"></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field filled readonly v-model="editedItem.RFC" label="R.F.C"></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field filled readonly v-model="editedItem.Empresa" label="Empresa"></v-text-field>
                                    </v-col>
                                  </v-row>
                                    <v-row>
                                     <v-col cols="12" sm="12" md="6">
                                        <v-text-field filled readonly v-model="editedItem.Email" label="Correo Electrónico"></v-text-field>
                                     </v-col>
                                     <v-col cols="12" sm="12" md="6">
                                        <v-text-field filled readonly v-model="editedItem.Telefono" label="Teléfono"></v-text-field>
                                     </v-col>
                                  </v-row> 

                                  <v-row>
                                    

                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea filled readonly name="input-7-1" label="Descripción" v-model="editedItem.Descripcion" auto-grow
                                             rows="1" row-height="15" ></v-textarea>
                                    </v-col>
                                  </v-row>

                                  <!--<v-row>                                    
                                    <v-col cols="12" sm="6" md="6">
                                     <v-file-input v-model="editedItem.Archivo" label="Archivo" ref="Archivo" required></v-file-input>
                                    </v-col>
                                  </v-row> 

                                  <v-row>                                                                      
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea name="input-7-1" label="Respuesta" v-model="editedItem.Respuesta" auto-grow
                                             rows="1" row-height="15" ></v-textarea>
                                    </v-col>
                                  </v-row>
                                  -->

                                  <v-row>
                                    <v-col cols="12" sm="5" md="5">
                                        <v-text-field filled readonly v-model="editedItem.NombreAnexo" label="Archivo"></v-text-field>
                                     </v-col>

                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                          <v-btn icon v-bind="attrs" v-on="on" @click="descargarArchivo(editedItem.ConcursoAclaracionId)">
                                            <v-icon x-large color="blue">mdi-cloud-download</v-icon>
                                          </v-btn>
                                      </template>
                                      <span>Descargar Archivo</span>
                                    </v-tooltip>                                                                                                                                      
                                  </v-row>  

                                  <v-divider :inset="inset"></v-divider>

                                  <v-subheader>Respuesta SIASI</v-subheader>
                                                                                               
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea name="input-7-1" label="Respuesta" v-model="editedItem.Respuesta" auto-grow
                                             rows="1" row-height="15" ></v-textarea>
                                    </v-col>                                                                    
                                  </v-row>

                                  <v-row>

                                    <v-col cols="12" sm="6" md="6">
                                     <v-file-input v-model="editedItem.ArchivoRespuesta" label="Archivo Modificar" ref="Archivo Modificar" required></v-file-input>
                                    </v-col>

                                    <v-col cols="12" sm="5" md="5">
                                     <v-text-field filled readonly v-model="editedItem.RespuestaNombreAnexo" label="Archivo"></v-text-field>                                     
                                    </v-col>

                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                          <v-btn icon v-bind="attrs" v-on="on" @click="descargarArchivoRespuesta(editedItem.ConcursoAclaracionId)">
                                            <v-icon x-large color="blue">mdi-cloud-download</v-icon>
                                          </v-btn>
                                      </template>
                                      <span>Descargar Archivo</span>
                                    </v-tooltip>   


                                                                                                                                                                        
                                  </v-row> 

                                  <v-row>
                                    <v-col cols="12" sm="12" md="12" v-show="valida">
                                        <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">

                                        </div>
                                    </v-col>
                                  </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                            <v-btn color="blue darken-1" text @click="guardar">Gurdar</v-btn>
                            </v-card-actions>
                        </v-card>
                      </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                <v-btn color="primary" @click="listar">Actualizar</v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios'
  export default {
    data: () => ({

      inset: false,
      items: [
        'default',
        'vertical',
      ],
      variant: 'default',

      listadoCompontente1: [],
      dialog: false,
      search: '',
      headers: [
        
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Empresa', value: 'empresa' },
        { text: 'Descripcion', value: 'descripcion' },
        { text: 'Respuesta', value: 'respuesta' },
      ],
      editedIndex: -1,
      editedItem: {
        ConcursoAclaracionId : '',
        ConcursoId : '',
        Nombre : '',
        RFC : '',
        Empresa : '',
        Email : '',
        Telefono : '',
        Descripcion : '',
        DescripcionAnexos : '',
        NombreAnexo : '',
        Archivo : null,
        ArchivoRespuesta: null,
        Respuesta : '',
        RespuestaAnexos : '',
        RespuestaNombreAnexo : '',
        FechaRespuesta : '',
        IdUsuarioRespuesta : ''
      },
      defaultItem: {
        ConcursoId : 2,
        Nombre : '',
        RFC : '',
        Empresa : '',
        Email : '',
        Telefono : '',
        Descripcion : '',
        DescripcionAnexos : '',
        NombreAnexo : '',
        Archivo : null,
        ArchivoRespuesta: null,
        Respuesta : '',
        RespuestaAnexos : '',
        RespuestaNombreAnexo : '',
        FechaRespuesta : '',
        IdUsuarioRespuesta : ''
      },
      valida: 0,
      validaMensaje:[]
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Aclaración' : 'Solicitud'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.listar();
    },

    methods: {

      cargarArhivo(){
        let me = this;
        console.log(me);
        console.log(me.defaultItem.Archivo);
        me.defaultItem.Archivo = this.$refs.Archivo.files[0];
        console.log(me.defaultItem.Archivo);
      },
      cargarArhivoRespuesta(){
        let me = this;
        console.log(me);
        console.log(me.defaultItem.ArchivoRespuesta);
        me.defaultItem.Archivo = this.$refs.ArchivoRespuesta.files[0];
        console.log(me.defaultItem.ArchivoRespuesta);
      },
      ActualizarArhivoRespuesta(){
        let me = this;
        console.log(me);
        console.log(me.editedItem.ArchivoRespuesta);
        me.editedItem.Archivo = this.$refs.ArchivoRespuesta.files[0];
        console.log(me.editedItem.ArchivoRespuesta);
      },

      listar(){
        //Enlistar registos
        //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

        let me = this;
        let header = {"Authorization":"Bearer " + this.$store.state.token};
        let configuration={headers:header};
        axios.get('api/aclaraciones/listar',configuration).then(function(response){
            console.log(response.data);
          me.listadoCompontente1 = response.data;
        }).catch(function(error){
          console.log(error);
        });
      },

      editItem (item) {
        //Editar registro

        //Estos son los campos del registro que se van a editar y por default tiene los valores originales
        var selectedItem = Object.assign({}, item)

        this.editedItem.ConcursoAclaracionId = selectedItem.concursoAclaracionId;
        this.editedItem.ConcursoId = selectedItem.concursoId;
        this.editedItem.Nombre = selectedItem.nombre;
        this.editedItem.RFC = selectedItem.rfc;
        this.editedItem.Empresa = selectedItem.empresa;
        this.editedItem.Email = selectedItem.email;
        this.editedItem.Telefono = selectedItem.telefono;
        this.editedItem.Descripcion = selectedItem.descripcion;
        this.editedItem.DescripcionAnexos = selectedItem.descripcionAnexos;
        this.editedItem.Archivo = selectedItem.descripcionAnexos;
        this.editedItem.ArchivoRespuesta = selectedItem.respuestaAnexos;
        this.editedItem.NombreAnexo = selectedItem.nombreAnexo;
        this.editedItem.Respuesta = selectedItem.respuesta;
        this.editedItem.RespuestaAnexos = selectedItem.respuestaAnexos;
        this.editedItem.RespuestaNombreAnexo = selectedItem.respuestaNombreAnexo;
        this.editedItem.FechaRespuesta = selectedItem.fechaRespuesta;
        this.editedItem.IdUsuarioRespuesta = selectedItem.idUsuarioRespuesta;

        console.log(this.editedItem);


        //Estas variables sirven para mostrar la ventana modal (Dialog) e indicar que se va a editar un registro
        this.editedIndex = 1;
        this.dialog = true;
      },

      activarItem (item) {
        //Desactivar registro

        let me = this;
        
        //Este es el item que se va a desactivar
        var selectedItem = Object.assign({}, item)

        if(confirm('¿Estás seguro de querer activar este registro?' + '\n' + selectedItem.name)){
          console.log("Si");
          axios.put('api/contratistas/activar/' + selectedItem.id).then(function(response){
            me.listar();
            me.limpiar();
          }).catch(function(error){
            console.log(error);
          });
        }
        else{
          console.log("No");
        }        
                
      },

      desactivarItem (item) {
        //Desactivar registro
        
        let me = this;

        //Este es el item que se va a desactivar
        var selectedItem = Object.assign({}, item)

        if(confirm('¿Estás seguro de querer desactivar este registro?' + '\n' + selectedItem.name)){
          console.log("Si");
          axios.put('api/contratistas/desactivar/' + selectedItem.id).then(function(response){
            me.listar();
            me.limpiar();
          }).catch(function(error){
            console.log(error);
          });
        }
        else{
          console.log("No");
        }        
                
      },

      deleteItem (item) {
        //Eliminar registro
        // const index = this.desserts.indexOf(item)
        // confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
      },

      close () {
        //Cierra el Dialog (Ventana Modal)

        this.limpiar();
        this.dialog = false;
      },

      descargarArchivo (id) {

        let me = this;        
        
        if (this.editedIndex > -1) 
        {

          //Funciona 
          axios({
          url: 'api/aclaraciones/DescargarArchivo/' + this.editedItem.ConcursoAclaracionId, 
          method: 'GET',
          responseType: 'blob',
          }).then((response) => {
                      
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
  
          fileLink.href = fileURL;
          // fileLink.setAttribute('download', 'file.pdf');
          fileLink.setAttribute('download', me.editedItem.NombreAnexo);
          document.body.appendChild(fileLink);
   
          fileLink.click();
          
          }).catch(function(error){
              var errormostrar = 'No se puede descargar ningun archivo';
                         
              console.log(errormostrar);
              // me.validaMensaje.push(error.response.request.responseText.headers);

              me.validaMensaje.push(errormostrar);            
          });             

        }
        
      },

      descargarArchivoRespuesta (id) {

        let me = this;  
        
        if (this.editedIndex > -1) 
        {

          //Funciona 
          axios({
          url: 'api/aclaraciones/DescargarArchivoRespuesta/' + this.editedItem.ConcursoAclaracionId, 
          method: 'GET',
          responseType: 'blob',
          }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
  
          fileLink.href = fileURL;
          // fileLink.setAttribute('download', 'file.pdf');
          fileLink.setAttribute('download', me.editedItem.RespuestaNombreAnexo);
          document.body.appendChild(fileLink);
   
          fileLink.click();
          
          }).catch(function(error){
              var errormostrar = 'No se puede descargar ningun archivo';
              
           


              console.log(errormostrar);
              // me.validaMensaje.push(error.response.request.responseText.headers);

              me.validaMensaje.push(errormostrar);

            

          });                              

        }
        
      },

      guardar () {
        //Crea un nuevo registro o actualiza alguno existente dependiendo de la variable "editedIndex" y de si no existen errores

        if (this.editedIndex > -1) 
        {
          //Actualizar registro

          if(this.validarActualizacionRegistro()){
            return;
          }
        let me = this;
        let formData = new FormData();
        console.log(formData);
        formData.append('ConcursoAclaracionId', me.editedItem.ConcursoAclaracionId);
        formData.append('Respuesta', me.editedItem.Respuesta);
        formData.append('ArchivoRespuesta', me.editedItem.ArchivoRespuesta);
        console.log(formData);

        axios.put(
          'api/aclaraciones/actualizarRespuesta',
          formData,
           {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
        .then(function(response){
            me.close();
            me.listar();
            me.limpiar();
          }).catch(function(error){
            me.validaMensaje.push(error.response);
            console.log(error.response);
          });

        } 
        else 
        {
          //Crear registro

          //Esto se tiene que quitar que cambiar cuando tengamos los datos del usuario que esta creando la aclaracion
          this.defaultItem.Nombre = 'Prueba Nombre';
          this.defaultItem.Empresa = 'Prueba Empresa';
          this.defaultItem.RFC = 'Prueba Rfc';
          this.defaultItem.Email = 'Prueba Email';
          this.defaultItem.Telefono = 'Prueba Telefono';

          if(this.validarCreacionRegistro()){
            return;
          }

        let me = this;
        let formData = new FormData();
        
        console.log(formData);
        //formData.append('archivo1', this.avatarFile, this.avatarFile.name);
        formData.append('ConcursoId', me.defaultItem.ConcursoId);
         formData.append('Nombre', me.defaultItem.Nombre);
         formData.append('RFC', me.defaultItem.RFC);
         formData.append('Empresa', me.defaultItem.Empresa);
        formData.append('Email', me.defaultItem.Email);
        formData.append('Telefono', me.defaultItem.Telefono);
        formData.append('Descripcion', me.defaultItem.Descripcion);
        formData.append('DescripcionAnexos', me.defaultItem.DescripcionAnexos);
        formData.append('NombreAnexo', me.defaultItem.NombreAnexo);
        formData.append('Archivo', me.defaultItem.Archivo);
        console.log(formData);


        axios.post(
          'api/aclaraciones/crear',
          formData,
           {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
        .then(function(response){
            me.close();
            me.listar();
            me.limpiar();
          }).catch(function(error){
              
            console.log(error.response.request.responseText);
              me.validaMensaje.push(error.response.request.responseText.headers);
          });

        }
      },

      validarCreacionRegistro(){
        this.valida=0;
        this.validaMensaje=[];

        if(!this.defaultItem.Nombre){
          this.validaMensaje.push("El campo Nombre no puede estar vacío.");
        }
        if(!this.defaultItem.Empresa){
          this.validaMensaje.push("El campo Empresa no puede estar vacío.");
        }
        if(!this.defaultItem.RFC){
          this.validaMensaje.push("El campo R.F.C no puede estar vacío.");
        }
        if(!this.defaultItem.Email){
          this.validaMensaje.push("El campo Correo Electrónico Legal no puede estar vacío.");
        }
        if(!this.defaultItem.Telefono){
          this.validaMensaje.push("El campo Telefono no puede estar vacío.");
        }
        if(!this.defaultItem.Descripcion){
          this.validaMensaje.push("El campo Descripcion no puede estar vacío.");
        }       

        if(this.validaMensaje.length){
          this.valida = 1;
        }

        return this.valida;
      },

      validarActualizacionRegistro(){
        this.valida=0;
        this.validaMensaje=[];

        console.log(this.editedItem.Id);

        if(!this.editedItem.Respuesta){
          this.validaMensaje.push("El campo Respuesta no puede estar vacío.");
        }        

        if(this.validaMensaje.length){
          this.valida = 1;
        }

        return this.valida;
      },


      limpiar(){
        this.editedIndex = -1;
        this.valida = 0;
        this.validaMensaje = [];






        this.defaultItem = {
        ConcursoId : 2,
        Nombre : '',
        RFC : '',
        Empresa : '',
        Email : '',
        Telefono : '',
        Descripcion : '',
        DescripcionAnexos : '',
        Archivo : null,
        Respuesta : '',
        FechaRespuesta : '',
        IdUsuarioRespuesta : ''
        };
      }
    },
  }
</script>