<template>
<v-row>
    <v-card dark>
        <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/FondoNL2.jpg" class="grey lighten-2" height="520" width="100%">
            <v-row class="fill-height pa-3" >
                <v-col cols="12" md="8" offset="1" class="d-none d-md-block mt-3">
                        <br>
                        <h1 class="display-2 font-weight-bold"  style="margin-top:300px ">
                            Obras en proceso
                        </h1>

                        <br>
                    </v-col>
                    <v-col cols="10" offset="1" class="d-block d-md-none mt-5"  >
                        <br>
                        <h1 class="headline font-weight-bold" style="margin-top:300px ">
                            Obras en proceso
                        </h1>
                      
                        <br>
                    </v-col>
                    
            </v-row>
        </v-img>
    </v-card>
    <v-col v-for="obra in obras" :key="obra.concursoId" cols="12">
        <v-card class="container">
            <v-row class="d-flex">
                <v-col cols="12" lg="3">
                    <v-avatar class="ma-3" height="300" width="280" tile>
                        <v-img v-if="obra.urlImagen" :src="'https://smpu.nl.gob.mx'+ obra.urlImagen"></v-img>
                        <v-img v-if="!obra.urlImagen" src='https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/LogoEstado.svg'></v-img>
                    </v-avatar>
                </v-col>
                <v-col cols="12" lg="9">
                    <v-card-title style="word-break:normal" v-text="obra.tituloConcurso">
                    </v-card-title>
                    <v-subheader v-if="obra.montoContrato">
                        {{"$"+ new Intl.NumberFormat("en-US").format(obra.montoContrato)}}
                    </v-subheader>
                    <v-subheader v-if="obra.municipio">
                        {{obra.municipio}}
                    </v-subheader>
                    <v-subheader v-if="obra.numeroContrato">
                        No. Contrato: {{obra.numeroContrato}}
                    </v-subheader>
                    <v-subheader v-if="obra.numeroConcurso">
                        No. Concurso: {{obra.numeroConcurso}}
                    </v-subheader>
                    <v-card-text v-text="obra.descripcion">

                    </v-card-text>
                    <v-btn v-if="obra.urlPia" :href="obra.urlPia" target="_blank" class="primary">ver mas</v-btn>
                </v-col>
            </v-row>

        </v-card>
    </v-col>
</v-row>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({
        Noticia: null,
        obras: [],

    }),
    created() {
        this.muestraNoticia();
    },
    methods: {
        muestraNoticia() {
            console.log("cargaNoticia");
            let me = this;
            // let header = {"Authorization":"Bearer " + this.$store.state.token};
            // let configuration={headers:header};
            axios.get('/api/contratos/listarobrasproceso').then(function (response) {

                me.obras = response.data;
                console.log(me.obras);
                return true;
            }).catch(function (error) {
                console.log(error);
                return false;
            });
        },

    },
}
</script>
