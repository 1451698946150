<template>
    <div id="divInicio">
        <section>
            <v-card dark>
                <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/FondoNL2.jpg" class="grey lighten-2" height="520" width="100%">
                    <v-row class="fill-height pa-3" align="center">
                        <v-col cols="12" md="8" offset="1" class="d-none d-md-block mt-3">
                            <br>
                            <h1 class="display-2 font-weight-bold"  style="margin-top:300px ">
                                Avisos de Privacidad
                            </h1>
                            <h4 class="display-1 font-weight-light">
                                Haciendo frente con infraestructura
                            </h4>
                            <br>
                        </v-col>
                        <v-col cols="10" offset="1" class="d-block d-md-none mt-5" >
                            <br>
                            <h1 class="headline font-weight-bold"  style="margin-top:300px ">
                                Avisos de Privacidad
                            </h1>
                            <h4 class="headline font-weight-light" >
                                Haciendo frente con infraestructura
                            </h4>
                            <br>
                        </v-col>
                    </v-row>
                   
                </v-img>
            </v-card>
        </section>
        
        <v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para Concursos de Obra Pública, Invitación Restringida, Adjudicación Directa y la Contratación.docx'>Aviso de Privacidad Integral para Concursos de Obra Pública, Invitación Restringida, Adjudicación Directa y la Contratación </a></span>
        </v-card>
        <v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para el Estudio de Impacto Urbano Regional.docx'>Aviso de Privacidad Integral para el Estudio de Impacto Urbano Regional </a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para el Registro Estatal de Contratistas de Obra Pública.docx'>Aviso de Privacidad Integral para el Registro Estatal de Contratistas de Obra Pública </a></span>
        </v-card>
<!-- <v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para el Tratamiento de Datos Personales en la Atención al Usuario del Servicio de Transporte Público.docx'>Aviso de Privacidad Integral para el Tratamiento de Datos Personales en la Atención al Usuario del Servicio de Transporte Público</a></span>
        </v-card> -->
<!-- <v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para el Usuario de Transporte de Carga.docx'>Aviso de Privacidad Integral para el Usuario de Transporte de Carga</a></span>
        </v-card> -->
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para Expedición de Copia Simple o Certificada de Expedientes, Planos yo Acuerdos.docx'>Aviso de Privacidad Integral para Expedición de Copia Simple o Certificada de Expedientes, Planos yo Acuerdos</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para Expedientes del Personal.docx'>Aviso de Privacidad Integral para Expedientes del Personal</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para Gestiones de Anticipo y Pago de Estimaciones Financieras relativas al Avance de Obras Públicas.docx'>Aviso de Privacidad Integral para Gestiones de Anticipo y Pago de Estimaciones Financieras relativas al Avance de Obras Públicas</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para la Atención al Usuario del Servicio de Certificado de Laboratorio.docx'>Aviso de Privacidad Integral para la Atención al Usuario del Servicio de Certificado de Laboratorio</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para la Atención al Usuario del Servicio de Certificado Profesionales Responsables.docx'>Aviso de Privacidad Integral para la Atención al Usuario del Servicio de Certificado Profesionales Responsables</a></span>
        </v-card>
<!-- <v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para la Atención al Usuario del Servicio de Transporte Urbano del Estado.docx'>Aviso de Privacidad Integral para la Atención al Usuario del Servicio de Transporte Urbano del Estado</a></span>
        </v-card> -->
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para la Atención al Usuario del Trámite de Inscripción a Eventos de Capacitación para Profesionales  en Materia de Pavimentos.docx'>Aviso de Privacidad Integral para la Atención al Usuario del Trámite de Inscripción a Eventos de Capacitación para Profesionales  en Materia de Pavimentos</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para la Atención al Usuario del Trámite de Inscripción a Eventos de Capacitación para Profesionales  en Materia de.docx'>Aviso de Privacidad Integral para la Atención al Usuario del Trámite de Inscripción a Eventos de Capacitación para Profesionales  en Materia de</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para Registro al Padrón de Profesionistas en Gestión Territorial.docx'>Aviso de Privacidad Integral para Registro al Padrón de Profesionistas en Gestión Territorial</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para Solicitudes de Acceso a la Información.docx'>Aviso de Privacidad Integral para Solicitudes de Acceso a la Información</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Integral para Solicitudes de Derechos ARCO.docx'>Aviso de Privacidad Integral para Solicitudes de Derechos ARCO</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad para Expedientes de Personal de la Secretaría de Movilidad y Planeación Urbana del Estado.docx'>Aviso de Privacidad para Expedientes de Personal de la Secretaría de Movilidad y Planeación Urbana del Estado
 </a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado Expedientes de Personal.docx'>Aviso de Privacidad Simplificado Expedientes de Personal
 </a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para el Estudio de Impacto Urbano Regional.docx'>Aviso de Privacidad Simplificado para el Estudio de Impacto Urbano Regional
 </a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para el Registro Estatal de Contratistas de Obra Pública.docx'>Aviso de Privacidad Simplificado para el Registro Estatal de Contratistas de Obra Pública</a></span>
        </v-card>
<!-- <v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para el Tratamiento de Datos Personales en la Atencion al Usuario del Servicio de Transporte Público.docx'>Aviso de Privacidad Simplificado para el Tratamiento de Datos Personales en la Atencion al Usuario del Servicio de Transporte Público</a></span>
        </v-card> -->
<!-- <v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para el Usuario de Transporte de Carga.docx'>Aviso de Privacidad Simplificado para el Usuario de Transporte de Carga</a></span>
        </v-card> -->
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para Expedición de Copia Simple o Certificada de Expedientes, Planos yo Acuerdos.docx'>Aviso de Privacidad Simplificado para Expedición de Copia Simple o Certificada de Expedientes, Planos yo Acuerdos</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para Gestiones de Anticipo y Pago de Estimaciones Financieras relativas al Avance de Obras Públicas.docx'>Aviso de Privacidad Simplificado para Gestiones de Anticipo y Pago de Estimaciones Financieras relativas al Avance de Obras Públicas</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para la Atención al Usuario del Servicio de Certificado de Laboratorio.docx'>Aviso de Privacidad Simplificado para la Atención al Usuario del Servicio de Certificado de Laboratorio</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para la Atención al Usuario del Servicio de Certificado Profesionales Responsables.docx'>Aviso de Privacidad Simplificado para la Atención al Usuario del Servicio de Certificado Profesionales Responsables</a></span>
        </v-card>
<!-- <v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para la Atención al Usuario del Servicio de Transporte Urbano del Estado.docx'>Aviso de Privacidad Simplificado para la Atención al Usuario del Servicio de Transporte Urbano del Estado</a></span>
        </v-card> -->
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para la Atención al Usuario del Trámite de Inscripción a Eventos de Capacitación para Profesionales  en Materi.docx'>Aviso de Privacidad Simplificado para la Atención al Usuario del Trámite de Inscripción a Eventos de Capacitación para Profesionales  en Materi</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para Registro al Padrón de Profesionistas en Gestión Territorial.docx'>Aviso de Privacidad Simplificado para Registro al Padrón de Profesionistas en Gestión Territorial</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado para Solicitudes de Derechos ARCO.docx'>Aviso de Privacidad Simplificado para Solicitudes de Derechos ARCO</a></span>
        </v-card>
<v-card class='container'> <span> <a target='_blank' href='https://smpu.nl.gob.mx/acceso/AvisosPrivacidad/Aviso de Privacidad Simplificado Solicitudes de Acceso a la Información.docx'>Aviso de Privacidad Simplificado Solicitudes de Acceso a la Información</a></span>
        </v-card>

    </div>
    </template>
    
    <script>
    export default {
        data() {
            return {
            }
        },
        compnents: {
    
        },
        methods: {
        }
    }
    </script>
    
    <style>
    .v-image__image {
        transition: .3s linear;
    }
    </style>
    