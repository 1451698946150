<template>
<div id="divInicio" >
  <!-- inicio -->
  <section class="deep-purple accent-4" height="400" width="80%">
      <v-card dark>
    <v-img
      src="https://smpu.nl.gob.mx/acceso/assets/websi/índice-resp.jfif"
      class="grey lighten-2"
      height="400"
      width="100%"
    >
      <v-row
        class="fill-height pa-3"
        align="center"
        
      >
        <v-col
          cols="12"
          md="7"
          offset-md="5"
        >
          <h1 class="display-3 font-weight-light">
            Recibe notificación
          </h1>

          <div class="subheading pl-2 mb-4">
            sobre concursos, programas, noticias y avance de las obras
          </div>

          <v-btn
            color="primary"
            depressed
          >
            Subscribe
          </v-btn>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
  </section>
  <section class="container" >
    <v-row>
      <v-col cols="12" sm="12" lg="8" >
        <v-carousel>
          <v-carousel-item
            v-for="(item,i) in listadoNoticias"
            :key="i"
            :src="'https://smpu.nl.gob.mx'+item.url"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
          <v-row
            class="fill-height"
            align="end"
            justify="first"
            
            style="padding:1rem; color:white; text-transform:uppercase;background-image: linear-gradient(#00000040,#00000040);"
            
          >
            <div class="display-1" style="" >{{item.tituloNoticia}}</div>
          </v-row>
          </v-carousel-item>
        </v-carousel>

      </v-col> 
      <v-col>
        <v-row  style="padding:1rem;">

          <v-dialog
        v-model="dialog"
        width="1200"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
          large 
          color="primary"
          v-bind="attrs"
          v-on="on"
          >Próximos eventos 
            <v-icon x-large style="rotate:-15deg" >event</v-icon>
          </v-btn>
        </template>
  
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Privacy Policy
          </v-card-title>
          <v-card-body>
            <v-data-table
              :headers="headers"
              :items="listadoEventos"
              :items-per-page="5"
              class="elevation-1"
            ></v-data-table>

          </v-card-body>
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
        </v-row>
        <!-- <v-row  style="padding:1rem;">
          <a href="/COVID" > 
          <img src="https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/covid19_160087.jpg" class="AccesoTema" alt="COVID">
          </a>
        </v-row> -->
        <!-- <v-row style="padding:1rem;">
          <a href="/BIM" > 
          <img src="https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/bim.jpg" class="AccesoTema" alt="BIM">
          </a>
        </v-row> -->
        <!-- <v-row style="padding:1rem;">
          <a href="/Alianza Real" > 
          <img src="https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/alianza.jpg" class="AccesoTema" alt="Alianza Real">
          </a>
        </v-row> -->
        <v-row style="padding:1rem;">
          <a href="https://smpu.nl.gob.mx/transparencia/" > 
          <img src="../../src/assets/Imagenes/infraestructura.jpg" class="AccesoTema" alt="Infraestructura Abierta">
          </a>
        </v-row>
      </v-col>
    </v-row>
    <!-- <h1>Indicadores</h1>
    <v-row>
      <v-col>
          <v-card
            class="mt-4"
            max-width="400"
          >
            <v-sheet
              style="top: -14px; right:-14px; position: relative;"
              color="cyan"
              elevation="12"
              max-width="calc(100% - 32px)"
            >
              <v-sparkline
                :labels="labels"
                :value="value"
                color="white"
                line-width="2"
                padding="16"
              ></v-sparkline>
            </v-sheet>
          </v-card>
      </v-col>

      
    </v-row> -->

    

  </section>
</div>

</template>
<script>

import axios from 'axios'
  export default {

    props: {
      // size: {
      //   type: Number,
      //   required: true,
      // },
    },

    data: () => (
      {listadoNoticias: [],
      headers:[
        {text:"Numero de Concurso", value:"concursoId"},
        {text:"Nombre de Concurso", value:"tituloConcurso"},
        {text:"Evento", value:"nombre"},
        {text:"Fecha", value:"fecha"},
      ],
      listadoEventos:[],

      cards: [
        { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 6 },
        { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
        { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
        { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 6 }
      ],        
      labels: [
      '12am',
      '3am',
      '6am',
      '9am',
      '12pm',
      '3pm',
      '6pm',
      '9pm',
      ],
      value: [
        200,
        675,
        410,
        390,
        10,
        460,
        250,
        240,
      ],
      overlay : false,
      valid: true,
      name: '',
      select: null,      
      dialog: false
    }),
    created () {
      this.listarNoticias();
      this.listarEventos();
    },
    methods: {      
      
      mostrarOverlay(){

        console.log(this.$overlay);
    
        this.$overlay = !this.$app.overlay ;
        setTimeout(() => { this.$app.overlay = !this.$app.overlay ; }, 5000);
        
      },
      listarNoticias(){
        console.log("Listar");
        let me = this;
        // let header = {"Authorization":"Bearer " + this.$store.state.token};
        // let configuration={headers:header};
        axios.get('/listarNoticias').then(function(response){
            console.log("Concursos:");
            console.log(response.data);
          me.listadoNoticias = response.data.reverse();
          return true;
        }).catch(function(error){
          console.log(error);
          return false;
        });
      },
      listarEventos(){
        console.log("Listar");
        let me = this;
        // let header = {"Authorization":"Bearer " + this.$store.state.token};
        // let configuration={headers:header};
        axios.get('/listarEventos').then(function(response){
            console.log("Eventos:");
            console.log(response.data);
          me.listadoEventos = response.data.reverse();
          return true;
        }).catch(function(error){
          console.log(error);
          return false;
        });
      },
    },
  }
</script>

<style>
.v-image__image {
  transition: .3s linear;
}
</style>