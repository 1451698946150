
<template>
  <CargarMapa
    :configMapa="configMapa"
    apiKey="AIzaSyDTtdttxL1G8XGravnTXGV5lnAfoFlgjhc"
  >
 
    <template slot-scope="{ google, map }">
      <Marcadores
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
      />
    </template>
 
  </CargarMapa>
 
</template>
 
<script>

    import axios from 'axios';
    import CargarMapa from "./CargarMapa";
    import Marcadores from "./Marcadores";
    import { configMapa } from "@/constants/configMapa";
 
  export default {
    components: {
      CargarMapa,
      Marcadores
    },
 
    data() {
      return {
        markers: [
        ],
        contador:0
      };
    },
 
    computed: {
      configMapa() {
        return {
          ...configMapa,
          center: this.mapCenter
        };
      },
 
      mapCenter() {
        return { lat: 25.6757253, lng: -100.2944108 };
      },

    },
    
    created () {
      this.listarMarcadores();
    },
    
    methods:{

      listarMarcadores(){
        let me = this;
        me.contador;
        console.log("Marcadores Iniciales:");
        console.log(me.markers);

        me.markers= [

        ];

        // let header = {"Authorization":"Bearer " + this.$store.state.token};
        // let configuration={headers:header};
        //axios.get('api/contratistas/listar',configuration).then(function(response){
        axios.get('api/unidadesdesalud/listar').then(function(response){
        console.log("Axios Mapa:");
            console.log(response.data);
            response.data.forEach(function(item) {



                me.contador += 1;
                me.markers.push({
                    id: me.contador,
                    position: { lat: item.latitud, lng: item.longitud },
                    title: item.nombreUnidad,
                    clue: item.clue,
                    domicilio: item.domicilio,
                    latitud: item.latitud,
                    longitud: item.longitud
                });
            });
        }).catch(function(error){
          console.log(error);
        });
      },
    }
  }
  
</script>