var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"align-start":""}},[_c('v-flex',[_c('h1',[_vm._v("Licitantes")]),_c('v-spacer'),(_vm.defaultItem.TipoConcurso =='Licitación Federal')?_c('v-card',{staticClass:"mx-auto",attrs:{"color":"#26c6da","dark":"","max-width":"90%"}},[_c('v-card-text',[_vm._v(" En el "),_c('b',[_vm._v("Lineamiento Quinto del Artículo Segundo del Acuerdo")]),_vm._v(" por el que se emiten diversos Lineamientos en Materia de Adquicisiones, Arrendamientos y Servicio y de Obras Públicas y Servicios Relacionados con las Mismas publicado por la Secretaría de la Función Pública el 9 de Septiembre de 2010 en el Diario Oficial de la Federación, qu establece que sólo procederá a realizarse la evaluación de las propuestas ecónomicas de aquéllas proposiciones cuya propuesta técnica resulte solvente por "),_c('b',[_vm._v(" haber obtenido la puntuación o unidades porcentuales iguales o superiores al mínimo establecido en la convocatoria a licitación.")])])],1):_vm._e(),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.computedheaders,"items":_vm.listadoCompontente1,"search":_vm.search},scopedSlots:_vm._u([{key:"item.propuestaEconomica",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.propuestaEconomica))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.puntosTecnicos + item.puntosEconomicos)+" ")]}},{key:"item.puntosTecnicos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.puntosTecnicos)+" ")]}},{key:"item.puntosEconomicos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.puntosEconomicos)+" ")]}},{key:"item.eliminar",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.desactivarItem(item)}}},[_vm._v(" mdi-trash-can-outline ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.ganador)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":"","color":"yellow"}},[_vm._v("mdi-trophy")])],1):_vm._e(),(item.descalificado)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-cancel")])],1):_vm._e()]}}],null,true)},[(item.ganador)?_c('span',[_vm._v("Licitante Ganador")]):_vm._e(),(item.descalificado)?_c('span',[_vm._v("Licitante descalificado")]):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }