<template>
<div class="container">
<br>
<v-col cols="12" lg="8"  class="d-none d-md-block">
    <br>
    <h1 class="display-2 font-weight-bold">
        {{Noticia.tituloNoticia}}
    </h1>
    <br>
</v-col>
<v-col cols="11"  class="d-block d-md-none">
    <br>
    <h1 class="headline font-weight-bold">
        {{Noticia.tituloNoticia}}
    </h1>
    <br>
</v-col>
<p class="ma-3 display-2">
  
</p>
  <v-card
    class="container"
    
  >
    <v-img max-height="600" contain v-if="!Noticia.video"
      :src="'https://smpu.nl.gob.mx'+ Noticia.imagen"
    ></v-img>

    <video v-if="Noticia.video" width="100%" controls :src="'https://smpu.nl.gob.mx'+ Noticia.video"></video>
   

    <v-card-text>
        {{Noticia.descripcion}}
    </v-card-text>


      <v-spacer></v-spacer>

  </v-card>
  <br>
  </div>
</template>
<script>
import axios from 'axios'
  export default {
    data: () => ({
      Noticia:null,
    }),
    created () {
      this.muestraNoticia();
    },
    methods:{
        muestraNoticia(){
        console.log("cargaNoticia");
        let me = this;

        axios.get('/mostrarNoticias/' + me.$route.params.id ).then(function(response){

          me.Noticia = response.data;
          return true;
        }).catch(function(error){
          console.log(error);
          return false;
        });
      },

    },
  }
</script>