<template>
    <v-layout align-start>
        <v-flex>
            <h1 class="display-3">Licitantes</h1>
            <v-spacer></v-spacer>
            <v-data-table
                :headers="headers"
                :items="listadoCompontente1"
                :search="search"
                class="elevation-1"
            >
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2">
                      mdi-eye
                  </v-icon>

                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios'
  export default {
    data: () => ({

      inset: false,
      items: [
        'default',
        'vertical',
      ],
      variant: 'default',

      listadoCompontente1: [],
      dialog: false,
      search: '',
      headers: [
        
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Empresa', value: 'empresa' },
        { text: 'Email', value: 'email' },
        { text: 'Telefono', value: 'telefono' },
      ],
      editedIndex: -1,
      defaultItem: {
        ConcursoId : '',
        Nombre:'',
        Empresa:'',
        Email:'',
        Telefono:''
      },      
      valida: 0,
      validaMensaje:[]
    }),
    props: {
        concursoid: Number,
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva aclaración' : 'Solicitud'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.listar();
    },

    methods: {
      listar(){
        //Enlistar registos
        //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

        let me = this;
        me.defaultItem.ConcursoId = me.$route.params.concursoid;
        let header = {"Authorization":"Bearer " + this.$store.state.token};
        let configuration={headers:header};
        axios.get('api/concursos/MostrarReplicas/' +  me.defaultItem.ConcursoId).then(function(response){
            console.log(response.data);
          me.listadoCompontente1 = response.data;
        }).catch(function(error){
          console.log(error);
        });
      },

      close () {
        //Cierra el Dialog (Ventana Modal)

        this.limpiar();
        this.dialog = false;
      },


      limpiar(){
        this.editedIndex = -1;
        this.valida = 0;
        this.validaMensaje = [];

        this.defaultItem = {
        ConcursoId : '',
        Nombre:'',
        Empresa:'',
        Email:'',
        Telefono:''
        };

      }
    },
  }
</script>