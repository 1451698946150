<template>
<v-app id="inspire">
    <section height="400" width="80%">
        <v-card dark>
            <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/FondoNL2.jpg" class="grey lighten-2" height="250" width="100%">
                <v-row class="fill-height pa-3" align="center">

                    <v-col cols="12" lg="8" offset-lg="4" class="d-none d-md-block">
                        <br>
                        <h1 class="display-2 font-weight-bold">
                            Alianza Real
                        </h1>
                        <h4 class="display-1 font-italic font-weight-light">
                            Plan estratégico de transformación integral 
                        </h4>
                        <br>
                    </v-col>
                    <v-col cols="11" offset="1" class="d-block  d-md-none">
                        <br>
                        <h1 class="headline font-weight-bold">
                            Alianza Real
                        </h1>
                        <h4 class="headline font-italic font-weight-light">
                            Plan estratégico de transformación integral 
                        </h4>
                        <br>
                    </v-col>
                </v-row>
            </v-img>
        </v-card>

        <div class="ma-3">
            <v-row>
                <v-col cols="12" sm="12" lg="4">
                    <v-card>  
                        <v-card-text>
                            <p class="display-1 mb-1">
                            Plan estratégico de transformación integral Alianza Real
                            </p>
                            <v-divider></v-divider>
                            <br>
                            <p class="headline"> 
                            Es la herramienta para la transformación del entorno físico urbano y social de un territorio específico.
                            </p>
                            <p class="headline"> 
                                Concreta todas las capacidades del Estado, los Vecinos, Organizaciones Civiles, y Empresarios, 
                                transformando el tejido social y urbano de manera radical, buscando niveles sostenibles de desarrollo 
                                económico, social, humano y ambiental garantizando el bienestar y la calidad de vida de todos sus habitantes 
                            </p>
                            <br>
                            <p class="display-1 mb-1">
                            Para cumplir los objetivos de:
                            </p>
                            <v-divider></v-divider>
                            <br>
                            <v-img src="https://smpu.nl.gob.mx/acceso/assets/websi/Peti/Definicion.png">
                            </v-img>
                        </v-card-text>
                    </v-card>
                   
                </v-col>
                <v-col cols="12" sm="12" lg="8">
                    <v-row>
                        <v-col v-for="info in Info" :key="info.titulo" cols="12">
                            <v-card class="container" height="90" @click="modal(info)" >
                                <v-card-text style="word-break:normal; text-align:center"  class="headline pa-1" v-text="info.titulo">
                                </v-card-text>
                            </v-card>
                            <br>
                        </v-col>
                    </v-row>
                     <v-subheader class="headline">Noticias</v-subheader>
                     <v-row>
                        <v-col>
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <br>
                    <v-card >
                    <v-carousel hide-delimiters cycle flat >
                        <v-carousel-item v-for="noticia in Noticias" :key="noticia.noticiaId" flat>
                            <v-card light flat  class="container"  :to="'/noticias/' + noticia.noticiaId ">
                                <v-img v-if="noticia.url" :src="'https://smpu.nl.gob.mx'+ noticia.url"  max-height="410" ></v-img>
                                <v-img v-if="!noticia.url" src='https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/LogoEstado.svg' max-height="410"></v-img>
                                <v-card-title style="word-break:normal" v-text="noticia.tituloNoticia">
                                </v-card-title>
                            </v-card>
                        </v-carousel-item>
                    </v-carousel>
                    </v-card>
                </v-col>
            </v-row>
            
            <br>
            <br>
            <v-subheader class="headline">Obras</v-subheader>
            <v-row>
                <v-col>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-for="peti in Petis" :key="peti.titulo" cols="12" sm="12" lg="4">
                    <v-card class="container" height="350" @click="modal(peti)" >
                        <v-img v-if="!peti.url" src='https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/PETI.png' contain height="200"></v-img>
                            <v-card-title style="word-break:normal" class="headline" v-text="peti.titulo">
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
            <br>
            <br>
            <v-dialog
                v-model="dialog"
                max-width="1500px"
            >
                <v-card>
                <v-card-text>
                    <v-img v-if="imagenPeti" :src="'https://smpu.nl.gob.mx/acceso/assets/websi/Peti/'+ imagenPeti" height="auto"></v-img>
                    <v-img v-if="!imagenPeti" src='https://smpu.nl.gob.mx/acceso/assets/websi/Imagenes/LogoEstado.svg' height="auto"></v-img>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                    color="primary"
                    outlined
                    @click="dialog = false"
                    >
                    Cerrar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

    </section>

</v-app>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({
        Noticias: null,
        dialog:false,
        obras: [],
        imagenPeti:'',
        Info:[
            {titulo:'Ubicación', img:'Ubicacion.png'},
            {titulo:'Estrategia', img:'Estrategia.png'},
            {titulo:'Observatorio de seguridad', img:'Observatorio.png'},
            {titulo:'Acciones integrales a Realizar', img:'Acciones.png'},
        ],
        Petis:[
            {titulo:'Parque Constitución', img:'ParqueConstitucion.png'},
            {titulo:'UNEME Escobedo', img:'UNEME.png'},
            {titulo:'Centro polideportivo Alianza Real', img:'Polideportivo.png'},
            {titulo:'Comedor comunitario barrio Quintana Roo', img:'ComedorBarrio.png'},
            {titulo:'Esfera cultural el carmen', img:'EsferaCarmen.png'},
            {titulo:'Pavimentación en diversas calles de la Col. Alianza Real', img:'Pavimentacion.png'},
            {titulo:'Rehabilitación de alumbrado publico en Col. Alianza Real', img:'Rehabilitacion.png'},
            {titulo:'Nodo del saber', img:'NodoSaber.png'},
            {titulo:'Nodo de seguridad', img:'NodoSeguridad.png'},
            {titulo:'Construccion de alberca semiolimpica y chapoteadero', img:'Semiolimpica.png'},
            {titulo:'Comedor comunitario Alianza Real', img:'ComedorComunitario.png'},
            

        ]

    }),
    created() {
        this.muestraNoticia();
    },
    methods: {
        modal(info){
            let me = this;
            me.imagenPeti = info.img;
            me.dialog = true;
            tit
        },
        muestraNoticia() {
            console.log("cargaNoticia");
            let me = this;
            // let header = {"Authorization":"Bearer " + this.$store.state.token};
            // let configuration={headers:header};
            axios.get('/ListarNoticiasTema').then(function (response) {

                me.Noticias = response.data;
                console.log(me.Noticias);
                return true;
            }).catch(function (error) {
                console.log(error);
                return false;
            });
        },

    },
}
</script>
