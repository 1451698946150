import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import "./plugins/vuetify-money.js";

//Prueba de agregar mapa de google
import VueGeolocation from 'vue-browser-geolocation'
import * as VueGoogleMaps from 'vue2-google-maps'

import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false
Vue.component('apexchart', VueApexCharts)
//Prueba de agregar mapa de google
Vue.use(VueGeolocation)
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyAVSVsnXHfmBLWXFTb6BIkfgRLy8pqlt6k'
    //key correcta de siasi
    key: 'AIzaSyDTtdttxL1G8XGravnTXGV5lnAfoFlgjhc'    
  }
})

//----------------------------------------------------------
// Local:
// axios.defaults.baseURL = 'https://localhost:44325/'
//----------------------------------------------------------
// QA:
// axios.defaults.baseURL = 'https://smpu.nl.gob.mx:8093/';
//----------------------------------------------------------
// Producción:
  axios.defaults.baseURL = 'https://smpu.nl.gob.mx/siasi_ws';
//----------------------------------------------------------

//Produccion Temporal-------------------------------------------
// axios.defaults.baseURL = 'https://mioficina.nl.gob.mx/Siasi'
//---


// axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.put['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';



// res.header('Access-Control-Allow-Origin', 'https://www.mySite.top:3000');
// res.header('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
// res.header('Access-Control-Allow-Methods', 'GET, POST');




new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
