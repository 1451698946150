<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg5 xl4>
        <br>
        <br>
            <v-card>
                <v-toolbar dark color="blue darken-3">
                    <v-toolbar-title>
                        Acceso al Sistema
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="email" autofocus color="accent" label="Correo Electrónico" required>
                    </v-text-field>
                    <v-text-field v-model="password" type="password" color="accent" label="Password" required>
                    </v-text-field>
                </v-card-text>
                <v-card-actions class="px-3 pb-3">
                    <v-flex text-xs-right>
                        <v-btn @click="ingresar" color="primary">Ingresar</v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            email: '',
            password: ''
        }
    },
    methods :{
        ingresar(){
            this.error=null;
            axios.post('api/Usuarios/Login', {email: this.email, password: this.password})
            .then(respuesta => {
                return respuesta.data
            })
            .then(data => {           
                this.$store.dispatch("guardarToken", data.token);
                     
                if(this.$store.state.usuario.role=='Administrador'){
                    this.$router.push({ name: 'UsuariosInternos' })
                }
                else if(this.$store.state.usuario.role=='Contratista'){
                    this.$router.push({ name: 'NoDisponible' })
                }
                else if(this.$store.state.usuario.role=='Interno'){
                    this.$router.push({ name: 'ContratistasValidar' })
                }
                else if(this.$store.state.usuario.role=='Rec'){
                    this.$router.push({ name: 'ContratistasValidar' })
                }
                else if(this.$store.state.usuario.role=='Cmic'){
                    this.$router.push({ name: 'ContratistasValidar' })
                }
                else if(this.$store.state.usuario.role=='Canadevi'){
                    this.$router.push({ name: 'ContratistasValidar' })
                }
                else if(this.$store.state.usuario.role=='Arquitectura'){
                    this.$router.push({ name: 'ContratistasValidar' })
                }
                else if(this.$store.state.usuario.role=='Ingcivil'){
                    this.$router.push({ name: 'ContratistasValidar' })
                }
                else{
                    this.$router.push({ name: 'Inicio' })
                }
            })
            .catch(err => {
                //console.log(err.response);
                console.log(err);
                if (err.response.status==400){
                    this.error="No es un email válido";
                } else if (err.response.status==404){
                    this.error="No existe el usuario o sus datos son incorrectos";
                }else{
                    this.error="Ocurrió un error";
                }
            })
        }
    }    
}
</script>