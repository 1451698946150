
<script>
 
  export default {
    props: {
      google: {
        type: Object,
        required: true
      },
      map: {
        type: Object,
        required: true
      },
      marker: {
        type: Object,
        required: true
      }
    },
 
    mounted() {
      const { Marker } = this.google.maps;

        var contentString = '<div id="content">'+
        '<div id="siteNotice">'+
        '</div>'+
        '<h1 id="firstHeading" class="firstHeading">'+ this.marker.nombreUnidad + '</h1>'+
        '<div id="bodyContent">'+
        '<p>Clue <b> '+ this.marker.clue + '</b></p>'+
        '<p>Direccion:<b> '+ this.marker.domicilio + '</b></p>'+
        '<p>Latitud:<b> '+ this.marker.latitud + '</b></p>'+
        '<p>Longitud:<b> '+ this.marker.longitud + '</b></p>'+
        '</div>'+
        '</div>';

        // new InfoWindow({
        //     content: contentString
        // });
 
      new Marker({
        position: this.marker.position,
        marker: this.marker,
        map: this.map,
        title: this.marker.title
      });

            
        // Marker.addListener('click', function() {
        //     infowindow.open(map, marker);
        // });
 
    },
 
    render() {}
  };
 
</script>