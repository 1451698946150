<template>
<v-layout align-start>
    <v-flex>
        <h1>
            Eventos
        </h1>
        <v-spacer></v-spacer>
        <br>
        <v-divider></v-divider>
        <br>
        <v-col>
        <v-data-table :headers="headers"  :items="listadoCompontente1"  :search="search" class="elevation-1">
            <!--<template v-slot:item.actions="{ item }"  >
                    <div v-for="(entry, index) in listadoCompontente1" :key="index">
                      <div v-for="(entry2, index2) in entry.url" :key="index2">
                        <div :set="(urlprueba2 = entry2)">
                          <v-icon x-large color="yellow">mdi-trophy</v-icon>
                          <p> URL {{index2}} : {{urlprueba2}} </p>                
                        </div>                 
                      </div> 
                    </div>                      
                </template>-->


            <template v-slot:item.actions="{ item }">
                        <v-btn icon v-for="(itemfor, index) in item.url" :key="index">
            <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                            <a :href="'https://smpu.nl.gob.mx/' + itemfor" v-if="item.titulo[index].toLowerCase() === 'bases'">
                             <v-icon v-on="on" color="pink" small class="mr-2">mdi-file-document-outline</v-icon>
                            </a>
                            <a :href="'https://smpu.nl.gob.mx/' + itemfor" v-else-if="item.titulo[index].toLowerCase().includes('anexos')">
                             <v-icon v-on="on" color="teal" small class="mr-2">mdi-file-document-outline</v-icon>
                            </a>
                            <a :href="'https://smpu.nl.gob.mx/' + itemfor" v-else>
                             <v-icon v-on="on" small class="mr-2">mdi-file-document-outline</v-icon>
                            </a>
                    </template>
                 <span>{{item.titulo[index]}}</span>
            </v-tooltip>
                        </v-btn>
            </template>
            <template v-slot:item.fecha="{ item }">
                {{item.fecha.substring(0,10) == "0001-01-01" ? "" : item.fecha.substring(0,10) }}
            </template>
            <template v-slot:item.hora="{ item }">
                {{item.hora.substring(11) == "00:00:00" ? "" : item.hora.substring(11)}}
            </template>
        </v-data-table>
        </v-col>
    </v-flex>
</v-layout>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({

        inset: false,
        items: [
            'default',
            'vertical',
        ],
        variant: 'default',

        listadoCompontente1: [],
        dialog: false,
        search: '',
        headers: [{
                text: 'Documentos',
                value: 'actions',
                sortable: false,
                class: "indigo white--text title",
                
            },
            {
                text: 'Estatus',
                value: 'nombre',
                class: "indigo white--text title",
            },
            {
                text: 'Fecha',
                value: 'fecha',
                sortable: false,
                class: "indigo white--text title",
                
            },
            {
                text: 'Hora',
                value: 'hora',
                sortable: false,
                class: "indigo white--text title",
            },
            {
                text: 'Lugar',
                value: 'lugar',
                class: "indigo white--text title",
            },

        ],
        editedIndex: -1,
        defaultItem: {
            ConcursoId: '',
            Nombre: '',
            Lugar: '',
            NombreContacto: '',
            TelefonoContacto: '',
            EmailContacto: '',
            Hora: '',
            Fecha: ''
        },
        valida: 0,
        validaMensaje: []
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nueva aclaración' : 'Solicitud'
        },
    },
    props: {
        concursoid: Number,
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
    },

    created() {
        this.listar();
    },

    methods: {
        listar() {
            //Enlistar registos
            //Llena el listado del componente con los registros que se obtengan al ejecutar la acción "Listar" del api/controller

            let me = this;
            let header = {
                "Authorization": "Bearer " + this.$store.state.token
            };
            let configuration = {
                headers: header
            };
            axios.get('api/concursos/MostrarEventos/' + me.concursoid).then(function (response) {
                console.log(response.data);
                me.listadoCompontente1 = response.data;
            }).catch(function (error) {
                console.log(error);
            });
        },
        close() {
            //Cierra el Dialog (Ventana Modal)

            this.limpiar();
            this.dialog = false;
        },

        limpiar() {
            this.editedIndex = -1;
            this.valida = 0;
            this.validaMensaje = [];

            this.defaultItem = {
                MunicipioNombre: '',
                LocalidadNombre: ''
            };

        }
    },
}
</script>
